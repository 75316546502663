import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {
  CPFMaskCustom,
  PhoneMaskCustom,
} from 'components/CustomMasks/Masks';
import CustomInput from 'components/CustomInput/CustomInput';

import Button from 'components/CustomButtons/Button';
import { getUserBalance, addUserCash } from '../../requests/requests';
import HeaderItem from './Income/HeaderItem';
import { formatMoney, formatTimeDate } from 'utils/functions';
import { useDebounce } from 'utils/hooks';
import './Income/index.css';
import moment from "moment";
import Typography from '@material-ui/core/Typography';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

function UserBalance() {
  const [driver, ] = useState(JSON.parse(sessionStorage.getItem('userDetail')));
  const [driverName, setDriverName] = useState("");

  const [loading, setLoading] = useState(true);
  const [isDeposit, setIsDeposit] = useState(false);

  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");

  const [count, setCount] = useState();
  const [income, setIncome] = useState();
  const [tableLoading, setTableLoading] = useState(true);
  const [allRides, setAllRides] = useState([]);

  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(20);
  
  const [driverId, setDriverId] = useState();

  /** EFFECTS */
  useEffect(()=>{
    if (driver !== undefined){
      setDriverId(driver.id);
      setDriverName(driver.name);
    }
  },[driver]);

  useEffect(()=>{
    if (driverId !== undefined){
      setTableLoading(true);
        setLoading(false);
        getUserBalance(
          driverId,
        ).then( res => {
          setAllRides(res.data.transfers);
          setCount(res.data.transfers.length);
          setIncome(res.data.balance);
          setTableLoading(false);
        }).catch(()=>{
          setTableLoading(false);
        });
    }
  },[
    driverId
  ])

  function updateAmount(value){

    var decimals = value.replace(/[^0-9]/g, '');
    var money = decimals / 100.0;

    if(money > 100000){
      money = 100000
    }

    setAmount(formatMoney(money));
  }

  function showModal(document, approved) {
    setModal(true);
  }

  function hideAlert() {
    setModal(false);
  }

  function addCash() {

    var decimals = amount.replace(/[^0-9]/g, '');
    var money = decimals / 100.0;

    if(money < 0.01){
      alert("Valor tem que ser maior que zero.")
      return;
    }

    if(note.length < 1){
      alert("Observação obrigatória.")
      return;
    }

    setLoading(true);
    setModal(false);
    addUserCash(
      driverId,
      money,
      note,
      isDeposit
    ).then( res => {
      setLoading(false)
      window.location.reload();
    }).catch(()=>{
      alert("Erro ao adicionar saldo");
    });
  }

  return (
    <div>
      {
        loading ? <CircularProgress color="secondary" /> : (
        <Grid
          container
          component={Card}
          style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
          spacing={16}
          justify="center"
          alignItems="center"
        >
          {
            modal ? (     
          <SweetAlert
            warning
            style={{
              display: "block",
              overflow: "scroll",
              marginTop: "-300px"
            }}
            title={"Adicionar/Subtrair saldo do cliente"}
            onConfirm={() => addCash()}
            onCancel={() => hideAlert()}
            showCancel
            confirmBtnText="Confirmar"
            cancelBtnText="Cancelar"
          >
            <p>Adicionar ou subtrair saldo?</p>
            <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={{ value: false, label: 'Subtrair (-)' }}
                onChange={evt => setIsDeposit(evt.value)}             
                options = {[              
                  { value: true, label: 'Adicionar (+)' },
                  { value: false, label: 'Subtrair (-)' }              
                ]}/>
            
            <br></br>
            <p>Digite o valor</p>
            <CustomInput
              id="amount"
              labelText="Valor"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'text',
                id: 'amount',
                required: true,
                value: amount,
                onChange: evt => updateAmount(evt.target.value)
              }}
              
                />
                <br></br>
            <p>Digite uma observação</p>
            <CustomInput
              id="note"
              labelText="Observação"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'text',
                id: 'note',
                required: true,
                value: note,
                onChange: evt => setNote(evt.target.value)
              }}
              
                />
          </SweetAlert>):''}
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <>
            <Grid item md={12}container justify="flex-start">
              <HeaderItem
                value={driverName}
              />
            </Grid>
            <br/>
            <br/>
            <br/>
            <Grid item md={12} container>
              <Grid item md={3}>
                <HeaderItem
                  title="Saldo de Beelog Cash"
                  value={income !== undefined ? formatMoney(income) : ''}
                />
                <br/>
                <Button
                  outline
                  color="primary"
                  size="sm"
                  disabled={false}
                  onClick={() => showModal()}
                >
                  Alterar saldo
                </Button>
              </Grid>                  
            </Grid>
            <Grid item md={12} container>
              <ReactTable
                style={{ width: '100%', textAlign: 'center' }}
                className="-striped -highlight"
                data={allRides}
                sortable={false}
                defaultPageSize={pageLength}
                showPageSizeOptions={false}            
                pageSize={pageLength}
                pages={Math.ceil(count ? count / pageLength : 1)}
                loading={tableLoading}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"                       
                columns={[
                  {
                    id: 'id',
                    accessor: 'created_at',
                    Header: 'Data',
                    accessor: row => row.created_at !== undefined ? formatTimeDate(row.created_at) : '',
                  },
                  {
                    id: 'type',
                    accessor: 'type',
                    Header: 'Tipo',
                    accessor: row => row.is_deposit == 1 ? 'DEPÓSITO' : 'USO/RESGATE',
                  },
                  {
                    id: 'note',
                    accessor: 'note',
                    Header: 'Descrição',
                    accessor: row => row.note ? row.note : '',
                  },
                  {
                    id: 'amount',
                    Header: 'Valor',
                    accessor: row => row.is_deposit == 1 ? formatMoney(row.amount) : formatMoney(row.amount * -1)
                  }
                ]}
                showPageJump={false}
              />
            </Grid>
            </>
          )}
        </Grid>
      )}
    </div>
  );
}

export default UserBalance;