import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import background from '../../assets/img/login.jpeg';

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, title, txt } = this.props;

    return (
      <div
        className={classes.container}
        style={{ backgroundImage: `url(${background})`, width: `100%`, height: 700 }}
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h1 className={classes.cardTitle} style={{ fontSize: 72 }}>
                {title}
              </h1>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={10} align="center">
                    <h2>{txt}</h2>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  txt: PropTypes.string.isRequired
};

export default withStyles(registerPageStyle)(Error);
