// @material-ui/icons
import PersonAdd from '@material-ui/icons/PersonAdd';
import Fingerprint from '@material-ui/icons/Fingerprint';
import LockOpen from '@material-ui/icons/LockOpen';
import LoginPage from '../views/Pages/LoginPage';
import DriverDocs from '../views/Pages/DriverDocs';
import Landing from '../views/Pages/Landing';
import Forget from '../views/Pages/Forget';

const pagesRoutes = [
  // {
  //   path: '/driver/register',
  //   name: 'Registrar',
  //   short: 'Registrar',
  //   mini: 'RP',
  //   icon: PersonAdd,
  //   component: RegisterPage
  // },
  {
    path: '/driver/documents',
    name: 'Envio de Documentos',
    short: 'Documentos',
    mini: 'DC',
    icon: PersonAdd,
    component: DriverDocs,
    hidden: true
  },
  {
    path: '/driver/landing',
    name: 'Landing Page',
    short: 'Landing',
    mini: 'Ln',
    icon: PersonAdd,
    component: Landing,
    hidden: true
  },
  {
    path: '/driver/login',
    name: 'Entrar',
    short: 'Entrar',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: '/driver/recovery',
    name: 'Forgot',
    short: 'Forgot',
    mini: 'FG',
    icon: LockOpen,
    component: Forget,
    hidden: true
  },
  {
    redirect: true,
    path: '/driver',
    pathTo: '/driver/login',
    name: 'Entrar'
  }
];

export default pagesRoutes;
