import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { withStyles, Select, InputLabel, MenuItem } from '@material-ui/core';

import Assignment from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardIcon from '../../components/Card/CardIcon';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import { debounce } from 'lodash';
// export to csv
import { CSVLink } from 'react-csv';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { getUsers } from '../../requests/requests';
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react';
// import { formatTimeDate } from '../../helpers/functions';
import Driver from '../Pages/Driver';
// express riomar
import Edit from '@material-ui/icons/Edit';
import moment from 'moment';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class UserHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: 'unstated',
      table: {
        base: [],
        pages: '',
        loading: false
      },
      name: '',
      email: '',
      cpf: '',
      state: '',
      goToDriver: false,
      driver: {},
      driverIncome: {},
      goToSplitter: false,
      driverId: null,
      count: 0
    };
    this.fetchDataWithDebounce = debounce(this.fetchData, 500);
  }

  shouldComponentUpdate(nextState) {
    const { list } = this.state;
    if (nextState.list !== list) {
      return true;
    }
    return false;
  }

  getDocumentSituation = driver => {
    if (
      !driver.approved_at &&
      (driver.documents == null ||
        driver.documents.criminal_records == null ||
        driver.documents.cnh == null ||
        driver.documents.vehicle_document == null ||
        driver.documents.selfie == null)
    ) {
      return true;
    }
    return false;
  };

  goToUserBalance = (evt, user) => {
    evt.preventDefault();
    sessionStorage.setItem('userDetail', JSON.stringify(user));
    window.open(`/user-balance`, '_blank');
    //window.location.href = '/user-balance';
  };

  handleChange = async evt => {
    await this.setState({ [evt.target.name]: evt.target.value });
    this.refReactTable.fireFetchData();
  };

  onFilteredChange = async (column, value) => {
    if (column && column.length > 0) {
      column.map((c, i) => {
        if (c.id === 'colState') {
          this.setState(prevState => ({ state: c.value }));
        }
        if (c.id === 'colName') {
          this.setState(prevState => ({ name: c.value }));
        }

        if (c.id === 'colEmail') {
          this.setState(prevState => ({ email: c.value }));
        }

        if (c.id === 'colCpf') {
          this.setState(prevState => ({ cpf: c.value }));
        }
      });
      // if (column[0].id === "colState"){
      //   this.setState(prevState => ({state: column[0].value}))
      // }
      // if (column[0].id === "colName") {
      //   this.setState(prevState => ({name: column[0].value}))
      // }
    } else {
      this.setState(prevState => ({ name: '', email: '', cpf: "" }));
    }
  };

  fetchData = async state => {
    const { list } = this.state;
    this.setState(prevState => ({
      table: { ...prevState.table, loading: true }
    }));

    try {
      const res = await getUsers(
        state.page * state.pageSize,
        state.pageSize,
        this.state.name,
        this.state.cpf,
        this.state.email
      );

      const nRes = res.data.count;
      const driverName = '';
      res.data.users.forEach(driver => {
        driver.btn = (
          <div className="actions-right" style={{ float: 'right' }}>
            <Button
              justIcon
              round
              simple
              color="success"
              onClick={evt => {
                this.goToUserBalance(evt, driver);
              }}
            >
              <AttachMoneyIcon />
            </Button>
          </div>
        );
      });
      this.setState(prevState => ({
        table: {
          ...prevState.table,
          base: res.data.users,
          loading: false,
          pages: parseInt(res.data.count / state.pageSize, 10)
        },
        count: nRes,
        driverName: driverName
      }));
    } catch (err) {
      this.setState(prevState => ({
        table: {
          ...prevState.table,
          loading: false
        }
      }));
    }
  };

  render() {
    const { classes } = this.props;
    const {
      table,
      goToDriver,
      driver,
      list,
      csvData,
      csvLoading,
      csvReady
    } = this.state;

    if (goToDriver) return <Driver driver={driver} />;
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Clientes&nbsp;&nbsp;&nbsp;<small>B2B, B2C e Motoristas</small></h4>              
            </CardHeader>
            <CardBody>
              <ReactTable
                ref={refReactTable => {
                  this.refReactTable = refReactTable;
                }}
                className="-striped -highlight"
                data={table.base}
                sortable={false}
                defaultPageSize={10}
                pages={table.pages + 1}
                loading={table.loading}
                previousText="Anterior"
                nextText="Próximo"
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                manual
                onFetchData={this.fetchDataWithDebounce}
                onFilteredChange={this.onFilteredChange}
                columns={[
                  {
                    id: 'colName',
                    Header: 'Nome',
                    accessor: row => row.name,
                    maxWidth: 200,
                    filterable: true
                  },
                  {
                    id: 'colEmail',
                    Header: 'Email',
                    accessor: row => row.email,
                    filterable: true
                  },
                  {
                    id: 'colCpf',
                    Header: 'CPF',
                    accessor: row => row.cpf,
                    filterable: true
                  },
                  {
                    id: 'colBalance',
                    Header: 'Saldo',
                    maxWidth: 100,
                    accessor: row =>
                      row.balance
                  },
                  {
                    id: 'colDisabled',
                    Header: 'Data de solicitação de exclusão',
                    accessor: row =>
                      row.disabled_at != null
                        ? row.disabled_at
                        : '',
                  },
                  {
                    id: 'colBtn',
                    Header: '',
                    accessor: row => row.btn,
                    sortable: false,
                    filterable: false
                  }
                ]}
                showPageJump={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

UserHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserHistory);
