import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { withStyles, Select, InputLabel, MenuItem } from '@material-ui/core';

import Assignment from '@material-ui/icons/Assignment';
import Search from '@material-ui/icons/Search';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardIcon from '../../components/Card/CardIcon';
import CardHeader from '../../components/Card/CardHeader';
import Button from '../../components/CustomButtons/Button';
import { debounce } from 'lodash';
// export to csv
import { CSVLink } from 'react-csv';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { getDrivers } from '../../requests/requests';
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react';
// import { formatTimeDate } from '../../helpers/functions';
import Driver from '../Pages/Driver';
// express riomar
import Edit from '@material-ui/icons/Edit';
import moment from 'moment';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

const headers = [
  {
    label: 'Nome',
    key: 'name'
  },
  {
    label: 'Telefone',
    key: 'phone'
  },
  {
    label: 'Email',
    key: 'mail'
  },
  {
    label: 'Documento pendente/Motivo de reprovação',
    key: 'doc'
  },
  {
    label: 'Data de cadastro',
    key: 'enroll_date'
  },
  {
    label: 'Mês de cadastro',
    key: 'enroll_month'
  },
  {
    label: 'Cidade do cadastro',
    key: 'enroll_city'
  },
  {
    label: 'Estado do cadastro',
    key: 'enroll_state'
  },
  {
    label: 'Data de retorno',
    key: 'return_date'
  },
  {
    label: 'Data de aprovação/reprovação',
    key: 'situation_date'
  },
  {
    label: 'Carro',
    key: 'car'
  },
  {
    label: 'Moto',
    key: 'bike'
  },
  {
    label: 'Descrição do veículo',
    key: 'veh_desc'
  },
  {
    label: 'Placa do veículo',
    key: 'veh_plate'
  }
  // {
  //   Header: 'Número do CRLV',
  //   accessor: 'crlv_number',
  // },
  // {
  //   Header: 'Ear',
  //   accessor: 'ear',
  // },
];

class DriverHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: 'unstated',
      table: {
        base: [],
        pages: '',
        loading: false
      },
      csvData: [
        {
          name: '',
          phone: '',
          mail: '',
          doc: '',
          enroll_date: '',
          enroll_month: '',
          enroll_city: '',
          enroll_state: '',
          return_date: '',
          situation_date: '',
          car: '',
          bike: '',
          veh_desc: '',
          veh_plate: ''
          // crlv_date: '',
          // crlv_number: '',
          // ear: ''
        }
      ],
      name: '',
      state: '',
      goToDriver: false,
      driver: {},
      driverIncome: {},
      goToSplitter: false,
      driverId: null,
      csvLoading: false,
      csvReady: false,
      count: 0
    };
    this.fetchDataWithDebounce = debounce(this.fetchData, 500);
    this.download = this.download.bind(this);
    this.downloadSave = this.downloadSave.bind(this);
  }

  shouldComponentUpdate(nextState) {
    const { list } = this.state;
    if (nextState.list !== list) {
      return true;
    }
    return false;
  }

  getDocumentSituation = driver => {
    if (
      !driver.approved_at &&
      (driver.documents == null ||
        driver.documents.criminal_records == null ||
        driver.documents.cnh == null ||
        driver.documents.vehicle_document == null ||
        driver.documents.selfie == null)
    ) {
      return true;
    }
    return false;
  };

  goToDriver = (evt, driver) => {
    evt.preventDefault();
    sessionStorage.setItem('driverDetail', JSON.stringify(driver));
    window.location.href = '/driver-detail';
  };

  goToCreateSplitter = (evt, driver) => {
    evt.preventDefault();
    sessionStorage.setItem('driverDetail', JSON.stringify(driver));
    window.location.href = '/csplitter';
  };

  goToDriverIncome = (evt, driver) => {
    evt.preventDefault();
    sessionStorage.setItem('driverDetail', JSON.stringify(driver));
    window.location.href = '/driver-income';
  };

  handleChange = async evt => {
    await this.setState({ [evt.target.name]: evt.target.value });
    this.refReactTable.fireFetchData();
  };

  onFilteredChange = async (column, value) => {
    if (column && column.length > 0) {
      column.map((c, i) => {
        if (c.id === 'colState') {
          this.setState(prevState => ({ state: c.value }));
        }
        if (c.id === 'colName') {
          this.setState(prevState => ({ name: c.value }));
        }
      });
      // if (column[0].id === "colState"){
      //   this.setState(prevState => ({state: column[0].value}))
      // }
      // if (column[0].id === "colName") {
      //   this.setState(prevState => ({name: column[0].value}))
      // }
    } else {
      this.setState(prevState => ({ name: '', state: '' }));
    }
  };

  handleTypeState(value) {
    let id = 0;
    let name = value.toLowerCase();
    switch (name) {
      case 'acre':
        id = 1;
        break;
      case 'ac':
        id = 1;
        break;
      case 'alagoas':
        id = 2;
        break;
      case 'al':
        id = 2;
        break;
      case 'amazonas':
        id = 3;
        break;
      case 'am':
        id = 3;
        break;
      case 'amapá':
        id = 4;
        break;
      case 'ap':
        id = 4;
        break;
      case 'bahia':
        id = 5;
        break;
      case 'ba':
        id = 5;
        break;
      case 'ceará':
        id = 6;
        break;
      case 'ce':
        id = 6;
        break;
      case 'distrito federal' || 'df':
        id = 7;
        break;
      case 'df':
        id = 7;
        break;
      case 'espírito santo':
        id = 8;
        break;
      case 'es':
        id = 8;
        break;
      case 'goiás':
        id = 9;
        break;
      case 'go':
        id = 9;
        break;
      case 'maranhão':
        id = 10;
        break;
      case 'ma':
        id = 10;
        break;
      case 'minas gerais':
        id = 11;
        break;
      case 'mg':
        id = 11;
        break;
      case 'mato grosso do sul':
        id = 12;
        break;
      case 'ms':
        id = 12;
        break;
      case 'mato grosso':
        id = 13;
        break;
      case 'mt':
        id = 13;
        break;
      case 'pará':
        id = 14;
        break;
      case 'pa':
        id = 14;
        break;
      case 'paraíba':
        id = 15;
        break;
      case 'pb':
        id = 15;
        break;
      case 'pernambuco':
        id = 16;
        break;
      case 'pe':
        id = 16;
        break;
      case 'piauí':
        id = 17;
        break;
      case 'pi':
        id = 17;
        break;
      case 'paraná':
        id = 18;
        break;
      case 'pr':
        id = 18;
        break;
      case 'rio de janeiro':
        id = 19;
        break;
      case 'rj':
        id = 19;
        break;
      case 'rio grande do norte':
        id = 20;
        break;
      case 'rn':
        id = 20;
        break;
      case 'rondônia':
        id = 21;
        break;
      case 'ro':
        id = 21;
        break;
      case 'roraima':
        id = 22;
        break;
      case 'rr':
        id = 22;
        break;
      case 'rio grande do sul':
        id = 23;
        break;
      case 'rs':
        id = 23;
        break;
      case 'santa catarina':
        id = 24;
        break;
      case 'sc':
        id = 24;
        break;
      case 'sergipe':
        id = 25;
        break;
      case 'se':
        id = 25;
        break;
      case 'são paulo':
        id = 26;
        break;
      case 'sp':
        id = 26;
        break;
      case 'tocantins':
        id = 27;
        break;
      case 'to':
        id = 27;
        break;
    }
    return id;
  }

  fetchData = async state => {
    const { list } = this.state;
    this.setState(prevState => ({
      table: { ...prevState.table, loading: true }
    }));

    let stateId = this.handleTypeState(this.state.state);

    try {
      const res = await getDrivers(
        state.page * state.pageSize,
        state.pageSize,
        list,
        this.state.name,
        stateId
      );

      const nRes = res.data.count;
      const driverName = '';
      res.data.drivers.forEach(driver => {
        driver.btn = (
          <div className="actions-right" style={{ float: 'right' }}>
            <Button
              justIcon
              round
              simple
              color="warning"
              onClick={evt => {
                this.goToCreateSplitter(evt, driver);
              }}
            >
              <Edit />
            </Button>
            <Button
              justIcon
              round
              simple
              color="info"
              onClick={evt => {
                this.goToDriver(evt, driver);
              }}
              //disabled={this.getDocumentSituation(driver)}
            >
              <Search />
            </Button>
            <Button
              justIcon
              round
              simple
              color="success"
              onClick={evt => {
                this.goToDriverIncome(evt, driver);
              }}
            >
              <AttachMoneyIcon />
            </Button>
          </div>
        );
      });
      this.setState(prevState => ({
        table: {
          ...prevState.table,
          base: res.data.drivers,
          loading: false,
          pages: parseInt(res.data.count / state.pageSize, 10)
        },
        count: nRes,
        driverName: driverName
      }));
    } catch (err) {
      this.setState(prevState => ({
        table: {
          ...prevState.table,
          loading: false
        }
      }));
    }
  };

  fetchDataCsv = async state => {
    const { list, count } = this.state;
    this.setState({ csvLoading: true });
    let stateId = this.handleTypeState(this.state.state);

    try {
      const csvRes = await getDrivers(0, count, list, this.state.name, stateId);
      const csvData = [];
      await csvRes.data.drivers.map(v => {
        const locked = [];

        if (v.documents.cnh_locked === false) {
          locked.push(' CNH pendente ');
        }
        if (v.documents.criminal_records_locked === false) {
          locked.push(' Antecedentes criminais pendente ');
        }
        if (v.documents.selfie_locked === false) {
          locked.push(' Foto pendente ');
        }
        if (v.documents.vehicle_document_locked === false) {
          locked.push(' Documento do veículo pendente ');
        }
        let month = moment(v.user.signup_date).format('MM/YYYY');

        csvData.push({
          name: v.user.name,
          phone: v.user.phone,
          mail: v.user.email,
          doc: locked,
          enroll_date: v.user.signup_date,
          enroll_month: month,
          enroll_city: v.user.address
            ? v.user.address.city
              ? v.user.address.city.nome
              : ''
            : '',
          enroll_state: v.user.address
            ? v.user.address.city
              ? v.user.address.city.state.nome
              : ''
            : '',
          return_date: '',
          situation_date:
            v.approved_at !== null
              ? v.approved_at
              : v.reproved_at !== null
              ? v.reproved_at
              : '',
          car: v.vehicle.type.name === 'Carro' ? 1 : 0,
          bike: v.vehicle.type.name === 'Moto' ? 1 : 0,
          veh_desc: v.vehicle.description,
          veh_plate: v.vehicle.plate
        });
      });

      this.setState(
        {
          csvData: csvData
        },
        () => {
          this.setState({ csvReady: true });
          this.setState({ csvLoading: false });
        }
      );
    } catch (err) {
      this.setState(prevState => ({
        csvLoading: false,
        csvReady: false
      }));
    }
  };

  download = async () => {
    await this.fetchDataCsv();
  };

  downloadSave() {
    this.csvLink.link.click();
    this.setState({ csvReady: false });
  }

  render() {
    const { classes } = this.props;
    const {
      table,
      goToDriver,
      driver,
      list,
      csvData,
      csvLoading,
      csvReady
    } = this.state;

    if (goToDriver) return <Driver driver={driver} />;
    return (
      <GridContainer justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Motoristas</h4>
            </CardHeader>
            <CardBody>
              <GridContainer direction="row" alignItems="center">
                <GridItem xs={10} sm={10} md={10}>
                  <InputLabel htmlFor="list">Listar: </InputLabel>
                  <Select
                    disabled={
                      this.state.name != null && this.state.name.length > 0
                    }
                    value={list}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'list',
                      id: 'list'
                    }}
                  >
                    <MenuItem value="unstated">Em espera</MenuItem>
                    <MenuItem value="approved">Aprovados</MenuItem>
                    <MenuItem value="reproved">Reprovados</MenuItem>
                    <MenuItem value="pending">Pendentes</MenuItem>
                  </Select>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <div style={{ textAlign: 'end' }}>
                    <CSVLink
                      data={csvData}
                      headers={headers}
                      separator={';'}
                      filename="dataDriver.csv"
                      className="hidden"
                      ref={r => (this.csvLink = r)}
                      target="_blank"
                    />
                    <Button
                      outline
                      color={csvReady ? 'success' : 'primary'}
                      size="sm"
                      disabled={csvLoading}
                      onClick={csvReady ? this.downloadSave : this.download}
                    >
                      {csvLoading
                        ? 'CARREGANDO'
                        : csvReady
                        ? 'SALVAR'
                        : 'EXPORTAR'}
                    </Button>
                  </div>
                </GridItem>
              </GridContainer>
              <br></br>
              <Typography variant="caption">
                Os valores exportados serão iguais aos presentes na tabela no
                momento da exportação.
              </Typography>
              <ReactTable
                ref={refReactTable => {
                  this.refReactTable = refReactTable;
                }}
                className="-striped -highlight"
                data={table.base}
                sortable={false}
                defaultPageSize={10}
                pages={table.pages + 1}
                loading={table.loading}
                previousText="Anterior"
                nextText="Próximo"
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                manual
                onFetchData={this.fetchDataWithDebounce}
                onFilteredChange={this.onFilteredChange}
                columns={[
                  {
                    id: 'colName',
                    Header: 'Nome',
                    accessor: row => row.user.name,
                    maxWidth: 200,
                    filterable: true
                  },
                  {
                    id: 'colContact',
                    Header: 'Contato',
                    accessor: row => row.user.phone,
                    width: 150
                  },
                  {
                    id: 'colSignup',
                    Header: 'Criado em',
                    accessor: row => row.user.signup_date,
                    width: 100
                  },
                  {
                    id: 'colCity',
                    Header: 'Cidade',
                    accessor: row =>
                      row.user.address != null ? row.user.address.city.nome : ''
                  },
                  {
                    id: 'colState',
                    Header: 'Estado',
                    accessor: row =>
                      row.user.address != null
                        ? row.user.address.city.state.nome
                        : '',
                    filterable: true
                  },
                  {
                    id: 'colBtn',
                    Header: '',
                    accessor: row => row.btn,
                    sortable: false,
                    filterable: false
                  }
                ]}
                showPageJump={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

DriverHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DriverHistory);
