// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import History from '@material-ui/icons/History';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import ExitToApp from '@material-ui/icons/ExitToAppOutlined';
import AttachMoney from '@material-ui/icons/AttachMoneyOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BusinessIcon from '@material-ui/icons/Business';

import UserProfile from '../views/Pages/UserProfile';
import DriverHistory from '../views/Tables/DriverHistory';
import UserHistory from '../views/Tables/UserHistory';
import Ride from '../views/Pages/Ride';

import UpdatePassword from '../views/Pages/UpdatePassword';
import LoginPage from '../views/Pages/LoginPage';
import RideHistory from '../views/Tables/RideHistory';
import Splitters from '../views/Tables/Splitters';
import DriversMap from '../views/Maps/DriversMap';
import Driver from '../views/Pages/Driver';
import CreateSplitter from '../views/Pages/CreateSplitter';
import Income from '../views/Pages/Income/Income';
import Fares from '../views/Pages/Fares/Fares';
import DriversQueue from '../views/Pages/DriversQueue/DriversQueue';
import DriverIncome from '../views/Pages/DriverIncome';
import UserBalance from '../views/Pages/UserBalance';
import EnterprisesTable from '../views/Pages/Enterprises/EnterprisesTable';
import EnterpriseDetail from '../views/Pages/Enterprises/EnterpriseDetail';
import CreateBusiness from '../views/Forms/CreateBusiness';
import BKOTable from '../views/Pages/Enterprises/BKOTable';
import DriversTable from '../views/Pages/Enterprises/DriversTable';
import Zones from '../views/Pages/Fares/Zones';

// const pages = [
//   {
//     path: '/timeline-page',
//     name: 'Timeline Page',
//     mini: 'TP',
//     component: TimelinePage
//   },
//   {
//     path: '/rtl/rtl-support-page',
//     name: 'RTL Support',
//     mini: 'RS',
//     component: RTLSupport
//   }
// ].concat(pagesRoutes);

const dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: DashboardIcon,
  //   component: Dashboard
  // },
  {
    path: '/user-page',
    name: 'Perfil de Usuário',
    icon: Person,
    component: UserProfile
  },
  {
    path: '/update-password',
    name: 'Atualizar Senha',
    icon: VpnKeyIcon,
    component: UpdatePassword
  },
  {
    path: '/driver-history',
    name: 'Cad. Motoristas',
    icon: History,
    component: DriverHistory
  },
  {
    path: '/enterprise-table',
    name: 'Empresas',
    icon: BusinessIcon,
    component: EnterprisesTable
  },
  {
    path: '/enterprise-detail',
    name: 'Detalhes da empresa',
    icon: BusinessIcon,
    component: EnterpriseDetail,
    hidden: true
  },
  {
    path: '/bko-enterprise',
    name: 'BKOs da empresa',
    icon: BusinessIcon,
    component: BKOTable,
    hidden: true
  },
  {
    path: '/drivers-enterprise',
    name: 'Motoristas da empresa',
    icon: BusinessIcon,
    component: DriversTable,
    hidden: true
  },
  {
    path: '/ride-history',
    name: 'Histórico Fretes',
    icon: History,
    component: RideHistory
  },
  {
    path: '/splitters',
    name: 'Cad. Recebedores',
    icon: History,
    component: Splitters
  },
  {
    path: '/income',
    name: 'Faturamento',
    icon: AttachMoney,
    component: Income,
    admin: true
  },
  {
    path: '/create-business',
    name: 'Cadastro de empresas',
    icon: BusinessIcon,
    component: CreateBusiness,
    hidden: true
  },
  {
    path: '/fares',
    name: 'Tarifas',
    icon: AttachMoney,
    component: Fares,
    admin: true
  },
  {
    path: '/driversqueue',
    name: 'Fila',
    icon: History,
    component: DriversQueue
  },
  {
    path: '/map',
    name: 'Mapa',
    icon: LocationOnIcon,
    component: DriversMap
  },
  {
    path: '/users',
    name: 'Usuários',
    icon: History,
    component: UserHistory
  },
  {
    path: '/zones',
    name: 'Zonas de Atuação',
    icon: History,
    component: Zones,
    admin: true
  },
  {
    path: '/ride/:id',
    name: 'Corrida',
    icon: DashboardIcon,
    component: Ride,
    hidden: true
  },
  {
    path: '/driver-detail',
    name: 'Motorista',
    icon: DashboardIcon,
    component: Driver,
    hidden: true
  },
  {
    path: '/csplitter',
    name: 'Completar Cadastro',
    icon: DashboardIcon,
    component: CreateSplitter,
    hidden: true
  },
  {
    path: '/driver-income',
    name: 'Faturamento do motorista',
    icon: DashboardIcon,
    component: DriverIncome,
    hidden: true
  },
  {
    path: '/user-balance',
    name: 'Saldo do Usuário',
    icon: DashboardIcon,
    component: UserBalance,
    hidden: true
  },
  {
    path: '/driver/login',
    name: 'Sair',
    icon: ExitToApp,
    component: LoginPage
  },
  { redirect: true, path: '/', pathTo: '/driver-history', name: 'Historico' }
];
export default dashRoutes;
