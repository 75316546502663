import React, { useState, useEffect, useCallback} from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,
  Select,
  MenuItem
} from '@material-ui/core';
import { getFares, createFare, fareDelete, getBusinessList} from '../../../requests/requests';
import { useDebounce } from '../../../utils/hooks';
import { formatTimeDate } from '../../../utils/functions';
import { moneyField, formatMoney } from '../../../helpers/functions';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderItem from '../Income/HeaderItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import Delete from '@material-ui/icons/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';

import './index.css';
import moment from "moment";

function Fares(props) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterState, setFilterState] = useState({
    zone: null,
    user: null
  });
  const [fares, setFares] = useState({ fares: []});
  const [businessList, setBusinessList] = useState([]);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState();

  const [createFareState, setCreateFareState] = useState({
    user_id: null,
    distance_value: 0,
    minimum: 0,
    volume_value: 0,
    type_of_content: 0,
    vehicle_type: 0,
    food: 0,
    insurance_rate: 0,
    cancelation: 0
  });

  const debouncedZoneId = useDebounce(filterState.zone, 1000);
  const debouncedUserId = useDebounce(filterState.user, 1000);

  // useEffect(() => {
  //   getBusinessList().then(res => {
  //     setBusinessList(res.data.businesses);
  //   });
  // }, []);

  useEffect(() => {
    getBusinessList().then(res => {
      setBusinessList(res.data.businesses);
    });
  }, []);

  useEffect(() => {
    setTableLoading(true);
    getFares(debouncedUserId, debouncedZoneId)
      .then(res => {
        setFares(res.data);
        setLoading(false);
        setTableLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setTableLoading(false);
      });
  }, [    
    debouncedUserId,
    debouncedZoneId
  ]);

  useEffect(() => {
    if (fares.fares.length > 0) {
      fares.fares.map(e => {

        if(e.user_id){
          e.deleteBtn = (
            <div style={{ float: 'right' }}>
              <Button
                justIcon
                round
                simple
                color="danger"
                onClick={evt => {
                  showAlert(e.id);
                }}
              >
                <Delete />
              </Button>
            </div>
          );
        }
      });

    }
  }, [fares]);

  const handleSubmit = useCallback(()=>{
    setLoading(true);
    setOpen(false);

    createFare(createFareState)
      .then(res => {
        setLoading(false);
        if(res){
          window.location.reload();
        }else{
          window.alert(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        window.alert(JSON.stringify(error.response.data));
      });

  },[createFareState]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function deleteFare(id){
    setAlert(null);
    setLoading(true);
    fareDelete(id)
      .then(res => {
        window.location.reload();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        window.alert('Um erro ocorreu.')
      });
  }

  /** FUNCTIONS */
  function showAlert(id) {
    setAlert(
      <SweetAlert
        warning
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title={'Excluir tarifa'}
        onConfirm={() => deleteFare(id)}
        onCancel={() => hideAlert()}
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        Você tem certeza que quer excluir essa tarifa? Lembre de cadastrar novamente senão o cliente poderá ficar sem solicitar corridas.
      </SweetAlert>
    );
  }

  function hideAlert() {
    setAlert(null);
  }

  return (
    <>
    {alert}
    <Grid
      container
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cadastrar Tarifa</DialogTitle>
            <DialogContent>
              
            <Grid item md={12} container>
              <Grid item md={6} lg={12} sm={12}>
                <FormControl fullWidth> 
                  <p>Escolha um cliente:</p>
                  <Select
                  native
                  value={filterState.user}
                  id="requester-select"
                  name="user"
                  required
                  fullWidth
                  onChange={e =>
                    setCreateFareState({
                      ...createFareState,
                      user_id: e.target.value
                    })                    
                  }
                >
                  <option aria-label="None" value="" />
                  {businessList.map(business => (
                    <option value={business.id}>
                      {business.name}
                    </option>
                  ))}
                </Select>
                <CustomInput
                  id="distance_value"
                  labelText="Valor por KM (R$)"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'distance_value',
                    required: true,
                    value: createFareState.distance_value,
                    onChange: evt => setCreateFareState({
                      ...createFareState,
                      distance_value: moneyField(evt.target.value)
                    })
                  }}
                />
                <CustomInput
                  id="minimum"
                  labelText="Valor mínimo ref. à quilometragem"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'minimum',
                    required: true,
                    value: createFareState.minimum,
                    onChange: evt => setCreateFareState({
                      ...createFareState,
                      minimum: moneyField(evt.target.value)
                    })
                  }}
                />
                <CustomInput
                  id="volume_value"
                  labelText="Valor do Volume"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'volume_value',
                    required: true,
                    value: createFareState.volume_value,
                    onChange: evt => setCreateFareState({
                      ...createFareState,
                      volume_value: moneyField(evt.target.value)
                    })
                  }}
                /> 
                <CustomInput
                  id="minimum_volume"
                  labelText="Valor mínimo do Volume"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'minimum_volume',
                    required: true,
                    value: createFareState.minimum_volume,
                    onChange: evt => setCreateFareState({
                      ...createFareState,
                      minimum_volume: moneyField(evt.target.value)
                    })
                  }}
                />
                
                <p>Tipo de veículo:</p>
                  <Select
                    native
                    id="vehicle_type"
                    fullWidth
                    name="vehicle_type"
                    value={createFareState.vehicle_type}
                    onChange={e =>
                      setCreateFareState({
                        ...createFareState,
                        vehicle_type: e.target.value
                      })
                    }>
                    <option value={0}>Moto</option>
                    <option value={1}>Carro</option>
                  </Select><br></br>
                  <p>Tipo de conteúdo:</p>
                  <Select
                    native
                    id="type_of_content"
                    fullWidth
                    name="type_of_content"
                    value={createFareState.type_of_content}
                    onChange={e =>
                      setCreateFareState({
                        ...createFareState,
                        type_of_content: e.target.value
                      })
                    }>
                    <option value={0}>Documentos</option>
                    <option value={1}>Pacote</option>
                  </Select><br></br>
                  <CustomInput
                    id="food"
                    labelText="Taxa de Food (R$)"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      type: 'decimal',
                      id: 'food',
                      required: true,
                      value: createFareState.food,
                      onChange: evt => setCreateFareState({
                        ...createFareState,
                        food: moneyField(evt.target.value)
                      })
                    }}
                  />

                  <CustomInput
                    id="insurance_rate"
                    labelText="Porcentagem do Seguro (%) Ex: 2.00"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      type: 'decimal',
                      id: 'insurance_rate',
                      required: true,
                      value: createFareState.insurance_rate,
                      onChange: evt => setCreateFareState({
                        ...createFareState,
                        insurance_rate: moneyField(evt.target.value)
                      })
                    }}
                  />

                  <CustomInput
                    id="cancelation"
                    labelText="Taxa de cancelamento (R$)"
                    formControlProps={{fullWidth: true}}
                    inputProps={{
                      type: 'decimal',
                      id: 'cancelation',
                      required: true,
                      value: createFareState.cancelation,
                      onChange: evt => setCreateFareState({
                        ...createFareState,
                        cancelation: moneyField(evt.target.value)
                      })
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={() => handleSubmit()} color="primary">
                Cadastrar
              </Button>
            </DialogActions>
          </Dialog>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem>
              <HeaderItem
                title="Tarifas"
              />
            </GridItem>
            <GridItem>
              <Button
                color="primary"
                onClick={() => handleClickOpen()}
              >
                Cadastrar Tarifa
            </Button>
          </GridItem> 
          </GridContainer>         
          <Grid item md={12} container>
            <ReactTable
              style={{ width: '100%', textAlign: 'center' }}
              className="-striped -highlight"
              data={fares.fares}
              sortable={false}
              defaultPageSize={fares.fares.length}
              pageSize={fares.fares.length}
              pages={1}
              loading={tableLoading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => {
                const obj = {
                  user: null,
                  fare: null,
                };

                state.filtered.forEach(filter => {
                  obj[filter.id] = filter.value;
                });

                setFilterState(obj);               
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {                    
                    //redirectRide(rowInfo.original.id);
                  },
                };
              }}
              columns={[
                {
                  id: 'fare_id',
                  Header: 'Cód.',
                  accessor: row => row.id,
                  filterable: true
                },
                {
                  id: 'user',
                  Header: 'Cliente',
                  accessor: row => row.user ? row.user.name : null,
                  filterable: true
                },
                {
                  id: 'zone',
                  Header: 'Zona',
                  accessor: row => row.zone ? row.zone.note : null,
                  filterable: true
                },
                {
                  id: 'distance',
                  Header: 'Dist.',
                  accessor: row => row.distance_value.toFixed(2)
                },
                {
                  id: 'minimum',
                  Header: 'Mín. Dist.',
                  accessor: row => row.minimum.toFixed(2)
                },
                {
                  id: 'volume',
                  Header: 'Volume',
                  accessor: row => row.volume_value.toFixed(2)
                },
                {
                  id: 'vehicle',
                  Header: 'Veículo',
                  accessor: row => row.vehicle_type === 0 ? "Moto" : "Carro"
                },
                {
                  id: 'content',
                  Header: 'Conteúdo',
                  accessor: row => row.type_of_content === 0 ? "Documentos" : "Pacote"
                },
                {
                  id: 'food',
                  Header: 'Tx. Food',
                  accessor: row => row.food
                },
                {
                  id: 'insurance',
                  Header: 'Seguro',
                  accessor: row => `${row.insurance_rate}%`
                },
                {
                  id: 'cancelation',
                  Header: 'Tx. Canc.',
                  accessor: row => row.cancelation.toFixed(2)
                },
                {
                  id: 'deleteBtn',
                  Header: '',
                  accessor: row => row.deleteBtn,
                  sortable: false,
                  filterable: false,
                  width: 50
                }                
              ]}
              showPageJump={false}
            />
          </Grid>
        </>
      )}
    </Grid>
    </>
  );
}

export default Fares;
