const K_SIZE = 10;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  borderRadius: K_SIZE,
  backgroundColor: 'white',
  textAlign: 'center',
  color: '#000000',
  fontSize: 16,
  padding: 4,
  cursor: 'pointer'
};

// const greatPlaceStyleHover = {
//   ...greatPlaceStyle,
//   border: '5px solid #3f51b5',
//   color: '#f44336'
// };

export { greatPlaceStyle, K_SIZE };
