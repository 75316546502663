/* eslint-disable react-hooks/exhaustive-deps */
/*global google*/
import React, { useState, useEffect, memo } from 'react';
//import Marker from 'components/Marker/Marker';
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker
} from 'react-google-maps';
import car from 'assets/img/pin_car.png';
import bike from 'assets/img/pin_bike.png';

const GoogleMapExample = withGoogleMap(props => (
  <GoogleMap
    defaultCenter={{ lat: -10.9498019, lng: -37.0843285 }}
    defaultZoom={13}
    defaultOptions={{ fullscreenControl: true }}
  >
    <Marker defaultLabel={'O'} defaultIcon={null} position={props.origin} />
    <Marker
      icon={{
        url: props.driverMarker || '',
        scaledSize: new google.maps.Size(36, 36)
      }}
      position={props.driver}
    />
    <Marker
      defaultLabel={'D'}
      defaultIcon={null}
      position={props.destination}
    />
    <DirectionsRenderer
      directions={props.directions}
      options={{
        // preserveViewport: true,
        suppressMarkers: true,
        icon: { scale: 3 },
        polylineOptions: {
          strokeColor: 'rgb(93, 44, 76)'
        }
      }}
    />
  </GoogleMap>
));

function RideMap(props) {
  const [directions, setDirections] = useState();
  const [origin, setOrigin] = useState({ lat: 0, lng: 0 });
  const [destination, setDestination] = useState({ lat: 0, lng: 0 });
  const [driver, setDriver] = useState({ lat: 0, lng: 0 });
  const [driverMarker, setDriverMarker] = useState(car);

  useEffect(() => {
    if (props.driverLoc !== undefined) {
      setDriver(props.driverLoc);
    }
  }, [props.driverLoc]);

  useEffect(() => {
    if (props.driver !== undefined) {
      if (props.driver.vehicle === 1) {
        setDriverMarker(car);
      } else if (props.driver.vehicle === 0) {
        setDriverMarker(bike);
      }
    }
  }, [props.driver]);

  useEffect(() => {
    if (props.origin.lat !== 0 && props.destination.lat !== 0) {
      const directionsService = new google.maps.DirectionsService();
      new google.maps.DirectionsRenderer({ suppressMarkers: true });
      setOrigin(props.origin);
      setDestination(props.destination);

      directionsService.route(
        {
          origin: props.origin,
          destination: props.destination,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [props.origin, props.destination]);

  return (
    <GoogleMapExample
      directions={directions}
      origin={origin}
      destination={destination}
      driver={driver}
      driverMarker={driverMarker}
      containerElement={
        <div style={{ height: `100%`, width: '100%', marginLeft: 0 }} />
      }
      mapElement={<div style={{ height: `100%`, width: '100%' }} />}
    />
  );
}

export default memo(RideMap);
