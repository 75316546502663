import React from 'react';
import Select from 'react-select';
import requestSelect from '../../requests/requestSelect';
import url from '../../requests/url';

const baseUrl = url.url;

const options = [
    { value: 'Inválido', label: 'Inválido' },
    { value: 'Vencido', label: 'Vencido' },
    { value: 'Não Confere', label: 'Não Confere' },
];

class App extends React.Component {
    componentDidMount (){
        this.loadProducts();
    }
    loadProducts = async () => {
        const response = await requestSelect.get(`${baseUrl}/account/reprove-document-messages`);
        const  docs  = response.data;
        console.log(docs);
    };
    render(){
        return (
            <Select 
            options = {options}/>
        );
    }
}
//console.log(options);

export default App;