import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import indexRoutes from './routes/index';

import 'assets/scss/material-dashboard-pro-react.css?v=1.4.0';

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Fragment>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} component={prop.component} key={key} />;
        })}
      </Switch>
      <ToastContainer 
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </Fragment>
  </Router>,
  document.getElementById('root')
);
