import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Grid from '@material-ui/core/Grid';

//const styles = (theme) => ({
//  progress: {
//    margin: theme.spacing(2),
//  },
//});

function Loading(props) {
  const { classes } = props;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <CircularProgress
        color="primary"
        size={100}
      />
    </Grid>
  );
}

export default Loading;
