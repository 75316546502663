/*global google*/
import React, { useState } from 'react';
import CardBody from '../../components/Card/CardBody';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from 'components/Snackbar/Snackbar';
import Button from 'components/CustomButtons/Button';
import {
  CPFMaskCustom,
  PhoneMaskCustom,
  CPNJMaskCustom
} from '../../components/CustomMasks/Masks';
import { verifyCPF } from '../../helpers/functions';
import { businessSignup } from '../../requests/requests';

import ReactGooglePlacesSuggest from 'react-google-places-suggest';
// @material-ui/icons
import BusinessIcon from '@material-ui/icons/Business';
import Check from '@material-ui/icons/Check';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import regularFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const styles = {
  ...extendedFormsStyle,
  ...regularFormsStyle
};

function CreateBusiness(props) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpf, setCpf] = useState('');
  const [document, setDocument] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [address, setAddress] = useState('');
  const [addressNote, setAddressNote] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [ownFleet, setOwnsFleet] = useState(0);
  const [hasInvoice, setHasInvoice] = useState(0);
  const [search, setSearch] = useState('');
  const [snack, setSnack] = useState(false);
  const [snackTxt, setSnackTxt] = useState(false);
  const [color, setColor] = useState('danger');

  function handleChange(name, value) {
    if (name === 'fleet') {
      setOwnsFleet(value);
    } else {
      setHasInvoice(value);
    }
  }

  function handleInputChange(e) {
    setSearch(e.target.value);
    setAddress(e.target.value);
  }

  const closeModal = () => {
    setSnack(false);
  };

  function handleSubmit(evt) {
    evt.preventDefault();

    if (
      !name ||
      !email ||
      !phone ||
      !password ||
      !document ||
      !businessName ||
      !address ||
      !latitude ||
      !longitude ||
      !verifyCPF(cpf.replace(/[\.\-]/g, ''))
    ) {
      setSnackTxt('Alguns campos obrigatórios não foram preenchidos');
      setColor('danger');
      setSnack(true);
      setTimeout(closeModal, 3000);
      return;
    }

    const business = {
      name: name,
      email: email,
      phone: phone,
      password: password,
      cpf: cpf,
      document: document,
      businessName: businessName,
      address: address,
      addressNote: addressNote,
      latitude: latitude,
      longitude: longitude,
      owns_fleet: ownFleet,
      has_invoice: hasInvoice,
      enterprise: true
    };

    businessSignup(business)
      .then(res => {
        setSnackTxt('Empresa cadastrada com sucesso!');
        setColor('success');
        setSnack(true);
        setTimeout(closeModal, 3000);
      })
      .catch(err => {
        setSnackTxt('Error ao cadastrar empresa!');
        setColor('danger');
        setSnack(true);
        setTimeout(closeModal, 3000);
      });
  }

  function handleSelectSuggest(geocodedPrediction, originalPrediction) {
    setLatitude(geocodedPrediction.geometry.location.lat());
    setLongitude(geocodedPrediction.geometry.location.lng());
    setSearch('');
    setAddress(geocodedPrediction.formatted_address);
  }

  const { classes } = props;

  return (
    <>
      <GridContainer>
        <Snackbar
          style={{ marginTop: '0em' }}
          place="tc"
          color={color}
          // icon={Error}
          message={snackTxt}
          open={snack}
        />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BusinessIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Cadastro de empresa</h4>
            </CardHeader>
            <CardBody>
              <InputLabel className={classes.label}>
                Administrador da empresa
              </InputLabel>
              <br />
              <form onSubmit={evt => handleSubmit(evt)}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      id="name"
                      labelText="Nome *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'text',
                        id: 'name',
                        value: name,
                        onChange: evt => setName(evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      id="email"
                      labelText="Email *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'email',
                        id: 'email',
                        value: email,
                        onChange: evt => setEmail(evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      id="cpf"
                      labelText="CPF *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'text',
                        id: 'cpf',
                        value: cpf,
                        inputComponent: CPFMaskCustom,
                        onChange: evt => setCpf(evt.target.value),
                        error: cpf
                          ? !verifyCPF(cpf.replace(/[\.\-]/g, ''))
                          : false
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      id="phone"
                      labelText="Telefone *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'text',
                        id: 'phone',
                        value: phone,
                        inputComponent: PhoneMaskCustom,
                        onChange: evt => setPhone(evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      id="password"
                      labelText="Senha *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      helpText="min 8 caracteres"
                      inputProps={{
                        type: 'password',
                        id: 'password',
                        value: password,
                        onChange: evt => setPassword(evt.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <InputLabel className={classes.label}>
                  Dados da empresa
                </InputLabel>
                <br />
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      id="name-bus"
                      labelText="Nome da empresa *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'text',
                        id: 'businessName',
                        value: businessName,
                        onChange: evt => setBusinessName(evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      id="document"
                      labelText="CNPJ *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'text',
                        id: 'document',
                        value: document,
                        inputComponent: CPNJMaskCustom,
                        onChange: evt => setDocument(evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <ReactGooglePlacesSuggest
                      googleMaps={google.maps}
                      autocompletionRequest={{
                        input: search,
                        componentRestrictions: { country: 'br' }
                      }}
                      onSelectSuggest={handleSelectSuggest}
                      textNoResults="Nenhum endereço encontrado."
                    >
                      <CustomInput
                        id="address"
                        labelText="Endereço *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          id: 'address',
                          value: address,
                          onChange: evt => handleInputChange(evt)
                        }}
                      />
                    </ReactGooglePlacesSuggest>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <CustomInput
                      id="line2"
                      labelText="Complemento"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'text',
                        id: 'addressNote',
                        value: addressNote,
                        onChange: evt => setAddressNote(evt.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.checkboxAndRadio}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleChange('fleet', 1)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Possui Frota ?"
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.checkboxAndRadio}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => handleChange('invoice', 1)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Empresa é faturada ?"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: '30px' }}>
                  <Button round color="primary" type="submit">
                    Cadastrar
                  </Button>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default withStyles(styles)(CreateBusiness);
