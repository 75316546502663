import React, { useState, useEffect } from 'react';

//Libs
import ReactTable from 'react-table';
import { Card, CircularProgress } from '@material-ui/core';

// Requests
import { getDriversList } from '../../../requests/requests';

// Components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderItem from '../Income/HeaderItem';

// Utils
import { useDebounce } from 'utils/hooks';

function DriversTable() {
  const [enterprise] = useState(
    JSON.parse(sessionStorage.getItem('driversDetail'))
  );
  const [id, setId] = useState('');
  const [driversList, setDriversList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filterState, setFilterState] = useState({
    name: '',
    vehicle_type: '',
    vehicle_plate: '',
  });
  const nameFiltered = useDebounce(filterState.name, 1000);
  const vehicleTypeFiltered = useDebounce(filterState.vehicle_type, 1000);
  const vehiclePlateFiltered = useDebounce(filterState.vehicle_plate, 1000);

  /** EFFECTS */
  useEffect(()=>{
    setId(enterprise[0].id);
    if (!!nameFiltered || !!vehicleTypeFiltered || !!vehiclePlateFiltered){
      console.log(enterprise[0].id, nameFiltered, vehiclePlateFiltered, vehicleTypeFiltered)
      getDriversList(enterprise[0].id, nameFiltered, vehiclePlateFiltered, vehicleTypeFiltered)
      .then(res => {
        setDriversList(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });      
    } else {
      getDriversList(enterprise[0].id)
      .then(res => {
        setDriversList(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  },[enterprise, nameFiltered, vehicleTypeFiltered, vehiclePlateFiltered])
  
  return (
    <GridContainer
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '50px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {loading ? <CircularProgress color="secondary" /> : (
        <>
          <GridContainer
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <GridItem>
              <HeaderItem
                title="Total de motoristas"
                value={driversList ? driversList.length : ''}
              />
            </GridItem>
          </GridContainer>
          <div style={{ margin: 15 }} />
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ReactTable
              className="-striped -highlight"
              data={driversList}
              sortable={false}
              defaultPageSize={10}
              pages={Math.ceil(driversList.length / 10)}
              loading={loading}
              previousText="Anterior"
              nextText="Próximo"
              pageText={'Página'}
              ofText={'de'}
              rowsText={'linhas'}
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => {
                const obj = {
                  name: '',
                  vehicle_type: '',
                  vehicle_plate: '',
                };
  
                state.filtered.forEach(filter => {
                  obj[filter.id] = filter.value;
                });
  
                setFilterState(obj);
              }}
              onFilteredChange={state => setFilterState(state)}
              columns={[
                {
                  id: 'name',
                  Header: 'Nome',
                  accessor: row => row.user.name,
                  filterable: true
                },
                {
                  id: 'email',
                  Header: 'Email',
                  accessor: row => row.user.email,
                },
                {
                  id: 'phone',
                  Header: 'Telefone',
                  accessor: row => (row.user.phone),
                },
                {
                  id: 'vehicle_type',
                  Header: 'Tipo do veículo',
                  accessor: row => (row.vehicle.type.name),
                  // filterable: true
                },
                {
                  id: 'vehicle_plate',
                  Header: 'Placa do veículo',
                  accessor: row => (row.vehicle.plate),
                  // filterable: true
                },
              ]}
              showPageJump={false}
            />
          </GridItem>
        </GridContainer>
        </>
      )}
    </GridContainer>
  );
}

export default DriversTable;