import axios from 'axios';
import url from './url';

const baseUrl = url.url;

const token = () => {
  return localStorage.getItem('token');
};

function identify() {
  return axios({
    method: 'get',
    url: `${baseUrl}/account/identify`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function updatePassword(password, newPassword) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/update-password`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { password, newPassword }
  });
}

function addUserCash(id, amount, note, is_deposit) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/add-user-cash`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { id, amount, note, is_deposit }
  });
}

function bankAccount(
  holderName,
  taxDocument,
  bankNumber,
  accountNumber,
  bankBranchNumber,
  variation,
  type
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/bank-account`,
    data: {
      holderName,
      taxDocument,
      bankNumber,
      accountNumber,
      bankBranchNumber,
      variation,
      type
    }
  });
}

function driverHistory(index = 0, length = 30) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/driver-history`,
    headers: { Authorization: `Bearer ${token()}` },
    params: { index, length }
  });
}

function getEnterprisesList() {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/businesses`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function editEnterprise(id, owns_fleet, active) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/edit-businesses `,
    headers: { Authorization: `Bearer ${token()}` },
    data: { id, owns_fleet, active }
  });
}

function createBko(name, email, phone, document, business_id, password){
  return axios({
    method: 'post',
    url: `${baseUrl}/account/signup`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { name, email, phone, document, business_id, password }
  });
}

function getBkoList(id, name, phone, email) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/business-users?businessId=${id}
    ${name ? `&name=${name}&` : ''}
    ${phone ? `phone=${phone}&` : ''}
    ${email ? `email=${email}&` : ''}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getDriversList(id, name, plate, vehicleType, phone, email) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/business-drivers?businessId=${id}
    ${name ? `&name=${name}&` : ''}
    ${plate ? `plate=${plate}&` : ''}
    ${vehicleType ? `vehicleType=${vehicleType}&` : ''}
    ${phone ? `phone=${phone}&` : ''}
    ${email ? `email=${email}&` : ''}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function editDriverBusiness(driver_id, business_id){
  return axios({
    method: 'post',
    url: `${baseUrl}/support/edit-driver-business`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { driver_id, business_id }
  });
}

function getDriverLocation(id) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/driver-location?ride_id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function signIn(email, password) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/signin`,
    data: { email, password }
  });
}

function deactivateDriver(driver_id, disabled_note, is_activate) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/deactivate-driver`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { driver_id, disabled_note, is_activate }
  });
}

function editDriverPercentage(driver_id, percentage) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/edit-driver-percentage`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { driver_id, percentage }
  });
}

function editDriverVehicle(driver_id, vehicle_type) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/edit-driver-vehicle`,
    headers: { Authorization: `Bearer ${token()}` },
    data: { driver_id, vehicle_type }
  });
}

function getDrivers(index = 0, length = 30, list = 'unstated', name = null) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/drivers`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length,
      name,
      ...(Boolean(list) && { list })
    }
  });
}

function getUsers(index = 0, length = 10, name = '', cpf = '', email = '') {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/users?index=${index}&length=${length}&name=${name}&cpf=${cpf}&email=${email}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getRide(id) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/ride-detail?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getZones(
  id = ' ',
  size = ' ',
  available = ' ',
  note = ' ',
  origin_longitude = ' ',
  origin_latitude = ' '
) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/available-zones`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      id,
      size,
      available,
      note,
      origin_longitude,
      origin_latitude
    }
  });
}

function getDriversLocations(id) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/available-drivers-per-zone?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getIncome(index = 0, length = 30, payload) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/income`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length,
      ...payload
    }
  });
}

function getFares(user = null, zone = null) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/fares?${user ? `user=${user}&` : ''}${
      zone ? `zone=${zone}&` : ''
    }`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getDriversQueue(id, vehicle) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/drivers-queue?user=${id}&vehicle=${vehicle}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getBusinessList() {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/businesses`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getSplitters(index = 0, length = 30, name = null) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/splitters`,
    headers: { Authorization: `Bearer ${token()}` },
    params: {
      index,
      length,
      ...(!!name && { name })
    }
  });
}

function updateSplitter(id, splitter) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/update-splitter/?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...splitter
    }
  });
}

function searchSplitter(id) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/splitter/?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getRideHistory(index = 0, length = 30) {
  return axios({
    method: 'get',
    url: `${baseUrl}/ride/user-history`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function getRides(
  index = 0,
  length = 30,
  driver_name = '',
  destination = '',
  status = '',
  dateStart,
  dateEnd,
  id
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/rides`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length,
      destination,
      status,
      driver_name,
      date_start: dateStart,
      date_end: dateEnd,
      id
    }
  });
}

function getAllRides(
  index = 0,
  length,
  driver_name = '',
  destination = '',
  status = '',
  dateStart,
  dateEnd,
  id
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/rides`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length: length,
      destination,
      status,
      driver_name,
      date_start: dateStart,
      date_end: dateEnd,
      id
    }
  });
}

function cancelRide(
  model, 
  currentStatusId = null
) {
  return axios({
    method: 'post',
    url:`${baseUrl}/ride/cancel-ride-as-user?currentStatusId=${currentStatusId}`,
    headers: { Authorization: `Bearer ${token()}` },
    data: model,
  });
}

function getDriverRides(
  index = 0,
  length,
  id,
  external_id,
  origin = '',
  destination = '',
  status = '',
  date_start,
  date_end,
  driver_id,
  all
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/driver-rides`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      index,
      length,
      id,
      external_id,
      origin,
      destination,
      status,
      date_start,
      date_end,
      driver_id,
      all
    }
  });
}

function getUserBalance(
  id,
) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/user-balance?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function updateDriver(id, status) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/update-driver`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      driver_id: id,
      approved: status
    }
  });
}

async function updateDriverDocument(id, document, status) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/update-driver-document`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      driver_id: id,
      approved: status,
      document
    }
  });
}

function userSignUp(name, email, password, phone, cpf) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/signup`,
    data: {
      name,
      email,
      password,
      phone,
      cpf
    }
  });
}

// express riomar

function createSplitter(id, splitter) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/create-splitter/?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...splitter
    }
  });
}

function businessSignup(business) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/business-signup`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...business
    }
  });
}

function driverSignup(
  name,
  email,
  password,
  phone,
  cpf,
  type,
  description,
  plate,
  cityId,
  zipcode,
  district,
  number,
  line1,
  line2,
  holderName,
  taxDocument,
  bankNumber,
  accountNumber,
  bankBranchNumber,
  variation,
  accountType
) {
  return axios({
    method: 'post',
    url: `${baseUrl}/account/driver-signup`,
    data: {
      name,
      email,
      password,
      phone,
      cpf,
      vehicle: {
        plate,
        type,
        description
      },
      address: {
        city_id: cityId,
        zipcode,
        district,
        number,
        line1,
        line2
      },
      bankAccount: {
        holderName,
        taxDocument,
        bankNumber,
        accountNumber,
        bankBranchNumber,
        variation,
        type: accountType
      }
    }
  });
}

function getAddress(zipcode) {
  return axios({
    method: 'get',
    url: `https://viacep.com.br/ws/${zipcode}/json/`
  });
}

function getCities(stateId) {
  return axios({
    method: 'get',
    params: { state_id: stateId },
    url: `${baseUrl}/account/cities`
  });
}

function getStates() {
  return axios({
    method: 'get',
    baseURL: `${baseUrl}/account/states`
  });
}

function sendImage(request, file) {
  const formData = new FormData();
  formData.set('image', file);
  let requisition;

  switch (request) {
    case 'profile':
      requisition = 'user-image';
      break;
    case 'crlv':
      requisition = 'driver-vehicle-document';
      break;
    case 'records':
      requisition = 'driver-criminal-records';
      break;
    default:
      requisition = `driver-${request}`;
      break;
  }

  const Url = `${baseUrl}/account/${requisition}`;
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token()}`
    },
    data: formData,
    baseURL: Url
  });
}

/**
 *
 *
 * @param {String} rideId the ride id
 * @returns url to image
 */
function getRideMap(rideId) {
  return `https://s3.us-east-2.amazonaws.com/beelog/dev/ride-images/${rideId}.png`;
}

function getBusinessReport(businessId, startDate, endDate) {
  return axios({
    method: 'get',
    url: `${baseUrl}/support/business-report?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

function updateBusinessLogo(businessId, file) {
  const formData = new FormData();
  formData.set('image', file);
  let requisition;

  const Url = `${baseUrl}/support/business-logo?businessId=${businessId}`;
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token()}`
    },
    data: formData,
    baseURL: Url
  });
}

function createFare(fare) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/create-fare`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...fare
    }
  });
}

function createZone(zone) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/create-zone`,
    headers: { Authorization: `Bearer ${token()}` },
    data: {
      ...zone
    }
  });
}

function fareDelete(id) {
  return axios({
    method: 'post',
    url: `${baseUrl}/support/fare-delete?id=${id}`,
    headers: { Authorization: `Bearer ${token()}` }
  });
}

export {
  identify,
  signIn,
  getDrivers,
  getZones,
  getDriversLocations,
  getSplitters,
  createZone,
  updateSplitter,
  searchSplitter,
  getRides,
  getAllRides,
  getDriverRides,
  getUserBalance,
  cancelRide,
  updateDriver,
  updateDriverDocument,
  updatePassword,
  bankAccount,
  driverHistory,
  driverSignup,
  userSignUp,
  fareDelete,
  getAddress,
  getRide,
  getStates,
  getCities,
  sendImage,
  getRideMap,
  createSplitter,
  getRideHistory,
  getIncome,
  getFares,
  getDriversQueue,
  getBusinessList,
  getDriverLocation,
  deactivateDriver,
  editDriverPercentage,
  getEnterprisesList,
  editEnterprise,
  businessSignup,
  editDriverVehicle,
  createBko,
  getBkoList,
  getDriversList,
  editDriverBusiness,
  getBusinessReport,
  getUsers,
  createFare,
  addUserCash,
  updateBusinessLogo
};
