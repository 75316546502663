import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';
import Code from '@material-ui/icons/Code';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import Phone from '@material-ui/icons/Phone';
import Email from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
import Description from '@material-ui/icons/Description';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';
import InfoArea from '../../components/InfoArea/InfoArea';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Snackbar from '../../components/Snackbar/Snackbar';

import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle';

import { PhoneMaskCustom, CPFMaskCustom } from '../../components/CustomMasks/Masks';
import { emailRegex, phoneRegex } from '../../helpers/regex';
import { verifyCPF } from '../../helpers/functions';
import { userSignUp } from '../../requests/requests';
import Landing from './Landing';
import catchingErr from '../../requests/requestErr';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      name: '',
      email: '',
      password: '',
      phone: '(  )      -    ',
      cpf: '',
      confirmPassword: '',
      errEmail: false,
      goToNext: false,
      errTxt: '',
      snack: false
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  openModal = () => {
    this.setState({ snack: true });
    setTimeout(this.closeModal, 3000);
  };

  closeModal = () => {
    this.setState({ snack: false });
  };

  handleChange = evt => {
    const { beginValidation } = this.state;

    this.setState({
      [evt.target.id]: evt.target.value.trim()
    });

    if (!beginValidation) {
      this.setState({
        beginValidation: true
      });
    }
  };

  handlePhone = evt => {
    const { beginValidation } = this.state;

    this.setState({
      [evt.target.id]: evt.target.value
    });

    if (!beginValidation) {
      this.setState({
        beginValidation: true
      });
    }
  };

  /**
   *
   *
   * @param {Event} evt
   */
  handleCPF = evt => {
    evt.persist();
    this.setState({
      [evt.target.id]: evt.target.value
    });
    if (!verifyCPF(evt.target.value.replace(/[\.\-]/g, '')))
      evt.target.setCustomValidity('CPF inválido');
    else evt.target.setCustomValidity('');
  };

  handleSubmit = async evt => {
    const { password, confirmPassword, phone, name, email, cpf } = this.state;
    evt.preventDefault();

    if (password !== confirmPassword || !phoneRegex.test(phone.trim())) {
      return;
    }

    try {
      const res = await userSignUp(name, email, password, phone, cpf);
      localStorage.setItem('token', res.data.token);
      this.setState({ goToNext: true });
    } catch (err) {
      console.log(err.response);

      this.setState({
        errTxt: catchingErr(err)
      });
      this.openModal();
    }
  };

  // Auto-generated layout
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }

  render() {
    const { classes } = this.props;
    const {
      beginValidation,
      password,
      confirmPassword,
      name,
      phone,
      email,
      cpf,
      goToNext,
      checked,
      errTxt,
      snack
    } = this.state;

    let isPasswordConfirmed = true;
    let isEmailValid = true;
    let isPhoneValid = true;

    if (beginValidation) {
      isPasswordConfirmed = password === confirmPassword;
      isEmailValid = emailRegex.test(email) || email === '';
      isPhoneValid = phoneRegex.test(phone.trim());
    }

    if (goToNext) {
      return <Landing txt="Cadastro em análise" />;
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <Snackbar
            style={{ marginTop: '0em' }}
            place="tc"
            color="danger"
            icon={Error}
            message={errTxt}
            open={snack}
          />
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Cadastro de Suporte</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title="Marketing"
                      description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                      icon={Timeline}
                      iconColor="rose"
                    />
                    <InfoArea
                      title="Fully Coded in HTML5"
                      description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                      icon={Code}
                      iconColor="primary"
                    />
                    <InfoArea
                      title="Built Audience"
                      description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={5}>
                    <form id="form" className={classes.form} onSubmit={this.handleSubmit}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment}>
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          required: true,
                          id: 'name',
                          placeholder: 'Nome',
                          value: name,
                          onChange: this.handleChange
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment}>
                              <Phone className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          required: true,
                          id: 'phone',
                          placeholder: 'Telefone',
                          value: phone,
                          onChange: this.handlePhone,
                          inputComponent: PhoneMaskCustom,
                          error: !isPhoneValid
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment}>
                              <Description className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          required: true,
                          id: 'cpf',
                          placeholder: 'CPF',
                          value: cpf,
                          onChange: this.handleCPF,
                          inputComponent: CPFMaskCustom,
                          // eslint-disable-next-line no-useless-escape
                          error: cpf ? !verifyCPF(cpf.replace(/[\.\-]/g, '')) : false
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment}>
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          required: true,
                          type: 'email',
                          id: 'email',
                          placeholder: 'Email',
                          value: email,
                          onChange: this.handleChange,
                          error: !isEmailValid || this.state.errEmail
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment}>
                              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                            </InputAdornment>
                          ),
                          required: true,
                          id: 'password',
                          placeholder: 'Senha',
                          value: password,
                          onChange: this.handleChange,
                          type: 'password'
                        }}
                      />
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={classes.inputAdornment}>
                              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                            </InputAdornment>
                          ),
                          required: true,
                          id: 'confirmPassword',
                          placeholder: 'Confirmar senha',
                          value: confirmPassword,
                          onChange: this.handleChange,
                          error: !isPasswordConfirmed,
                          type: 'password'
                        }}
                      />
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={() => this.handleToggle(1)}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            Eu aceito os
                            <a href="#pablo"> termos e condições.</a>
                          </span>
                        }
                      />
                      <div className={classes.center}>
                        <Button disabled={checked.length === 0} round color="primary" type="submit">
                          Registrar
                        </Button>
                      </div>
                    </form>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
