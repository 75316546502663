/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { formatTimeDate } from '../../helpers/functions';
//ICONS
import Car from '@material-ui/icons/CallMergeTwoTone';
import { getRide, getDriverLocation } from '../../requests/requests';

const bdgColorHelper = statusId => {
  switch (statusId) {
    case 1:
      return 'info';
    case 2:
      return 'info';
    case 3:
      return 'warning';
    case 4:
      return 'success';
    case 5:
      return 'danger';
    case 6:
      return 'success';
    case 7:
      return 'warning';
    case 8:
      return 'danger';
    case 9:
      return 'danger';
    default:
      return 'danger';
  }
};

export default function HiddenRide(props) {
  const [status, setStatus] = useState();
  const [ride, setRide] = useState();
  const [driverLocation, setDriverLocation] = useState();

  useEffect(() => {
    if (props.id !== undefined) {
      //props.setLoading(true);
      getRide(props.id).then(res => setRide(res.data));
    }
  }, []);

  useEffect(() => {
    //props.setLoading(false);
    console.log(`ride hiddenRide`,ride)
    getDriverLocation(props.id).then(res => setDriverLocation(res.data));
    if (ride !== undefined) {
      if (
        ride.origin_latitude !== props.origin.lat &&
        ride.origin_longitude !== props.origin.lng
      ) {
        props.setOrigin({
          lat: ride.origin_latitude,
          lng: ride.origin_longitude
        });
      }

      if (
        ride.destination_latitude !== props.destination.lat &&
        ride.destination_longitude !== props.destination.lng
      ) {
        props.setDestination({
          lat: ride.destination_latitude,
          lng: ride.destination_longitude
        });
      }

      setStatus(ride.lastStatus.status_id);

      if (ride.status.length !== props.status.length) {
        props.setStatus(makeTimelineStatus(ride.status));
      }
      if (props.myRide === undefined) {
        props.setRide({
          id: ride.id,
          amount_paid: ride.amount_paid,
          typeName: ride.type.name,
          createdBy: ride.created_by,
          distance: ride.distance,
          originAddress: ride.originAddress,
          destinationAddress: ride.destinationAddress,
          destinationNote: ride.destination_note,
          fare: ride.fare.name,
          num_volumes: ride.num_volumes,
          measures: ride.measures,
          volume: ride.volume,
          is_food: ride.is_food,
          external_id: ride.external_id,
          signature: ride.signature,
          receiver_name: ride.receiver_name,
          receiver_intermediate: ride.receiver_intermediate,
          date: ride.date,
          vehicleType: ride.vehicle_type,
          requester: ride.requester,
          lastStatus: ride.lastStatus,
          price: {
            distance_price: ride.price.distance_price,
            volume_price: ride.price.volume_price,
            insurance_price: ride.price.insurance_price,
            discount_price: ride.price.discount_price,
            driver_price: ride.price.driver_price,
            total_price: ride.price.total_price
          },
          parent_id: ride.parent_id,
          lastStatus: ride.lastStatus,
        });
      }

      if (ride.driver !== undefined) {
        if (props.driver === undefined) {
          props.setDriver({
            name: ride.driver.name,
            phone: ride.driver.phone,
            picture: ride.driver.picture,
            driver: ride.driver.driver,
            vehicle: ride.driver.driver.vehicle.type.id
          });
        }
      }

      return function cleanup() {
        setRide(undefined);
      };
    }
  }, [ride]);

  useEffect(() => {
    if (driverLocation !== undefined) {
      if (
        driverLocation.latitude !== null &&
        driverLocation.longitude !== null
      ) {
        if (
          driverLocation.latitude !== props.driverLoc.lat ||
          driverLocation.longitude !== props.driverLoc.lng
        ) {
          props.setDriverLoc({
            lat: driverLocation.latitude,
            lng: driverLocation.longitude
          });
        }
      }
      if (driverLocation.arrival_time !== null){
        props.setArrivalTime(driverLocation.arrival_time)
      }

      return function cleanup() {
        setDriverLocation(undefined);
      };
    }
  }, [driverLocation]);

  useEffect(() => {
    if (status !== undefined) {
      let interval = setInterval(function() {
        // console.log(status);
        if (status === 1) {
          getRide(props.id).then(res => setRide(res.data));
        } else if (status >= 2 && status <= 4) {
          getDriverLocation(props.id).then(res => setDriverLocation(res.data));
        } else if (status >= 5) {
          clearInterval(interval);
          return;
        }
      }, 10000);

      return function cleanup() {
        clearInterval(interval);
        interval = null;
      };
    }
  }, [status]);

  /** FUNCTIONS */
  function makeTimelineStatus(status) {
    const newStatus = status.map(item => ({
      inverted: item.status_id % 2 === 0,
      badgeColor: bdgColorHelper(item.status_id),
      badgeIcon: Car,
      title: item.name,
      titleColor: bdgColorHelper(item.status_id),
      body: (
        <div>
          <b>Data: </b>
          {`${formatTimeDate(item.created_at)[0]}`}
          <br />
          <b>Hora: </b>
          {`${formatTimeDate(item.created_at)[1]}`}
          {item.latitude && item.longitude ? (
            <div>
              <b>Posição: </b>
              {`${item.latitude},${item.longitude}`}
            </div>
          ) : (
            ''
          )}
          <br />
        </div>
      ),
      footerTitle: item.note ? item.note : ''
    }));

    return newStatus;
  }

  function callRide(id) {
    props.getRide({ id });
  }

  return <div />;
}

// const mapStateToProps = state => {
//   return {
//     ride: state.ride.ride,
//     driverLocation: state.ride.driverLocation
//   };
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     getRide: id => dispatch(getRide(id)),
//     resetRide: prop => dispatch(resetRide(prop)),
//     getDriverLocation: id => dispatch(getDriverLocation(id))
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(HiddenRide);
