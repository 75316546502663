/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { withScriptjs } from 'react-google-maps';
// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Print from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import SweetAlert from 'react-bootstrap-sweetalert';
// core components
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Timeline from '../../components/Timeline/Timeline';
import HiddenRide from '../Components/HiddenRide';
import Loading from '../../components/Loading/Loading.jsx';
import { GOOGLE_KEY } from '../../helpers/constants';
import { formatTimeDate } from '../../helpers/functions';
import RideMap from '../Maps/RideMap';
import { formatMoney } from '../../helpers/functions';
import { withStyles } from '@material-ui/core';
import Button from '../../components/CustomButtons/Button';

import Notification from 'components/Notification/Notification';

import { cancelRide } from '../../requests/requests';
// const adminUseStyles = makeStyles(adminStyles);

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     '& > *': {
//       margin: theme.spacing(1)
//     }
//   },
//   small: {
//     width: theme.spacing(3),
//     height: theme.spacing(3)
//   },
//   large: {
//     width: theme.spacing(10),
//     height: theme.spacing(10)
//   }
// }));

const styles = {
  ...appStyle,
  cardImgTop: {
    width: '100%',
    borderTopLeftRadius: 'calc(.25rem - 1px)',
    borderTopRightRadius: 'calc(.25rem - 1px)'
  }
};

let MapLoader;

function Ride(props) {
  const [ride, setRide] = useState();
  const [status, setStatus] = useState([]);
  const [origin, setOrigin] = useState({ lat: 0, lng: 0 });
  const [destination, setDestination] = useState({ lat: 0, lng: 0 });
  const [driver, setDriver] = useState();
  const [driverLoc, setDriverLoc] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const [logo] = React.useState(require('assets/img/logo-beelog-horiz.png'));
  const [arrivalTime, setArrivalTime] = useState(null);

  const [canCancel, setCanCancel] = useState(false);
  const [canceledRide, setCanceledRide] = useState();
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');

  const [alert, setAlert] = useState();

  const componentRef = useRef();

  console.log('ride', ride);

  useEffect(() => {
    document.title = `Frete: ${props.match.params.id}`;
    MapLoader = withScriptjs(RideMap);
  }, []);

  useEffect(
    () => () => {
      document.title = `Beelog`;
    },
    []
  );

  useEffect(() => {
    console.log(arrivalTime);
  }, [arrivalTime]);

  useEffect(() => {
    if (ride !== undefined) {
      console.log(`ride`, ride);
      if (
        ride.lastStatus.status_id === 0 ||
        ride.lastStatus.status_id === 1 ||
        ride.lastStatus.status_id === 3 ||
        ride.lastStatus.status_id === 4
      ) {
        setCanCancel(true);
      }
    }
  }, [ride]);

  useEffect(() => {
    if (canceledRide !== undefined) {
      console.log(`canceledRide`, canceledRide);
    }
  }, [canceledRide]);

  function openWhatsApp() {
    window.open(
      `https://api.whatsapp.com/send?1=pt_BR&phone=55${driver.phone}`,
      '_blank'
    );
  }

  function handleCancelRide() {
    console.log(`cancelar corrida`);
    hideCancelAlert();
    cancelRide(
      {
        ride_id: ride.parent_id ? ride.parent_id : ride.id,
        currentStatusId: ride.lastStatus.status_id
      },
      ride.lastStatus.status_id
    )
      .then(response => {
        setCanCancel(false);
        setCanceledRide(response.data);
        setNotification(true);
        setMessage('Corrida cancelada com sucesso!');
        setColor('success');
      })
      .catch(err => {
        console.log(err);
        setNotification(true);
        setMessage('Não foi possível cancelar a corrida. Tente novamente.');
        setColor('danger');
      });
  }

  function showCancelAlert(document, approved) {
    setAlert(
      <SweetAlert
        warning
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title="Cancelar corrida"
        onConfirm={() => handleCancelRide()}
        onCancel={() => hideCancelAlert()}
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        Você tem certeza que quer cancelar a corrida?
      </SweetAlert>
    );
  }

  function hideCancelAlert() {
    setAlert(null);
  }

  // styles
  const { classes } = props;

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {
          <>
            {notification ? (
              <Notification
                message={message}
                color={color}
                setNotification={setNotification}
              />
            ) : (
              ''
            )}
            {alert}
            {loading ? (
              <Loading />
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {MapLoader ? (
                    <div
                      style={{
                        height: 350,
                        width: '100%',
                        display: 'flex',
                        flexFlow: 'row nowrap',
                        justifyContent: 'center',
                        padding: 0
                      }}
                    >
                      <MapLoader
                        driverLoc={driverLoc}
                        driver={driver}
                        origin={origin}
                        destination={destination}
                        className={classes.cardImgTop}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_KEY}`}
                        loadingElement={<div style={{ height: `100%` }} />}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                  {ride ? (
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        padding: '10px 10px 10px 10px'
                      }}
                    >
                      <Card>
                        <CardBody>
                          {canCancel ? (
                            <Button
                              outline
                              color="primary"
                              onClick={() => showCancelAlert()}
                            >
                              Cancelar corrida
                            </Button>
                          ) : (
                            ''
                          )}
                          <ReactToPrint
                            trigger={() => (
                              <Button
                                style={{ float: 'right' }}
                                justIcon
                                round
                                color="primary"
                              >
                                <Print />
                              </Button>
                            )}
                            content={() => componentRef.current}
                          />

                          <h3>
                            Código:&nbsp;
                            {props.match.params.id}
                          </h3>
                          {ride.requester ? (
                            <h4>
                              Cliente:&nbsp;
                              {ride.requester.business
                                ? ride.requester.business.name
                                : ride.requester.name}
                            </h4>
                          ) : (
                            ''
                          )}
                          {ride.createdBy ? (
                            <Typography>
                              Frete solicitado por:
                              {ride.createdBy.name}
                            </Typography>
                          ) : (
                            ''
                          )}
                          <Typography>
                            {formatTimeDate(ride.date)[0]} -
                            {formatTimeDate(ride.date)[1]}
                          </Typography>
                          <br />
                          <h4>
                            <b>Coleta em:</b> {ride.originAddress}
                          </h4>
                          <h4>
                            <b>Destino:</b> {ride.destinationAddress}
                          </h4>
                          <h4>{ride.destinationNote}</h4>
                          {ride.receiver_name ? (
                            <h4>
                              <b>Destinatário:</b> {ride.receiver_name}
                            </h4>
                          ) : (
                            ''
                          )}
                          {ride.receiver_intermediate ? (
                            <h4>
                              <b>A/C:</b> {ride.receiver_intermediate}
                            </h4>
                          ) : (
                            ''
                          )}
                          <hr />

                          {driver ? (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div
                                  align="center"
                                  style={{ width: '100%', align: 'center' }}
                                >
                                  <Avatar
                                    alt={driver.name}
                                    src={driver.picture}
                                    className={classes.large}
                                  />
                                  <br />
                                </div>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={6}>
                                <h4>
                                  <b>Motorista</b>
                                </h4>
                                <h4>{driver.name}</h4>
                                <Button
                                  onClick={() => openWhatsApp()}
                                  style={{
                                    backgroundColor: 'lightgreen',
                                    color: 'white'
                                  }}
                                  round
                                >
                                  WhatsApp
                                </Button>
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12} lg={6}>
                                <h4>
                                  <b>Veículo</b>
                                </h4>
                                <h4>
                                  Placa:
                                  {driver.driver.vehicle.plate}
                                </h4>
                                <h4>{driver.driver.vehicle.description}</h4>
                              </GridItem>
                            </GridContainer>
                          ) : (
                            ''
                          )}
                          <hr />

                          <h4>
                            <b>Informações do frete</b>
                          </h4>

                          <h4>
                            <b>Distância:</b>{' '}
                            {(ride.distance / 1000)
                              .toFixed(2)
                              .replace('.', ',')}
                             km
                          </h4>
                          <h4>
                            <b>Tipo de veículo: </b> {ride.vehicleType.name}
                          </h4>
                          <h4>
                            <b>Quantidade de volumes: </b> {ride.num_volumes}
                          </h4>
                          <h4>
                            <b>Tipo de conteúdo: </b>{' '}
                            {ride.measures || ride.volume || ride.is_food == 1
                              ? 'Pacote'
                              : 'Documentos'}
                          </h4>
                          {ride.is_food == 1 ? (
                            <div>
                              <h4>Food</h4>
                            </div>
                          ) : (
                            ''
                          )}
                          {ride.volume !== null ? (
                            <div>
                              <h4>Dimesões do pacote:</h4>
                              <h4>
                                {`${ride.volume.length} (C) x ${ride.volume.width} (L) x ${ride.volume.height} (A)`}
                              </h4>
                              <h4>Peso:</h4>
                              <h4>{`${ride.volume.weight}kg`}</h4>
                              <h4>Cubagem do pacote:</h4>
                              <h4>{`${ride.volume.calc_weight}kg`}</h4>
                            </div>
                          ) : (
                            ''
                          )}
                          <hr />

                          <h4>
                            <b>Composição do preço</b>
                          </h4>
                          {ride.lastStatus.status_id === 5 ? (
                            <>
                              {ride.amount_paid !== null ? (
                                <h4>
                                  {`Taxa de cancelamento: R$ ${formatMoney(
                                    ride.amount_paid
                                  )}`}
                                </h4>
                              ) : (
                                <h4>Não houve cobrança </h4>
                              )}
                            </>
                          ) : (
                            <>
                              <h4>{`Distância: R$ ${formatMoney(
                                ride.price.distance_price
                              )}`}</h4>
                              <h4>{`Volume: R$ ${formatMoney(
                                ride.price.volume_price
                              )}`}</h4>
                              <h4>{`Gris: R$ ${formatMoney(
                                ride.price.insurance_price
                              )}`}</h4>
                              <h4 style={{ color: 'green' }}>
                                <b>{`Total: R$ ${formatMoney(
                                  ride.price.total_price
                                )}`}</b>
                              </h4>
                              <h4>{`Repasse p/ motorista: R$ ${formatMoney(
                                ride.price.driver_price
                              )}`}</h4>
                            </>
                          )}

                          <hr />
                          {ride.signature ? (
                            <div>
                              <h4>
                                <b>Assinatura do recebedor</b>
                              </h4>
                              <img src={`${ride.signature}`} width={'100%'} />
                            </div>
                          ) : (
                            ''
                          )}
                        </CardBody>
                      </Card>

                      <div style={{ display: 'none' }}>
                        <div
                          style={{
                            width: '210mm',
                            height: '297mm',
                            margin: '2',
                            display: 'block'
                          }}
                        >
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12} zeroMinWidth>
                              <div
                                style={{ padding: '10%', flexShrink: 2 }}
                                ref={componentRef}
                              >
                                <div
                                  style={{
                                    height: '70%',
                                    padding: '10px 10px 10px 10px'
                                  }}
                                >
                                  <img src={logo} width={150} />

                                  <h4>
                                    Código:&nbsp;
                                    {props.match.params.id}
                                  </h4>
                                  {ride.requester ? (
                                    <h6>
                                      Cliente:&nbsp;
                                      {ride.requester.business
                                        ? ride.requester.business.name
                                        : ride.requester.name}
                                    </h6>
                                  ) : (
                                    ''
                                  )}
                                  {ride.createdBy ? (
                                    <Typography variant="caption">
                                      Frete solicitado por:
                                      {ride.createdBy.name}
                                    </Typography>
                                  ) : (
                                    ''
                                  )}
                                  <Typography variant="caption">
                                    {formatTimeDate(ride.date)[0]} -
                                    {formatTimeDate(ride.date)[1]}
                                  </Typography>
                                  <br />
                                  <h6>
                                    <b>Coleta em:</b> {ride.originAddress}
                                  </h6>
                                  <h6>
                                    <b>Destino:</b> {ride.destinationAddress}
                                  </h6>
                                  <h6>{ride.destinationNote}</h6>
                                  {ride.receiver_name ? (
                                    <h6>
                                      <b>Destinatário:</b> {ride.receiver_name}
                                    </h6>
                                  ) : (
                                    ''
                                  )}
                                  {ride.receiver_intermediate ? (
                                    <h6>
                                      <b>A/C:</b> {ride.receiver_intermediate}
                                    </h6>
                                  ) : (
                                    ''
                                  )}
                                  <hr />

                                  {driver ? (
                                    <GridContainer>
                                      <GridItem xs={12} sm={12} md={12} lg={6}>
                                        <h6>
                                          <b>Motorista</b>
                                        </h6>
                                        <h6>{driver.name}</h6>
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={12} lg={6}>
                                        <h6>
                                          <b>Veículo</b>
                                        </h6>
                                        <h6>
                                          Placa:
                                          {driver.driver.vehicle.plate}
                                        </h6>
                                        <h6>
                                          {driver.driver.vehicle.description}
                                        </h6>
                                      </GridItem>
                                    </GridContainer>
                                  ) : (
                                    ''
                                  )}
                                  <hr />

                                  <h6>
                                    <b>Informações do frete</b>
                                  </h6>

                                  <h6>
                                    <b>Distância:</b>{' '}
                                    {(ride.distance / 1000)
                                      .toFixed(2)
                                      .replace('.', ',')}
                                     km
                                  </h6>
                                  <h6>
                                    <b>Tipo de veículo: </b>{' '}
                                    {ride.vehicleType.name}
                                  </h6>
                                  <h6>
                                    <b>Quantidade de volumes: </b>{' '}
                                    {ride.num_volumes}
                                  </h6>
                                  <h6>
                                    <b>Tipo de conteúdo: </b>{' '}
                                    {ride.measures ||
                                    ride.volume ||
                                    ride.is_food == 1
                                      ? 'Pacote'
                                      : 'Documentos'}
                                  </h6>
                                  {ride.is_food == 1 ? (
                                    <div>
                                      <h6>Food</h6>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  {ride.volume !== null ? (
                                    <div>
                                      <h6>Dimesões do pacote:</h6>
                                      <h6>
                                        {`${ride.volume.length} (C) x ${ride.volume.width} (L) x ${ride.volume.height} (A)`}
                                      </h6>
                                      <h6>Peso:</h6>
                                      <h6>{`${ride.volume.weight}kg`}</h6>
                                      <h6>Cubagem do pacote:</h6>
                                      <h6>{`${ride.volume.calc_weight}kg`}</h6>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <hr />

                                  <h6>
                                    <b>Composição do preço</b>
                                  </h6>
                                  {ride.lastStatus.status_id === 5 ? (
                                    <>
                                      {ride.amount_paid !== null ? (
                                        <h6>
                                          {`Taxa de cancelamento: R$ ${formatMoney(
                                            ride.amount_paid
                                          )}`}
                                        </h6>
                                      ) : (
                                        <h6>Não houve cobrança </h6>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <h6>{`Distância: R$ ${formatMoney(
                                        ride.price.distance_price
                                      )}`}</h6>
                                      <h6>{`Volume: R$ ${formatMoney(
                                        ride.price.volume_price
                                      )}`}</h6>
                                      <h6>{`Gris: R$ ${formatMoney(
                                        ride.price.insurance_price
                                      )}`}</h6>
                                      <h6 style={{ color: 'green' }}>
                                        <b>{`Total: R$ ${formatMoney(
                                          ride.price.total_price
                                        )}`}</b>
                                      </h6>
                                      <h6>{`Repasse p/ motorista: R$ ${formatMoney(
                                        ride.price.driver_price
                                      )}`}</h6>
                                    </>
                                  )}

                                  <hr />
                                  {ride.signature ? (
                                    <div>
                                      <h6>
                                        <b>Assinatura do recebedor</b>
                                      </h6>
                                      <img
                                        src={`${ride.signature}`}
                                        width={'11%'}
                                        style={{ transform: `rotate(90deg` }}
                                      />
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <hr />
                                </div>
                              </div>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Loading />
                  )}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  {arrivalTime !== null ? (
                    <Card>
                      <CardBody>
                        <div style={{ textAlign: 'center' }}>
                          <h5 style={{ color: '#4a3385', fontWeight: 'bold' }}>
                            Tempo estimado de chegada
                          </h5>
                          <h4 style={{ color: '#4a3385', fontWeight: 'bold' }}>
                            {arrivalTime}.
                          </h4>
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    ''
                  )}
                  <Card plain>
                    {/* <CardBody plain> */}
                    <Timeline stories={status} />
                    {/* </CardBody> */}
                  </Card>
                </GridItem>
                <HiddenRide
                  id={props.match.params.id}
                  origin={origin}
                  destination={destination}
                  setOrigin={setOrigin}
                  setDestination={setDestination}
                  setStatus={setStatus}
                  status={status}
                  setRide={setRide}
                  myRide={ride}
                  setDriver={setDriver}
                  driver={driver}
                  setDriverLoc={setDriverLoc}
                  driverLoc={driverLoc}
                  setLoading={setLoading}
                  setArrivalTime={setArrivalTime}
                />
              </GridContainer>
            )}
          </>
        }
      </div>
    </div>
  );
}

export default withStyles(styles)(Ride);
