import React from 'react';
import { withStyles, Tooltip, Typography } from '@material-ui/core';
import { greatPlaceStyle } from './styles';
import Button from 'components/CustomButtons/Button';

import { getDrivers } from '../../requests/requests';

export default function Marker(props) {
  const { text, tooltip, available, name, type } = props;

  const style = greatPlaceStyle;
  let border = '5px solid #000';
  switch (available) {
    case 'available':
      border = '2px solid #00FF00';
      break;
    case 'inRide':
      border = '2px solid #0000FF';
      break;
    case 'unavailable':
      border = '2px solid #ff0000';
      break;
    default:
      break;
  }

  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      '& b': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  }))(Tooltip);

  async function getDriverInfo(name){
    // evt.preventDefault();
    const res = await getDrivers(0, 10, '', name, '');
    setTimeout(()=>{
      redirect(res.data.drivers[0])
    },1000)
  }

  function redirect(driver){
    sessionStorage.setItem('driverDetail', JSON.stringify(driver));
    window.location.href = '/driver-detail';
  }
  
  return (
    <>
    <Button
      simple
      onClick={()=>getDriverInfo(name)}
    >
      <HtmlTooltip
      title={
        <React.Fragment>
          <Typography component="h3">{text}</Typography>
          <em>{tooltip}</em>
          <br/>
          <em>{type}</em>
        </React.Fragment>
      }
    >
      <div style={{ ...style, border }}>

        {/* {text} */}
      </div>
    </HtmlTooltip>
    </Button>
    
  </>
  );
}
