import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  // IconButton,
  TextField,
  // Button
} from '@material-ui/core';
// import ClearIcon from '@material-ui/icons/Clear';
import ReactTable from 'react-table';
import { getRides, getAllRides } from '../../requests/requests';
import { formatTimeDate } from '../../helpers/functions';
import {CSVLink} from "react-csv";
import moment from 'moment';
import Button from '../../components/CustomButtons/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';

function RideHistory(props) {
  const [loading, setLoading] = useState(true);
  const [rides, setRides] = useState([]);
  const [pages, setPages] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [filterState, setFilterState] = useState(null);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

  const [count, setCount] = useState();
  const [buttonLabel, setButtonLabel] = useState();
  const [allRides, setAllRides] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvLink, setCsvLink] = useState();
  const [csvLoading, setCsvLoading] = useState(false);

  const headers = [
    {
      label: 'Frete',
      key: 'id',
    },
    {
      label: 'Controle',
      key: 'control',
    },
    {
      label: 'Destino',
      key: 'to',
    },
    {
      label: 'Distância (m)',
      key: 'distance',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: 'Motorista',
      key: 'driver',
    },
    {
      label: 'BackOffice',
      key: 'bko',
    },
    {
      label: 'Data/Hora',
      key: 'date',
    },
    {
      label: 'Duração',
      key: 'duration',
    },
    {
      label: 'Tipo de conteúdo',
      key: 'type',
    },
    {
      label: 'Cubagem (m³)',
      key: 'volume',
    },
    {
      label: 'Valor do Frete - Distância',
      key: 'price_distance',
    },
    {
      label: 'Valor do Frete - Volume',
      key: 'price_volume',
    },
    {
      label: 'Valor do Frete - Total',
      key: 'price_total',
    },
    {
      label: 'Valor - Gris',
      key: 'price_gris',
    },
    {
      label: 'Observação',
      key: 'obs',
    },
  ];

  useEffect(() => {
    if (
      filterState !== null &&
      ((!dateStart && !dateEnd) || (dateStart && dateEnd))
    ) {
      let timeOutFunction = setTimeout(() => {
        fetchData(filterState);
      }, 500);
      
      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    }
  }, [filterState, dateStart, dateEnd]);

  // console.log('pages',pages)
  
  function redirectRide(id) {
    window.open(`/ride/${id}`, '_blank');
  }

  async function fetchData(state) {
    setLoading(true);

    let count;
    const status = state.filtered.find(obj => obj.id === 'colStatus');

    const destination = state.filtered.find(
      obj => obj.id === 'destinationAddress'
    );

    const driverName = state.filtered.find(obj => obj.id === 'colDriver');

    const id = state.filtered.find(obj => obj.id === 'colFrete');

    try {
      const res = await getRides(
        state.page * pageSize,
        pageSize,
        driverName ? driverName.value : '',
        destination ? destination.value : '',
        status ? status.value : '',
        dateStart || undefined,
        dateEnd || undefined,
        id ? id.value : undefined
      );
      setRides(res.data.rides);
      count = res.data.count;
      setCount(res.data.count);
      setPages(Math.ceil(res.data.count / pageSize));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(()=>{
    if (allRides.length === Number(count)){
      let data = [];
      allRides.map(r=>{
        let diffM = null;
        let diffH = null;
        let diffD = null;
        let type = '';
        let d0 = moment(r.date);
        let d = moment(r.lastStatus.created_at);
        diffM = d.diff(d0,"minutes");
        if (diffM > 60){
          diffH = d.diff(d0,'hours');
        }
        if (diffH > 24){
          diffD = d.diff(d0,'days');
        }
        // Condição para verificar o tipo 
        if (r.is_food){
          type = 'Comida'
        } else if (r.volume !== null || r.measures !== null){
          type = 'Pacote'
        } else {
          type = 'Documento'
        }
        data.push({
          "id": r.id,
          "control": r.external_id ? r.external_id : '',
          "to": r.destinationAddress,
          "distance": r.distance ? r.distance : '',
          'date': r.date,
          'status': r.lastStatus.name,
          'driver': r.driver ? r.driver.name : '',
          'bko': r.created_by ? r.created_by.name : '',
          'duration': diffD !== null ? diffD + ' dias' 
            : diffH !== null ? diffH + ' horas'
            : diffM > 0 ? diffM + ' min': '0 min',
          'type': type, 
          'volume': r.volume === null ? '' : r.volume.calc_weight ,
          'price_distance': r.price ? r.price.distance_price : '',
          'price_volume': r.price ? r.price.volume_price : '',
          'price_total': r.price ? r.price.total_price : '',
          'price_gris' : r.price ? r.price.insurance_price : '',
          'obs': r.lastStatus ? r.lastStatus.note === null ? '' : r.lastStatus.note : ''
        });
      });
      setCsvData(data);
    } else {
      setButtonLabel('Exportar')
    }
  },[allRides, count])

  useEffect(()=>{
    if (csvData.length > 0){

      downloadCSV();      
    }    
  },[csvData])

  console.log('dateStart',dateStart)
  async function requestCSV(){
    if (filterState.filtered.length > 0 || dateStart ){
      setButtonLabel('Preparando');
      setCsvLoading(true);

      const status = filterState.filtered.find(obj => obj.id === 'colStatus');
  
      const destination = filterState.filtered.find(
        obj => obj.id === 'destinationAddress'
      );
  
      const driverName = filterState.filtered.find(obj => obj.id === 'colDriver');
  
      const id = filterState.filtered.find(obj => obj.id === 'colFrete');
  
      
      try {
        const allRes = await getAllRides(
          0,
          count,
          driverName ? driverName.value : '',
          destination ? destination.value : '',
          status ? status.value : '',
          dateStart || undefined,
          dateEnd || undefined,
          id ? id.value : undefined
        );
        setAllRides(allRes.data.rides);
        setButtonLabel('Exportar');
      } catch (err) {
        console.log('err',err)
      }
    } else {
      setButtonLabel('Preparando');
      setCsvLoading(true);
      try {
        const allRes = await getAllRides(
          0,
          count
        );
        setAllRides(allRes.data.rides);
        setButtonLabel('Exportar');
      } catch (err) {
        console.log('err',err)
      }
    }
  }

  function downloadCSV(){
    if(allRides !== undefined){
      setCsvLoading(false);
      if (csvLink !== undefined && csvLink !== null){        
        csvLink.link.click();
      }

    }else{      
      requestCSV();
    }
    // if (csvData.length === Number(count)){
    //   setTimeout(()=>{
    //     csvLink.link.click();
    //   },500)
    // }
  }

  console.log('csvLoading',csvLoading)
  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container >
              <Grid container item xs={3} spacing={2} >
                <TextField
                  id="dateStart"
                  label="Data Início"
                  type="date"
                  value={dateStart}
                  onChange={e => {
                    if (!dateEnd) {
                      setDateStart(e.target.value);
                      setDateEnd(e.target.value);
                    } else setDateStart(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />               
              </Grid>
              <Grid container item xs={3} spacing={2} >
                <TextField
                  id="dateEnd"
                  label="Data Fim"
                  type="date"
                  value={dateEnd}
                  onChange={e => {
                    if (!dateEnd) {
                      setDateStart(e.target.value);
                      setDateEnd(e.target.value);
                    } else setDateEnd(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid container item xs={3} spacing={1} >
                <div style={{textAlign:'initial'}}>
                  <Button
                    simple
                    color="primary"
                    onClick={() => {
                      setDateStart('');
                      setDateEnd('');
                    }}
                  >
                    {"Limpar"}
                  </Button>
                </div>
              </Grid>
              <Grid container item xs={3} spacing={1}></Grid>
            </Grid>
            <br></br>
            <Grid container direction='row' justify='space-between' alignItems='center' >
            {/* <Grid container direction='row' justify='flex-end' alignItems='center' >   */}
              {/* <Button
                simple
                color="primary"
                onClick={() => {
                  setDateStart('');
                  setDateEnd('');
                }}
              >
                {"Limpar"}
              </Button> */}
              <Typography variant='caption'>Os valores exportados serão iguais aos presentes na tabela no momento da exportação.</Typography>
              {csvData.length === Number(count) ? 
                <>
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    separator={";"}
                    filename="adminDataRides.csv"
                    className="hidden"
                    ref={(r) => setCsvLink(r)}
                    target="_blank"
                  />
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => downloadCSV()}
                  >
                    BAIXAR
                  </Button>
                </>
              :
              <div style={{
                position:'relative',
                margin:1
              }}>
                <Button
                  outline
                  color="primary"
                  size="sm"
                  disabled={csvLoading}
                  onClick={() => requestCSV()}
                >
                  {buttonLabel}
                </Button>
                {csvLoading && <CircularProgress size={24} style={{
                    color: '#FFF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12}} 
                  />
                }
              </div>
              }
            </Grid>
            <br></br><br></br>
            <ReactTable
              className="-striped -highlight"
              data={rides}
              pages={pages}
              sortable={false}
              loading={loading}
              defaultPageSize={pageSize}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              pageText={"Página"}
              ofText={"de"}
              rowsText={"linhas"}
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => setFilterState(state)}
              onFilteredChange={state => setFilterState(state)}
              filter
              getTdProps={(state, rowInfo) => {
                return {
                  onClick: () => {
                    redirectRide(rowInfo.original.id);
                  }
                };
              }}
              columns={[
                {
                  id: 'colFrete',
                  accessor: 'id',
                  Header: 'Cód. Frete',
                  filterable: true
                },
                {
                  Header: 'Destino',
                  accessor: 'destinationAddress',
                  filterable: true
                },
                {
                  id: 'colDistance',
                  Header: 'Distancia',
                  accessor: row =>
                    `${(row.distance / 1000).toFixed(1).replace('.', ',')} km`
                },
                {
                  id: 'colStatus',
                  Header: 'Status',
                  accessor: row => (row.lastStatus ? row.lastStatus.name : '~'),
                  width: 100,
                  filterable: true
                },
                {
                  id: 'colDriver',
                  Header: 'Motorista',
                  filterable: true,
                  accessor: row => {
                    if (row.driver_id) {
                      return row.driver.name;
                    }
                    return '-';
                  }
                },
                {
                  id: 'colCreatedAt',
                  Header: 'Data',
                  accessor: row =>
                    row.lastStatus
                      ? `${formatTimeDate(row.lastStatus.created_at)[0]} - 
                  ${formatTimeDate(row.lastStatus.created_at)[1]}`
                      : '~'
                },
                 {
                  id: 'colBtn',
                  Header: '',
                  sortable: false
                 }
              ]}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default RideHistory;
