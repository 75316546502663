import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Error from '@material-ui/icons/Error';

// core components
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardFooter from '../../components/Card/CardFooter';
import Snackbar from '../../components/Snackbar/Snackbar';

import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import { signIn, identify } from '../../requests/requests';
import catchingErr from '../../requests/requestErr';
import Landing from './Landing';
import DriverDocs from './DriverDocs';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      email: '',
      password: '',
      snack: false,
      errTxt: '',
      goToDashboard: false,
      goToDocuments: false,
      goToLanding: false,
      landTxt: ''
    };

    // this.checkLogin();
  }

  componentDidMount() {
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  checkLogin = () => {
    if (localStorage.getItem('token')) {
      identify().then(res => {
        sessionStorage.setItem('user', JSON.stringify(res.data));
        this.setState(prevState => ({
          ...prevState,
          goToDashboard: true
        }));
      });
    }
  };

  openModal = () => {
    this.setState({ snack: true });
    setTimeout(this.closeModal, 3000);
  };

  closeModal = () => {
    this.setState({ snack: false });
  };

  goToDashboard = () => {
    this.setState({ goToDashboard: true });
  };

  goToDocuments = () => {
    this.setState({ goToDocuments: true });
  };

  goToLanding = text => {
    this.setState({ goToLanding: true, landTxt: text });
  };

  handleChange = evt => {
    this.setState({
      [evt.target.id]: evt.target.value
    });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    const { email, password } = this.state;

    signIn(email, password)
      .then(res => {
        localStorage.setItem('token', res.data.token);
        identify().then(resIdentify => {
          const user = resIdentify.data;
          console.log('user :', user);
          sessionStorage.setItem('user', JSON.stringify(user));
          if (user.type !== 2) {
            this.setState({ errTxt: 'Usuário inválido' });
            this.openModal();
            sessionStorage.removeItem('user');
          } else this.goToDashboard();
        });
      })
      .catch(err => {
        this.setState({ errTxt: catchingErr(err) });
        this.openModal();
      });
  };

  render() {
    const { classes } = this.props;
    const {
      cardAnimaton,
      email,
      password,
      snack,
      errTxt,
      goToDashboard,
      goToLanding,
      goToDocuments,
      forget,
      landTxt
    } = this.state;

    if (goToDashboard) return <Redirect to="/driver-history" />;
    if (goToLanding) return <Landing txt={landTxt} />;
    if (goToDocuments) return <DriverDocs />;
    if (forget) return <Redirect to="/driver/recovery" />;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <Snackbar
            style={{ marginTop: '0em' }}
            place="tc"
            color="danger"
            icon={Error}
            message={errTxt}
            open={snack}
          />
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.handleSubmit}>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                  <h4 className={classes.cardTitle}>BackOffice</h4>
                  {/* <div className={classes.socialLine}>
                    {['fab fa-facebook-square', 'fab fa-twitter', 'fab fa-google-plus'].map(
                      (prop, key) => {
                        return (
                          <Button
                            color="transparent"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      }
                    )}
                  </div> */}
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="E-mail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      required: true,
                      id: 'email',
                      type: 'email',
                      value: email,
                      onChange: this.handleChange
                    }}
                  />
                  <CustomInput
                    labelText="Senha"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      id: 'password',
                      type: 'password',
                      value: password,
                      onChange: this.handleChange
                    }}
                  />
                  <div align="right">
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({ forget: true });
                      }}
                      style={{
                        color: 'blue',
                        background: 'none !important',
                        border: 'none',
                        padding: '0 !important',
                        font: 'inherit',
                        cursor: 'pointer'
                      }}
                    >
                      <small>Esqueceu sua senha?</small>
                    </button>
                  </div>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="primary" simple size="lg" block type="submit">
                    Entrar
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
