import React, { useEffect, useState, useReducer } from 'react';
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Switch,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';

import Edit from '@material-ui/icons/Edit';

import { searchSplitter, getAddress, updateSplitter } from '../../requests/requests';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';

export default function Splitter({ splitterId }) {
  const [loading, setLoading] = useState(true);
  const [splitter, setSplitter] = useReducer((state, newState) => ({ ...state, ...newState }), {
    address: { district: '' }
  });

  useEffect(() => {
    searchSplitter(splitterId)
      .then(res => {
        const aux = res.data;
        delete aux.city;
        delete aux.state;
        delete aux.id;
        setSplitter(aux);
      })
      .then(() => setLoading(false))
      .catch(err => console.log(err.response));
  }, []);

  function fetchViaCep(cep) {
    getAddress(cep.replace('-', ''))
      .then(res => {
        setSplitter({
          address: {
            ...splitter.address,
            line1: res.data.logradouro,
            line2: res.data.complemento,
            district: res.data.bairro,
            cityCode: res.data.ibge,
            zipCode: cep
          }
        });
      })
      .catch(err => console.log(err.response));
  }

  function handleChange(evt) {
    const { name, value } = evt.target;

    if (name.indexOf('.') > -1) {
      const keys = name.split('.');
      setSplitter({
        [keys[0]]: {
          ...splitter[keys[0]],
          [keys[1]]: value
        }
      });
    } else {
      setSplitter({ [name]: value });
    }
  }

  function handleSwitch(evt) {
    const { checked } = evt.target;

    setSplitter({ anticipatedTransfer: checked });
  }

  function handleZipCode(evt) {
    const { value } = evt.target;
    if (value.length === 9) {
      fetchViaCep(value);
    } else setSplitter({ address: { ...splitter.address, zipCode: value } });
  }

  async function handleSubmit(evt) {
    evt.preventDefault();

    const copy = splitter;
    copy.address.zipCode = copy.address.zipCode.replace('-', '');
    await updateSplitter(splitterId, copy);
  }

  if (loading) return <div />;

  return (
    <form
      onSubmit={evt => {
        handleSubmit(evt);
      }}
    >
      <Grid container justify="center">
        <Grid item md={10}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Edit />
              </CardIcon>
              <h4>Perfil</h4>
            </CardHeader>
            <CardContent>
              <Grid container justify="center" alignItems="flex-start" spacing={8}>
                <Grid item container spacing={16}>
                  <Grid item md={8}>
                    <small>Nome *</small>
                    <TextField
                      required
                      fullWidth
                      className="field"
                      name="name"
                      placeholder="Nome"
                      value={splitter.name}
                      onChange={evt => handleChange(evt)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <small>Dias para repasse *</small>
                    <TextField
                      required
                      fullWidth
                      name="transferDays"
                      type="number"
                      placeholder="Dias para Repasse"
                      value={splitter.transferDays}
                      onChange={evt => handleChange(evt)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <small>Antecipado</small>
                    <Switch
                      name="anticipatedTransfer"
                      checked={splitter.anticipatedTransfer}
                      onChange={evt => handleSwitch(evt)}
                    />
                  </Grid>
                </Grid>
                <Grid item container md={6}>
                  <Grid item md={12} style={{ marginTop: 10, marginBottom: 8 }}>
                    <h3>Endereço</h3>
                  </Grid>

                  <Grid item container spacing={16}>
                    <Grid item md={4}>
                      <small>CEP *</small>
                      <TextField
                        required
                        fullWidth
                        name="address.zipCode"
                        placeholder="CEP"
                        value={splitter.address.zipCode}
                        onChange={evt => handleZipCode(evt)}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <small>Bairro *</small>
                      <TextField
                        required
                        fullWidth
                        name="address.district"
                        placeholder="Bairro"
                        value={splitter.address.district}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={16}>
                    <Grid item md={12}>
                      <small>Logradouro *</small>
                      <TextField
                        required
                        fullWidth
                        name="address.line1"
                        placeholder="Logradouro"
                        value={splitter.address.line1}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={16}>
                    <Grid item md={12}>
                      <small>Complemento *</small>
                      <TextField
                        required
                        fullWidth
                        name="address.line2"
                        placeholder="Complemento"
                        value={splitter.address.line2}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={16}>
                    <Grid item md={4}>
                      <small>Número *</small>
                      <TextField
                        required
                        fullWidth
                        type="number"
                        name="address.streetNumber"
                        placeholder="Número"
                        value={splitter.address.streetNumber}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container md={6}>
                  <Grid item md={12} style={{ marginTop: 10, marginBottom: 8 }}>
                    <h3>Conta Bancária</h3>
                  </Grid>
                  <Grid item container md={12} spacing={8}>
                    <Grid item md={6}>
                      <small>Titular *</small>
                      <TextField
                        required
                        fullWidth
                        name="bankAccount.holderName"
                        placeholder="Titular"
                        value={splitter.bankAccount.holderName}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <small>CPF/CNPJ *</small>
                      <TextField
                        required
                        fullWidth
                        name="bankAccount.taxDocument"
                        placeholder="CPF/CNPJ"
                        value={splitter.bankAccount.taxDocument}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container md={12} spacing={8}>
                    <Grid item md={4}>
                      <small>Banco *</small>
                      <TextField
                        required
                        fullWidth
                        name="bankAccount.bankNumber"
                        placeholder="Banco"
                        value={splitter.bankAccount.bankNumber}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <small>Conta *</small>
                      <TextField
                        required
                        fullWidth
                        name="bankAccount.accountNumber"
                        placeholder="Conta"
                        value={splitter.bankAccount.accountNumber}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <small>Agência *</small>
                      <TextField
                        required
                        fullWidth
                        name="bankAccount.bankBranchNumber"
                        placeholder="Agência"
                        value={splitter.bankAccount.bankBranchNumber}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container md={12} spacing={8}>
                    <Grid item md={6}>
                      <small>Variância *</small>
                      <TextField
                        fullWidth
                        name="bankAccount.variation"
                        placeholder="Variância"
                        value={splitter.bankAccount.variation || ''}
                        onChange={evt => handleChange(evt)}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <small>Tipo *</small>
                      <Select
                        fullWidth
                        value={splitter.bankAccount.type.toLowerCase()}
                        onChange={evt => handleChange(evt)}
                        inputProps={{
                          name: 'bankAccount.type',
                          required: true
                        }}
                      >
                        <MenuItem value="corrente">Corrente</MenuItem>
                        <MenuItem value="poupanca">Poupanca</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container md={12} justify="flex-end">
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ backgroundColor: 'green', color: 'white', padding: '8px' }}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}
