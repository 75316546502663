import React, { useState, useEffect } from 'react';
import { Card, Grid, CardContent, IconButton } from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import CheckIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SweetAlert from 'react-bootstrap-sweetalert';
import SelectComponent from './Select';
import CreateIcon from '@material-ui/icons/Create';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';

import Notification from 'components/Notification/Notification';

import {
  updateDriver,
  updateDriverDocument,
  createSplitter,
  deactivateDriver,
  editDriverPercentage,
  editDriverVehicle,
  getEnterprisesList,
  editDriverBusiness
} from '../../requests/requests';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

function Driver(props) {
  const [driver, setDriver] = useState(
    JSON.parse(sessionStorage.getItem('driverDetail'))
  );
  const [driverDocuments, setDriverDocuments] = useState(
    JSON.parse(sessionStorage.getItem('driverDetail')).documents
  );
  const [alert, setAlert] = useState();
  const [blockAlert, setBlockAlert] = useState();
  const [moneyAlert, setMoneyAlert] = useState();
  const [vehicleTypeAlert, setVehicleTypeAlert] = useState();
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [color, setColor] = useState('');
  const [business, setBusiness] = useState('');
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [enterprises, setEnterprises] = useState([
    { id: 0, name: 'Sem empresa' }
  ]);
  const [id, setId] = useState('');

  const { classes } = props;

  /** EFFECTS */
  useEffect(() => {
    if (!!driver) {
      const businessName = '';
      console.log('driver', driver);
      // const businessId = driver.business_id;
      // const enterprise = enterprises.filter( enterprise => (enterprise.id) === String(businessId));
      setBusiness(businessName);
      setId(driver.user.id);
    }
  }, [driver, enterprises]);

  useEffect(() => {
    getEnterprisesList()
      .then(res => {
        let newEnterprises = enterprises.concat(res.data.businesses);
        setEnterprises(newEnterprises);
      })
      .catch(err => {
        console.log(err);
      });
  }, [getEnterprisesList]);

  /** FUNCTIONS */
  function hideAlert() {
    setAlert(null);
  }

  function hideBlockAlert() {
    setBlockAlert(null);
  }

  function hideMoneyAlert() {
    setMoneyAlert(null);
  }

  function hideVehicleTypeAlert() {
    setVehicleTypeAlert(null);
  }

  function showAlert(document, approved) {
    setAlert(
      <SweetAlert
        warning
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title={approved ? 'Aprovar documento' : 'Reprovar documento'}
        onConfirm={() => approveDocument(document, approved)}
        onCancel={() => hideAlert()}
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        {approved ? '' : <SelectComponent />}
        Você tem certeza que quer{' '}
        {approved ? 'aprovar o documento' : 'reprovar o documento'}?
      </SweetAlert>
    );
  }

  function showBlockAlert(id, block) {
    setBlockAlert(
      <SweetAlert
        input
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title={block ? 'Ativar o colaborador' : 'Suspender o colaborador'}
        onConfirm={message => blockDriver(id, block, message)}
        onCancel={() => hideBlockAlert()}
        showCancel
        confirmBtnText="Confirmar"
        cancelBtnText="Cancelar"
      >
        Qual o motivo para{' '}
        {block ? 'ativar o colaborador' : 'suspender o colaborador'}?
      </SweetAlert>
    );
  }

  function showMoneyAlert(id) {
    setMoneyAlert(
      <SweetAlert
        input
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title={'Porcentagem do colaborador'}
        onConfirm={message => driverPercentage(id, message)}
        onCancel={() => hideMoneyAlert()}
        showCancel
        confirmBtnText="Alterar"
        cancelBtnText="Cancelar"
      >
        Qual a nova porcentagem do motorista? Lembrando que pode ser um valor
        entre 18% e 25%.
      </SweetAlert>
    );
  }

  function showVehicleTypeAlert(id, type) {
    setVehicleTypeAlert(
      <SweetAlert
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title={'Tipo de veículo'}
        onConfirm={message => driverVehicle(id, type)}
        onCancel={() => hideVehicleTypeAlert()}
        showCancel
        confirmBtnText="Alterar"
        cancelBtnText="Cancelar"
      >
        Tem certeza que quer mudar o tipo de veículo desse motorista para{' '}
        {type === 1 ? 'moto' : 'carro'} ?
      </SweetAlert>
    );
  }

  async function approveDocument(document, approved) {
    hideAlert();
    const res = await updateDriverDocument(driver.user.id, document, approved);
    if (res.data) {
      setDriverDocuments(res.data);
    }
  }

  async function blockDriver(id, blocked, message) {
    hideBlockAlert();
    const res = await deactivateDriver(id, message, blocked);

    if (res.status === 200) {
      setNotification(true);
      setMessage(
        `Colaborador ${blocked ? 'ativado' : 'suspendido'} com sucesso!`
      );
      setColor('success');
      setDriver(res.data);
      sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
    } else {
      setNotification(true);
      setMessage(`Ops, parece que algo deu errado. Tente novamente.`);
      setColor('danger');
    }
  }

  async function driverPercentage(id, money) {
    hideMoneyAlert();

    if (typeof money === 'string') {
      money = money.replace(/\D/, '');
    }

    let p = money / 100;

    if (p <= 0.25 && p >= 0.18) {
      editDriverPercentage(id, p)
        .then(res => {
          if (res.status === 200) {
            setNotification(true);
            setMessage(`Porcentagem alterada para ${money}%.`);
            setColor('success');
            setDriver(res.data);
            sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
          }
        })
        .catch(error => {
          setNotification(true);
          setMessage(`Ops, parece que algo deu errado. Tente novamente.`);
          setColor('danger');
        });
    } else {
      setNotification(true);
      setMessage(
        `O valor da porcentagem deve ser entre 18 e 25%. Tente novamente.`
      );
      setColor('danger');
    }
  }

  async function driverVehicle(id, type) {
    hideVehicleTypeAlert();

    if (type === 0) {
      type = 1;
    } else {
      type = 0;
    }

    editDriverVehicle(id, type)
      .then(res => {
        if (res.status === 200) {
          setNotification(true);
          setMessage(`Veículo alterado com sucesso.`);
          setColor('success');
          setDriver(res.data);
          sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
        }
      })
      .catch(error => {
        setNotification(true);
        setMessage(`Ops, parece que algo deu errado. Tente novamente.`);
        setColor('danger');
      });
  }

  async function handleChangeBusiness() {
    if (selectedBusiness === null) {
      editDriverBusiness(id)
        .then(res => {
          if (res.status === 200) {
            setNotification(true);
            setMessage(`Alteração realizada com sucesso.`);
            setColor('success');
            setDriver(res.data);
            setOpenMenu(false);
            sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
          }
        })
        .catch(error => {
          setNotification(true);
          setMessage(`Ops, parece que algo deu errado. Tente novamente.`);
          setColor('danger');
        });
    } else {
      editDriverBusiness(id, selectedBusiness)
        .then(res => {
          if (res.status === 200) {
            setNotification(true);
            setMessage(`Alteração realizada com sucesso.`);
            setColor('success');
            setDriver(res.data);
            setOpenMenu(false);
            sessionStorage.setItem('driverDetail', JSON.stringify(res.data));
          }
        })
        .catch(error => {
          setNotification(true);
          setMessage(`Ops, parece que algo deu errado. Tente novamente.`);
          setColor('danger');
        });
    }
  }

  if (!driver) {
    // window.location.href = '/driver-history';
    return <div />;
  }
  return (
    <div>
      {alert}
      {blockAlert}
      {moneyAlert}
      {vehicleTypeAlert}
      {notification ? (
        <Notification
          message={message}
          color={color}
          setNotification={setNotification}
        />
      ) : (
        ''
      )}
      <Grid container spacing={8}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <h3>{driver.user.name}</h3>
              <h5>{driver.user.email}</h5>
              {driver.disabled_at !== null ? (
                <>
                  <p>
                    <b>
                      {driver.disabled_at !== null ? 'Motorista suspenso' : ''}
                    </b>
                    <br />
                    {driver.disabled_note !== null
                      ? `Motivo:  ${driver.disabled_note}`
                      : ''}
                    <br />
                    {driver.disabled_at !== null
                      ? `Data:  ${driver.disabled_at}`
                      : ''}
                    <br />
                  </p>
                  <p>
                    <b>
                      {driver.approved_at
                        ? `Aprovado em: ${driver.approved_at}`
                        : ''}
                      {driver.reproved_at
                        ? `Reprovado em: ${driver.reproved_at}`
                        : ''}
                      <br />
                    </b>
                  </p>
                  <p>
                    {driver.user.phone}
                    <br />
                    {driver.user.cpf}
                    <br />
                    {driver.user.signup_date}
                    <br />
                  </p>
                  <p>
                    <b>Endereço</b>
                  </p>
                  <p>
                    {`${
                      driver.user.address.city.nome
                    }, ${driver.user.address.city.state.nome.toUpperCase()}`}
                    <br />
                    {`${driver.user.address.line1}, ${driver.user.address.number}`}
                    <br />
                    {driver.user.address.line2}
                    {`CEP: ${driver.user.address.zipcode}, ${driver.user.address.district}`}
                    <br />
                  </p>
                  <p>
                    <b>Veículo</b>
                  </p>
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline'
                    }}
                  >
                    <p>
                      Placa:{' '}
                      {`${driver.vehicle.plate.substring(
                        0,
                        3
                      )}-${driver.vehicle.plate.substring(3)}`}
                      Descrição: {driver.vehicle.description}
                    </p>
                    <p>Descrição: {driver.vehicle.description}</p>
                    <p>Tipo: {driver.vehicle.type.name}</p>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    <b>
                      {driver.approved_at
                        ? `Aprovado em: ${driver.approved_at}`
                        : ''}
                      {driver.reproved_at
                        ? `Reprovado em: ${driver.reproved_at}`
                        : ''}
                      <br />
                    </b>
                  </p>
                  <p>
                    Telefone: {driver.user.phone}
                    <br />
                    CPF: {driver.user.cpf}
                    <br />
                    {driver.user.signup_date}
                    <br />
                  </p>
                  <p>
                    <b>Endereço</b>
                  </p>
                  <p>
                    {`${
                      driver.user.address.city.nome
                    }, ${driver.user.address.city.state.nome.toUpperCase()}`}
                    <br />
                    {`${driver.user.address.line1}, ${driver.user.address.number}`}
                    <br />
                    {driver.user.address.line2}
                    {`CEP: ${driver.user.address.zipcode}, ${driver.user.address.district}`}
                    <br />
                  </p>
                  <p>
                    <b>Veículo</b>
                  </p>
                  <div
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline'
                    }}
                  >
                    <p>
                      Placa:{' '}
                      {`${driver.vehicle.plate.substring(
                        0,
                        3
                      )}-${driver.vehicle.plate.substring(3)}`}
                    </p>
                    <p>Descrição: {driver.vehicle.description}</p>
                    <p>
                      Tipo: {driver.vehicle.type.name}
                      <IconButton
                        onClick={() =>
                          showVehicleTypeAlert(
                            driver.user.id,
                            driver.vehicle.type.id
                          )
                        }
                      >
                        <CreateIcon />
                      </IconButton>
                    </p>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
          <div style={{ marginTop: 10 }} />
          <Card>
            <CardContent>
              {/* <Grid container alignItems="stretch" justify="space-between" direction="row" > */}
              <Grid
                container
                alignItems="stretch"
                justify="center"
                direction="column"
              >
                <h5 style={{ color: '#777', fontSize: 14 }}>
                  <b>Porcentagem do motorista: {driver.percentage * 100}%</b>
                </h5>
                <br></br>
                <Button
                  color="primary"
                  onClick={evt => {
                    evt.preventDefault();
                    showMoneyAlert(driver.user.id, true);
                  }}
                  style={{
                    padding: '8px',
                    display:
                      driver.approved_at ||
                      (!driver.approved_at && !driver.reproved_at)
                        ? 'block'
                        : 'none'
                  }}
                >
                  Alterar porcentagem
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item md={6} xs={12} direction="column">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Documentos do parceiro
              </ListSubheader>
            }
            style={{
              width: '100%',
              maxWidth: 520,
              backgroundColor: '#FFFFFF'
            }}
          >
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.selfie ? (
                    <a
                      href={driverDocuments.selfie}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText
                        primary="Selfie"
                        primaryTypographyProps={{ variant: `body2` }}
                      />
                    </a>
                  ) : (
                    <ListItemText
                      primary="Selfie"
                      primaryTypographyProps={{ variant: `body2` }}
                      secondary="Faltando"
                    />
                  )}
                </Grid>
                {driverDocuments.selfie_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : driverDocuments.selfie ? (
                  <React.Fragment>
                    <Grid item md={2}>
                      <IconButton onClick={() => showAlert('selfie', true)}>
                        <CheckIcon />
                      </IconButton>
                    </Grid>
                    <Grid item md={2}>
                      <IconButton onClick={() => showAlert('selfie', false)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Grid>
            </ListItem>
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.cnh ? (
                    <a
                      href={driverDocuments.cnh}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText
                        primary="CNH"
                        primaryTypographyProps={{ variant: `body2` }}
                      />
                    </a>
                  ) : (
                    <ListItemText
                      primary="CNH"
                      primaryTypographyProps={{ variant: `body2` }}
                      secondary="Faltando"
                    />
                  )}
                </Grid>
                {driverDocuments.cnh_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : driverDocuments.cnh ? (
                  <React.Fragment>
                    <Grid item md={2}>
                      <IconButton onClick={() => showAlert('cnh', true)}>
                        <CheckIcon />
                      </IconButton>
                    </Grid>
                    <Grid item md={2}>
                      <IconButton onClick={() => showAlert('cnh', false)}>
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Grid>
            </ListItem>
            <ListItem divider>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.vehicle_document ? (
                    <a
                      href={driverDocuments.vehicle_document}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText
                        primary="CRLV"
                        primaryTypographyProps={{ variant: `body2` }}
                      />
                    </a>
                  ) : (
                    <ListItemText
                      primary="CRLV"
                      primaryTypographyProps={{ variant: `body2` }}
                      secondary="Faltando"
                    />
                  )}
                </Grid>
                {driverDocuments.vehicle_document_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : driverDocuments.vehicle_document ? (
                  <React.Fragment>
                    <Grid item md={2}>
                      <IconButton
                        onClick={() => showAlert('vehicle_document', true)}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Grid>
                    <Grid item md={2}>
                      <IconButton
                        onClick={() => showAlert('vehicle_document', false)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container alignItems="center">
                <Grid item md={8}>
                  {driverDocuments.criminal_records ? (
                    <a
                      href={driverDocuments.criminal_records}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListItemText
                        primary="Certidão de Bons Antecedentes"
                        primaryTypographyProps={{ variant: `body2` }}
                      />
                    </a>
                  ) : (
                    <ListItemText
                      primary="Certidão de Bons Antecedentes"
                      primaryTypographyProps={{ variant: `body2` }}
                      secondary="Faltando"
                    />
                  )}
                </Grid>
                {driverDocuments.criminal_records_locked ? (
                  <Grid item md={4}>
                    <p>Documento já aprovado!</p>
                  </Grid>
                ) : driverDocuments.criminal_records ? (
                  <React.Fragment>
                    <Grid item md={2}>
                      <IconButton
                        onClick={() => showAlert('criminal_records', true)}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Grid>
                    <Grid item md={2}>
                      <IconButton
                        onClick={() => showAlert('criminal_records', false)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </Grid>
            </ListItem>
          </List>
          <div style={{ marginTop: 10 }} />
          <Card>
            <CardContent>
              {/* <Grid container alignItems="stretch" justify="space-between" direction="row" > */}
              {openMenu ? (
                <Grid
                  container
                  alignItems="stretch"
                  justify="center"
                  direction="column"
                >
                  <h5 style={{ color: '#777', fontSize: 14 }}>
                    <b>Empresa atual: </b>
                    <span>
                      {driver.business ? driver.business.name : 'Sem empresa'}
                    </span>
                  </h5>
                  <br></br>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="business">
                      Selecione a empresa:
                    </InputLabel>
                    <Select
                      native
                      value={selectedBusiness}
                      onChange={evt => setSelectedBusiness(evt.target.value)}
                      name="business"
                    >
                      <option aria-label="None" value={null} />
                      {enterprises.map(business => (
                        <option key={business.id} value={business.id}>
                          {business.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <br></br>
                  <Button
                    color="primary"
                    onClick={handleChangeBusiness}
                    style={{
                      padding: '8px',
                      display:
                        driver.approved_at ||
                        (!driver.approved_at && !driver.reproved_at)
                          ? 'block'
                          : 'none'
                    }}
                  >
                    Salvar alteração
                  </Button>
                </Grid>
              ) : (
                <Grid
                  container
                  alignItems="stretch"
                  justify="center"
                  direction="column"
                >
                  <h5 style={{ color: '#777', fontSize: 14 }}>
                    <b>Empresa atual: </b>
                    <span>
                      {' '}
                      {driver.business ? driver.business.name : 'Sem empresa'}
                    </span>
                  </h5>
                  <br></br>
                  <Button
                    color="primary"
                    onClick={evt => {
                      setOpenMenu(true);
                    }}
                    style={{
                      padding: '8px',
                      display:
                        driver.approved_at ||
                        (!driver.approved_at && !driver.reproved_at)
                          ? 'block'
                          : 'none'
                    }}
                  >
                    Alterar empresa
                  </Button>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>

        {driverDocuments.selfie &&
        driverDocuments.cnh &&
        driverDocuments.vehicle_document &&
        driverDocuments.criminal_records ? (
          <Grid container item md={12} alignItems="center" justify="center">
            <Button
              color="danger"
              onClick={evt => {
                evt.preventDefault();
                updateDriver(driver.user.id, false).then(res => {
                  if (res.data) {
                    setDriver(res.data);
                    sessionStorage.setItem(
                      'driverDetail',
                      JSON.stringify(res.data)
                    );
                  }
                });
              }}
              style={{
                padding: '8px',
                display:
                  driver.approved_at ||
                  (!driver.approved_at && !driver.reproved_at)
                    ? 'block'
                    : 'none'
              }}
            >
              Reprovar
            </Button>
            <div style={{ width: '10px' }} />
            {driver.disabled_at === null ? (
              <Button
                color="warning"
                onClick={evt => {
                  evt.preventDefault();
                  showBlockAlert(driver.user.id, false);
                }}
                style={{
                  padding: '8px',
                  display:
                    driver.approved_at ||
                    (!driver.approved_at && !driver.reproved_at)
                      ? 'block'
                      : 'none'
                }}
              >
                Suspender
              </Button>
            ) : (
              <Button
                color="info"
                onClick={evt => {
                  evt.preventDefault();
                  showBlockAlert(driver.user.id, true);
                }}
                style={{
                  padding: '8px',
                  display:
                    driver.approved_at ||
                    (!driver.approved_at && !driver.reproved_at)
                      ? 'block'
                      : 'none'
                }}
              >
                Ativar
              </Button>
            )}
            <div style={{ width: '10px' }} />
            <Button
              color="success"
              onClick={evt => {
                evt.preventDefault();
                updateDriver(driver.user.id, true).then(res => {
                  if (res.data) {
                    setDriver(res.data);
                    sessionStorage.setItem(
                      'driverDetail',
                      JSON.stringify(res.data)
                    );
                  }
                });
              }}
              style={{
                padding: '8px',
                display:
                  driver.reproved_at ||
                  (!driver.approved_at && !driver.reproved_at)
                    ? 'block'
                    : 'none'
              }}
            >
              Aprovar
            </Button>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </div>
  );
}

export default withStyles(extendedFormsStyle)(Driver);
