import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

PhoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

function CPFMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

function CPNJMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
    />
  );
}

CPNJMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
};

// eslint-disable-next-line import/prefer-default-export
export { PhoneMaskCustom, CPFMaskCustom, CPNJMaskCustom };
