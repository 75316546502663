import React, { useState, useEffect } from 'react';

import { getEnterprisesList } from '../../../requests/requests';

import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderItem from '../Income/HeaderItem';

import ReactTable from 'react-table';

import { Card, CircularProgress } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon'

// import AddBoxIcon from '@material-ui/icons/AddBox';
import Search from '@material-ui/icons/Search';

function EnterprisesTable() {
  const [enterprises, setEnterprises] = useState([]);
  const [loading, setLoading] = useState(true);

  /** ICONS */

  function DriversIcon(props){
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
      </SvgIcon>
    )
  }

  function BkoIcon(props){
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17 12C16.87 12 16.76 12.09 16.74 12.21L16.55 13.53C16.25 13.66 15.96 13.82 15.7 14L14.46 13.5C14.35 13.5 14.22 13.5 14.15 13.63L13.15 15.36C13.09 15.47 13.11 15.6 13.21 15.68L14.27 16.5C14.25 16.67 14.24 16.83 14.24 17C14.24 17.17 14.25 17.33 14.27 17.5L13.21 18.32C13.12 18.4 13.09 18.53 13.15 18.64L14.15 20.37C14.21 20.5 14.34 20.5 14.46 20.5L15.7 20C15.96 20.18 16.24 20.35 16.55 20.47L16.74 21.79C16.76 21.91 16.86 22 17 22H19C19.11 22 19.22 21.91 19.24 21.79L19.43 20.47C19.73 20.34 20 20.18 20.27 20L21.5 20.5C21.63 20.5 21.76 20.5 21.83 20.37L22.83 18.64C22.89 18.53 22.86 18.4 22.77 18.32L21.7 17.5C21.72 17.33 21.74 17.17 21.74 17C21.74 16.83 21.73 16.67 21.7 16.5L22.76 15.68C22.85 15.6 22.88 15.47 22.82 15.36L21.82 13.63C21.76 13.5 21.63 13.5 21.5 13.5L20.27 14C20 13.82 19.73 13.65 19.42 13.53L19.23 12.21C19.22 12.09 19.11 12 19 12H17M10 14C5.58 14 2 15.79 2 18V20H11.68A7 7 0 0 1 11 17A7 7 0 0 1 11.64 14.09C11.11 14.03 10.56 14 10 14M18 15.5C18.83 15.5 19.5 16.17 19.5 17C19.5 17.83 18.83 18.5 18 18.5C17.16 18.5 16.5 17.83 16.5 17C16.5 16.17 17.17 15.5 18 15.5Z" />
      </SvgIcon>
    )
}

  /** EFFECTS */
  useEffect(() => {
    getEnterprisesList()
      .then(res => {
        setEnterprises(res.data.businesses);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (enterprises.length > 0) {
      enterprises.map(e => {
        e.btn = (
          <div style={{ float: 'right' }}>
            <Button
              justIcon
              round
              simple
              color="info"
              onClick={evt => {
                enterpriseDetails(e.id);
              }}
            >
              <Search />
            </Button>
          </div>
        );
        e.bkoBtn = (
          <div style={{ float: 'right' }}>
            <Button
              justIcon
              round
              simple
              color="info"
              onClick={evt => {
                redirectBKO(e.id);
              }}
            >
              <BkoIcon />
            </Button>
          </div>
        );
        e.driverBtn = (
          <div style={{ float: 'right' }}>
            <Button
              justIcon
              round
              simple
              color="info"
              onClick={evt => {
                redirectDrivers(e.id);
              }}
            >
              <DriversIcon />
            </Button>
          </div>
        );
      });
      setLoading(false);
      console.log(`enterprises`,enterprises)
    }
  }, [enterprises]);


  /** FUNCTIONS */
  function enterpriseDetails(id) {
    const details = enterprises.filter(enterprise => enterprise.id === id);

    sessionStorage.setItem('enterpriseDetail', JSON.stringify(details));
    window.location.href = '/enterprise-detail';
  }

  function redirectBKO(id){
    const details = enterprises.filter(enterprise => enterprise.id === id);

    sessionStorage.setItem('BkoDetail', JSON.stringify(details));
    window.location.href = '/bko-enterprise';
  }

  function redirectDrivers(id){
    const details = enterprises.filter(enterprise => enterprise.id === id);
    
    sessionStorage.setItem('driversDetail', JSON.stringify(details));
    window.location.href = '/drivers-enterprise';
  }

  function goToCreate() {
    window.location.href = '/create-business';
  }

  return (
    <GridContainer
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem>
              <HeaderItem
                title="Total de empresas"
                value={enterprises ? enterprises.length : ''}
              />
            </GridItem>
            <GridItem>
              <Button
                // justIcon
                color="primary"
                onClick={() => goToCreate()}
              >
                {/* <AddBoxIcon /> */}
                Cadastrar empresa
              </Button>
            </GridItem>
          </GridContainer>
          <div style={{ margin: 15 }} />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <ReactTable
                // ref={refReactTable => {
                //   this.refReactTable = refReactTable;
                // }}
                className="-striped -highlight"
                data={enterprises}
                sortable={false}
                defaultPageSize={10}
                pages={Math.ceil(enterprises.length / 10)}
                loading={loading}
                previousText="Anterior"
                nextText="Próximo"
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
                loadingText="Carregando..."
                noDataText="Nenhum dado encontrado"
                manual
                // onFetchData={this.kkthis.onFilteredChange}
                columns={[
                  {
                    id: 'id',
                    Header: 'ID',
                    accessor: row => row.id,
                    width: 100
                  },
                  {
                    id: 'name',
                    Header: 'Nome',
                    accessor: row => row.name
                    // maxWidth: 200,
                    // filterable: true
                  },
                  {
                    id: 'document',
                    Header: 'Documento',
                    accessor: row => row.document
                    // width: 150
                  },
                  {
                    id: 'owns_fleet',
                    Header: 'Frota própria',
                    accessor: row => (row.owns_fleet === 0 ? 'Não' : 'Sim'),
                    width: 150
                  },
                  {
                    id: 'active',
                    Header: 'Liberada',
                    accessor: row => (row.active === 0 ? 'Não' : 'Sim'),
                    width: 150
                  },
                  {
                    id: 'has_invoice',
                    Header: 'Faturada',
                    accessor: row => (row.has_invoice === 0 ? 'Não' : 'Sim'),
                    width: 150
                  },
                  {
                    id: 'btn',
                    Header: '',
                    accessor: row => row.btn,
                    sortable: false,
                    filterable: false,
                    width: 50
                  },
                  {
                    id: 'bkoBtn',
                    Header: '',
                    accessor: row => row.bkoBtn,
                    sortable: false,
                    filterable: false,
                    width: 50
                  },
                  {
                    id: 'driverBtn',
                    Header: '',
                    accessor: row => row.driverBtn,
                    sortable: false,
                    filterable: false,
                    width: 50
                  }
                ]}
                showPageJump={false}
              />
            </GridItem>
          </GridContainer>
        </>
      )}
    </GridContainer>
  );
}

export default EnterprisesTable;