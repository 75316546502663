import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

// material ui icons
import MailOutline from '@material-ui/icons/MailOutline';
import Edit from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';

// core components
import Snackbar from '../../components/Snackbar/Snackbar';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CardBody from '../../components/Card/CardBody';

import validationFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import { updatePassword } from '../../requests/requests';

class UpdatePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      newPassword: '',
      confirmPassword: '',
      txt: '',
      snack: false,
      snackColor: 'danger'
    };

    this.openSnack = this.openSnack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    this.setState({
      [evt.target.id]: evt.target.value
    });
  }

  handleSubmit(evt) {
    const { password, newPassword } = this.state;
    evt.preventDefault();
    updatePassword(password, newPassword)
      .then(() =>
        this.setState({
          txt: 'Senha atualizada.',
          snackColor: 'success',
          password: '',
          newPassword: '',
          confirmPassword: ''
        })
      )
      .catch(() => this.setState({ txt: 'Senha negada.', snackColor: 'danger' }))
      .finally(() => this.openSnack());
  }

  openSnack() {
    this.setState({ snack: true });
    setTimeout(() => this.setState({ snack: false }), 3000);
  }

  render() {
    const { classes } = this.props;
    const { password, newPassword, confirmPassword, snack, snackColor, txt } = this.state;

    const passwordError = newPassword !== confirmPassword;

    return (
      <GridContainer justify="center">
        <GridItem align="center" xs={12} sm={12} md={12}>
          <Card style={{ width: '50%' }}>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LockIcon />
              </CardIcon>
              <h4 align="left" className={classes.cardIconTitle}>
                Alterar Senha
              </h4>
            </CardHeader>
            <CardBody>
              <form autoComplete="off" onSubmit={this.handleSubmit}>
                {/* <input
                  id={'username'}
                  style={{ display: 'none' }}
                  type={'text'}
                  name={'fakeUser'}
                />
                <input
                  id={'password'}
                  style={{ display: 'none' }}
                  type={'password'}
                  name={'fakePassword'}
                /> */}
                <input style={{ display: 'none' }} />
                <input type="password" style={{ display: 'none' }} />
                <CustomInput
                  labelText="Senha Atual*"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: password,
                    onChange: this.handleChange,
                    type: 'password',
                    inputProps: {
                      autoComplete: 'false'
                    }
                  }}
                />
                <CustomInput
                  labelText="Nova Senha*"
                  id="newPassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: newPassword,
                    onChange: this.handleChange,
                    type: 'password'
                  }}
                />
                <CustomInput
                  error={passwordError}
                  labelText="Confirmar Senha *"
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: confirmPassword,
                    onChange: this.handleChange,
                    type: 'password'
                  }}
                />
                <div align="right" className={classes.formCategory}>
                  <small>*</small>
                  Obrigatório
                </div>
                <Button
                  className={classes.registerButton}
                  disabled={passwordError}
                  color="rose"
                  type="submit"
                >
                  Alterar
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar place="tc" color={snackColor} icon={Edit} message={txt} open={snack} close />
      </GridContainer>
    );
  }
}

export default withStyles(validationFormsStyle)(UpdatePassword);
