import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,  
  MenuItem
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import { getDriversQueue , getBusinessList} from '../../../requests/requests';
import { useDebounce } from '../../../utils/hooks';
import { formatMoney, formatTimeDate } from '../../../utils/functions';
import './index.css';
import moment from "moment";

function DriversQueue(props) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    vehicle: 0,
    user: null
  });
  const [drivers, setDrivers] = useState({ drivers: []});
  
  const debouncedVehicle = useDebounce(filterState.vehicle, 1000);
  const debouncedUserId = useDebounce(filterState.user, 1000);
  const [businessList, setBusinessList] = useState([]);
  
  useEffect(() => {
      getBusinessList().then(res => {
        setBusinessList(res.data.businesses);
      });
    }, []);

  useEffect(() => {

    if(debouncedUserId || debouncedVehicle){
      
      setTableLoading(true);
      getDriversQueue(debouncedUserId, debouncedVehicle)
        .then(res => {
          setDrivers(res.data);
          setLoading(false);
          setTableLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setTableLoading(false);
        });
    }
  }, [    
    debouncedUserId,
    debouncedVehicle
  ]);

  return (
    <Grid      
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {false ? (
        <CircularProgress color="secondary" />
      ) : (
        <>          
          <Grid item md={12} container>
            <Grid item md={12} container>
              <Grid item md={6} lg={4} sm={12}>
              <FormControl fullWidth> 
                <InputLabel
                  htmlFor="user"
                >
                Escolha um cliente:
                </InputLabel>
                <Select
                native
                value={filterState.user}
                id="requester-select"
                name="user"
                fullWidth
                onChange={e =>
                  setFilterState({
                    ...filterState,
                    user: e.target.value
                  })
                }
              >
                <option aria-label="None" value="" />
                {businessList.map(business => (
                  <option value={business.id}>
                    {business.name}
                  </option>
                ))}
              </Select> 
              </FormControl>
            </Grid>
            <Grid item md={0} lg={2} sm={12}></Grid>
            <Grid item md={6} lg={4} sm={12}>
                <FormControl fullWidth> 
                <InputLabel
                  htmlFor="user"
                >
                Tipo de veículo:
                </InputLabel>
                <Select
                native
                id="requester-select"
                fullWidth
                name="vehicle"
                value={filterState.vehicle}
                onChange={e =>
                  setFilterState({
                    ...filterState,
                    vehicle: e.target.value
                  })
                }>
                <option value={0}>Moto</option>
                <option value={1}>Carro</option>
              </Select> 
              </FormControl>
          </Grid>
        </Grid>                                        
            <ReactTable
              style={{ width: '100%', textAlign: 'center' }}
              className="-striped -highlight"
              data={drivers.drivers}
              sortable={false}
              resizable={false}
              showPageSizeOptions={false}
              defaultPageSize={drivers.drivers.length}
              pageSize={drivers.drivers.length}
              pages={1}
              loading={tableLoading}
              previousText="Anterior"
              nextText="Próximo"
              noDataText="Nenhum dado encontrado"
              manual              
              columns={[ 
                {
                  id: 'drivers_id',
                  Header: 'Posição',
                  Cell: (row) => {
                    return <div>{row.index+1}</div>;
                  },
                  filterable: false
                },
                {
                  id: 'name',
                  Header: 'Nome',
                  accessor: row => row.name ? row.name : null,
                  filterable: false
                },
                {
                  id: 'plate',
                  Header: 'Placa',
                  accessor: row => row.plate ? row.plate : null,
                  filterable: false
                },
                {
                  id: 'distance',
                  Header: 'Veículo',
                  accessor: row => row.vehicle_description ? row.vehicle_description : null,
                },        
                {
                  id:'date',
                  Header: 'Data de chegada',
                  accessor: row => row.queued_at,

                },       
              ]}
              showPageJump={false}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default DriversQueue;
