import { identify } from '../requests/requests';

async function getPermissions() {
  const permissions = { permissions: [], flag: '' };

  try {
    const response = await identify();
    const user = response.data;

    // console.log('user :', user);

    if (user) {
      if (user.is_admin === 1) permissions.flag = 'IS_ADMIN';
      if (user.type === 2) permissions.permissions.push('IS_SUPPORT');
      else permissions.permissions.push('NOT_SUPPORT');
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      permissions.permissions.push('NOT_LOGGED');
    }
  } finally {
    return permissions;
  }
}

/**
 *
 *
 * @param {String} dateTime
 * @returns
 */
function formatTimeDate(dateTime, timeSeparator = ':', dateSeparator = '/') {
  const arrDateTime = dateTime.split(' ');
  let date = arrDateTime[0];
  let time = arrDateTime[1];
  date = date.split('-');
  date = date[2] + dateSeparator + date[1] + dateSeparator + date[0];
  time = time.split(':');
  time = time[0] + timeSeparator + time[1] + timeSeparator + time[2];

  return [date, time];
}

function verifyCPF(strCPF) {
  let Soma;
  let Resto;
  Soma = 0;
  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i += 1)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i += 1)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  return Resto === parseInt(strCPF.substring(10, 11), 10);
}

/**
 *
 *
 * @param {number} numb
 * @param {number} [decimalQtd=2]
 * @param {string} [decimal=',']
 * @param {string} [thousands='.']
 * @returns
 */
function formatMoney(numb, decimalQtd = 2, decimal = ',', thousands = '.') {
  try {
    let amount = numb;
    let decimalCount = Math.abs(decimalQtd);
    decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
      10
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

function moneyField(numb) {
  
    let amount = numb;
    let cents = numb;
    let res = cents.toString().replace(/[^0-9]/g, '');
    return (parseFloat(res) / 100).toFixed(2);
}

export { getPermissions, formatTimeDate, formatMoney, moneyField, verifyCPF };
