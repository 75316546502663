import React, { useState, useEffect, useCallback} from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,
  Select,
  MenuItem
} from '@material-ui/core';
import { getZones, createZone} from '../../../requests/requests';
import { formatTimeDate } from '../../../utils/functions';
import { moneyField, formatMoney } from '../../../helpers/functions';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderItem from '../Income/HeaderItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import Delete from '@material-ui/icons/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';

import './index.css';
import moment from "moment";

function Zones(props) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [zones, setZones] = useState({ zones: []});
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState();

  const [createZoneState, setCreateZoneState] = useState({
    note: "",
    size: 0,
    origin_latitude: null,
    origin_longitude: null
  });

  useEffect(() => {
    setTableLoading(true);
    getZones()
      .then(res => {
        setZones(res.data);
        setLoading(false);
        setTableLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setTableLoading(false);
      });
  }, []);

  const handleSubmit = useCallback(()=>{
    setLoading(true);
    setOpen(false);

    createZone(createZoneState)
      .then(res => {
        setLoading(false);
        if(res){
          window.location.reload();
        }else{
          window.alert(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        window.alert(JSON.stringify(error.response.data));
      });

  },[createZoneState]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <Grid
      container
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cadastrar Zona de Atuação</DialogTitle>
            <DialogContent>
              
            <Grid item md={12} container>
              <Grid item md={6} lg={12} sm={12}>
                <FormControl fullWidth>                   
                <CustomInput
                  id="note"
                  labelText="Nome (Ex.: Rio de Janeiro)"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'text',
                    id: 'note',
                    required: true,
                    value: createZoneState.note,
                    onChange: evt => setCreateZoneState({
                      ...createZoneState,
                      note: evt.target.value
                    })
                  }}
                />
                <CustomInput
                  id="size"
                  labelText="Raio de atuação em Km (Ex.: 30.00)"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'size',
                    required: true,
                    value: createZoneState.size,
                    onChange: evt => setCreateZoneState({
                      ...createZoneState,
                      size: moneyField(evt.target.value)
                    })
                  }}
                />
                <CustomInput
                  id="origin_latitude"
                  labelText="Latitude central (Ex:-22.915900)"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'origin_latitude',
                    required: true,
                    value: createZoneState.origin_latitude,
                    onChange: evt => setCreateZoneState({
                      ...createZoneState,
                      origin_latitude: evt.target.value
                    })
                  }}
                /> 
                <CustomInput
                  id="origin_longitude"
                  labelText="Longitude central (Ex.: -43.456093)"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                    type: 'decimal',
                    id: 'origin_longitude',
                    required: true,
                    value: createZoneState.origin_longitude,
                    onChange: evt => setCreateZoneState({
                      ...createZoneState,
                      origin_longitude: evt.target.value
                    })
                  }}
                /> 
                </FormControl>
              </Grid>
            </Grid>
              
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button onClick={() => handleSubmit()} color="primary">
                Cadastrar
              </Button>
            </DialogActions>
          </Dialog>
          <GridContainer
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <GridItem>
              <HeaderItem
                title="Zonas de Atuação"
              />
            </GridItem>
            <GridItem>
              <Button
                color="primary"
                onClick={() => handleClickOpen()}
              >
                Cadastrar Nova
            </Button>
          </GridItem> 
          </GridContainer>         
          <Grid item md={12} container>
            <ReactTable
              style={{ width: '100%', textAlign: 'left' }}
              className="-striped -highlight"
              data={zones.zones}
              sortable={false}
              showPageSizeOptions={false}
              defaultPageSize={zones.zones.length}
              pageSize={zones.zones.length}
              pages={1}
              loading={tableLoading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              // onFetchData={state => {
              //   const obj = {
              //     user: null,
              //     fare: null,
              //   };

              //   state.filtered.forEach(filter => {
              //     obj[filter.id] = filter.value;
              //   });

              //   setFilterState(obj);               
              // }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {                    
                    //redirectRide(rowInfo.original.id);
                  },
                };
              }}
              columns={[
                {
                  id: 'id',
                  Header: 'ID',
                  accessor: row => row.id,
                  filterable: false
                },
                {
                  id: 'note',
                  Header: 'Nome',
                  accessor: row => row.note,
                  filterable: false
                },
                {
                  id: 'size',
                  Header: 'Tamanho do Raio KM',
                  accessor: row => row.size,
                  filterable: false
                },
                {
                  id: 'origin_latitude',
                  Header: 'Latitude',
                  accessor: row => row.origin_latitude.toFixed(8)
                },
                {
                  id: 'origin_longitude',
                  Header: 'Longitude',
                  accessor: row => row.origin_longitude.toFixed(8)
                }               
              ]}
              showPageJump={false}
            />
          </Grid>
        </>
      )}
    </Grid>
    </>
  );
}

export default Zones;
