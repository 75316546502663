import React, { useState, useEffect, useCallback } from 'react';

//Libs
import ReactTable from 'react-table';
import { Card, CircularProgress } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from 'react-toastify';

// Requests
import { getBkoList, createBko } from '../../../requests/requests';

// Utils
import { verifyCPF } from 'helpers/functions';
import { useDebounce } from 'utils/hooks';

// Components
import {
  CPFMaskCustom,
  PhoneMaskCustom,
} from 'components/CustomMasks/Masks';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import HeaderItem from '../Income/HeaderItem';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';

function BKOTable(props) {
  const [enterprise] = useState(
    JSON.parse(sessionStorage.getItem('BkoDetail'))
  );
  const [loading, setLoading] = useState(true);
  const [bkoList, setBkoList] = useState([]);
  const [document, setDocument] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [id, setId] = useState('');
  const [filterState, setFilterState] = useState({
    name: '',
  });
  const nameFiltered = useDebounce(filterState.name, 1000);

  const [open, setOpen] = useState(false);

  const [data, setData] = useState([]);

  /** EFFECTS */
  useEffect(()=>{
    if (!!enterprise){
      setId(enterprise[0].id);
    }
  },[enterprise])

  useEffect(()=>{
    if (!!nameFiltered){
      getBkoList(enterprise[0].id, nameFiltered)
      .then(res => {
        setBkoList(res.data);
        setData(res.data);
        setLoading(false);
        sessionStorage.setItem('bkoList', JSON.stringify(res.data));
      })
      .catch(() => {
        setLoading(false);
      });
    } else {
      getBkoList(enterprise[0].id)
      .then(res => {
        setBkoList(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    }
  },[nameFiltered, enterprise])

  useEffect(()=>{
    if (!!bkoList){
      setData(bkoList)
    }
  },[bkoList])

  /** FUNCTIONS */
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(()=>{
    setLoading(true);
    createBko(name, email, phone, document, id, password)
    .then((res) => {
      toast.success('BKO cadastrado com sucesso!');
      setLoading(false);
      sessionStorage.setItem('bkoList', JSON.stringify(res.data));
    })
    .catch(() => {
      toast.error('Não foi possível concluir o cadastro. Verifique as informações.');
      setLoading(false);
    });
    setOpen(false);
  },[name, email, phone, document, id, password]);

  return (
    <GridContainer
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '50px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {open}
      {loading ? <CircularProgress color="secondary" /> : (
        <>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Cadastrar BKO</DialogTitle>
          <DialogContent>
            <CustomInput
              id="name"
              labelText="Nome *"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'text',
                id: 'name',
                required: true,
                value: name,
                onChange: evt => setName(evt.target.value)
              }}
            />
            <CustomInput
              id="cpf"
              labelText="CPF *"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'text',
                id: 'cpf',
                value: document,
                required: true,
                inputComponent: CPFMaskCustom,
                onChange: evt => setDocument(evt.target.value),
                error: document
                  ? !verifyCPF(document.replace(/[\.\-]/g, ''))
                  : false
              }}
            />
            <CustomInput
              id="email"
              labelText="Email *"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'email',
                id: 'email',
                required: true,
                value: email,
                onChange: evt => setEmail(evt.target.value)
              }}
            />
            <CustomInput
              id="phone"
              labelText="Telefone *"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'text',
                id: 'phone',
                required: true,
                value: phone,
                inputComponent: PhoneMaskCustom,
                onChange: evt => setPhone(evt.target.value)
              }}
            />
            <CustomInput
              id="password"
              labelText="Senha *"
              formControlProps={{fullWidth: true}}
              inputProps={{
                type: 'password',
                id: 'password',
                required: true,
                value: password,
                onChange: evt => setPassword(evt.target.value),
                error: password
                  ? (password.replace(/^.{8,35}$/g, ''))
                  : false
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleSubmit()} color="primary">
              Cadastrar
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <GridItem>
            <HeaderItem
              title="Total de BKOs"
              value={bkoList ? bkoList.length : ''}
            />
          </GridItem>
          <GridItem>
            <Button
              color="primary"
              onClick={() => handleClickOpen()}
            >
              Cadastrar BKO
            </Button>
          </GridItem>
        </GridContainer>
        <div style={{ margin: 15 }} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <ReactTable
              className="-striped -highlight"
              data={data}
              sortable={false}
              defaultPageSize={10}
              pages={Math.ceil(bkoList.length / 10)}
              loading={loading}
              previousText="Anterior"
              nextText="Próximo"
              pageText={'Página'}
              ofText={'de'}
              rowsText={'linhas'}
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => {
                const obj = {
                  name: '',
                };
  
                state.filtered.forEach(filter => {
                  obj[filter.id] = filter.value;
                });
  
                setFilterState(obj);
              }}
              onFilteredChange={state => setFilterState(state)}
              filter
              columns={[
                {
                  id: 'name',
                  Header: 'Nome',
                  accessor: row => row.name,
                  filterable: true,
                },
                {
                  id: 'email',
                  Header: 'Email',
                  accessor: row => row.email
                  // width: 150
                },
                {
                  id: 'phone',
                  Header: 'Telefone',
                  accessor: row => (row.phone),
                  width: 150
                }
              ]}
              showPageJump={false}
            />
          </GridItem>
        </GridContainer>
        </>
      )}
    </GridContainer>
  );
}

export default BKOTable;