import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import { getDriverRides } from '../../requests/requests';
import HeaderItem from './Income/HeaderItem';
import { formatMoney, formatTimeDate } from 'utils/functions';
import { useDebounce } from 'utils/hooks';
import './Income/index.css';
import moment from "moment";
import {CSVLink} from "react-csv";
import Typography from '@material-ui/core/Typography';

function DriverIncome() {
  const [driver, ] = useState(JSON.parse(sessionStorage.getItem('driverDetail')));
  const [driverName, setDriverName] = useState("");

  const [loading, setLoading] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const [count, setCount] = useState();
  const [income, setIncome] = useState();
  const [csvRes, setCsvRes] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvLink, setCsvLink] = useState();

  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(30);
  const [filterState, setFilterState] = useState({
    id: '',
    external_id: '',
    origin_addres: '',
    destination_addres: ''
  });
  const debouncedId = useDebounce(filterState.id, 1000);
  const debouncedExternalId = useDebounce(filterState.external_id, 1000);
  const debouncedOrigin = useDebounce(filterState.origin_addres, 1000);
  const debouncedDestination = useDebounce(filterState.destination_addres, 1000);
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

  const [allRides, setAllRides] = useState([]);

  const [buttonLabel, setButtonLabel] = useState('EXPORTAR');
  const [driverId, setDriverId] = useState();

  const headers = [
    {
      label: 'Código do frete',
      key: 'id',
    },
    {
      label: 'Código do pedido',
      key: 'control',
    },
    {
      label: 'Motorista',
      key: 'driver',
    },
    {
      label: 'Data/Hora',
      key: 'date',
    },
    {
      label: 'Distância',
      key: 'distance',
    },
    {
      label: 'Origem',
      key: 'from',
    },
    {
      label: 'Destino',
      key: 'to',
    },
    {
      label: 'Valor total',
      key: 'price_total',
    },    
    {
      label: 'Valor peso',
      key: 'price_volume',
    },
    {
      label: 'Valor declarado',
      key: 'product_price',
    },
    {
      label: 'Repasse Seguro',
      key: 'insurance_price',
    },
    {
      label: 'Repasse motorista',
      key: 'driver_price',
    },
    {
      label: 'Status',
      key: 'status',
    },
  ]

  /** EFFECTS */
  useEffect(()=>{
    if (driver !== undefined){
      setDriverId(driver.user.id);
      setDriverName(driver.user.name);
    }
  },[driver]);

  useEffect(()=>{
    if (driverId !== undefined){
      if (
        debouncedId ||
        debouncedExternalId ||
        debouncedOrigin !== "" ||
        debouncedDestination ||
        (dateStart !== '' && dateEnd !== '')
      ){
        setLoading(false);
        setTableLoading(true);
        
        getDriverRides(
          page * pageLength, 
          pageLength,
          debouncedId,
          debouncedExternalId,
          debouncedOrigin,
          debouncedDestination,
          '',
          dateStart ? moment(dateStart).format('YYYY-MM-DD') : null,
          dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : null,
          driverId,
          false
        ).then( res => {
          setAllRides(res.data.rides);
          setCount(res.data.count);
          setIncome(res.data.income);
          setTableLoading(false);
        }).catch(()=>{
          setTableLoading(false);
        });
      } else {
        console.log('else');
        setTableLoading(true);
        setLoading(false);
        getDriverRides(
          page * pageLength, 
          pageLength,
          null,
          null,
          '',
          '',
          '',
          null,
          null,
          driverId,
          false,
        ).then( res => {
          setAllRides(res.data.rides);
          setCount(res.data.count);
          setIncome(res.data.income);
          setTableLoading(false);
        }).catch(()=>{
          setTableLoading(false);
        });
      }
    }
  },[
    driverId,
    page,
    pageLength, 
    dateStart,
    dateEnd,
    debouncedId,
    debouncedExternalId,
    debouncedOrigin,
    debouncedDestination
  ])

  useEffect(() => {
    if (
      filterState !== null &&
      ((!dateStart && !dateEnd) || (dateStart && dateEnd))
    ) {
      let timeOutFunction = setTimeout(() => {
        setCsvData([]);
        setButtonLabel("EXPORTAR")
      }, 500);

      // console.log('dateStart',dateStart)
      // console.log('dateEnd',dateEnd)
      
      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    } 
  }, [filterState, dateStart, dateEnd]);

  useEffect(()=>{
    if (csvData.length > 0 ){
      setCsvLoading(false);
      setButtonLabel("EXPORTAR");
    }
  }, [csvData]);

  useEffect(()=>{
    if (csvRes.length > 0){
      let data = [];

      csvRes.map(r=>{
        data.push({
          "id": r.id,
          "control": r.external_id ? r.external_id : '',
          'driver': r.driver ? r.driver.name : '',
          'date': r.date,
          "distance": r.distance ? r.distance : '',
          'from': r.originAddress,
          "to": r.destinationAddress,
          'price_total': r.price ? formatMoney(r.price.total_price) : '',
          'price_volume': r.price ? formatMoney(r.price.volume_price) : '',
          'driver_price': r.price? formatMoney(r.price.driver_price) : '',
          'insurance_price': r.price ? formatMoney(r.price.insurance_price) : '',
          'product_price': r.insurance ? formatMoney(r.insurance.product_price) : '',
          'status': r.lastStatus ? r.lastStatus.name : ''
        });
      });
      setCsvData(data);
    }
  },[csvRes])

  /** FUNCTIONS */
  async function requestCSV(){
    setCsvLoading(true);
    setButtonLabel('PREPARANDO');
    if (
      filterState.id !== "" || 
      filterState.external_id !== "" ||
      filterState.origin_addres !== "" ||
      filterState.destination_addres !== "" ||
      (dateStart !== '' && dateEnd !== '')
    ){      
      try {
        const allRes = await getDriverRides(
          0, 
          30,
          filterState.id ? filterState.id : '',
          filterState.external_id ? filterState.external_id : '',
          filterState.origin_addres ? filterState.origin_addres : '',
          filterState.destination_addres ? filterState.destination_addres : '',
          '',
          dateStart ? moment(dateStart).format('YYYY-MM-DD') : null,
          dateEnd ? moment(dateEnd).format('YYYY-MM-DD') : null,
          driverId,
          true
        );
        setCsvRes(allRes.data.rides);
      } catch (err) {
        console.log('err',err)
      }
    } else {
      console.log('else csv')
      try {
        const allRes = await getDriverRides(
          0, 
          30,
          null,
          null,
          '',
          '',
          '',
          null,
          null,
          driverId,
          true,
        );
        console.log('allRes',allRes)
        setCsvRes(allRes.data.rides);
      } catch (err) {
        console.log('err',err)
      }
    }
  }

  function downloadCSV(){
    if(csvData !== undefined){
      if (csvLink !== undefined && csvLink !== null){        
        csvLink.link.click();
      }
    } else {      
      requestCSV();
    }
  }

  return (
    <Grid
      container
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
        <Grid item md={12}container justify="flex-start">
          <HeaderItem
            value={'Motorista: ' + driverName}
          />
        </Grid>
        <br/>
        <br/>
        <br/>
        <Grid item md={12} container alignItems="flex-start" spacing={0}>
          <Grid item xs={4} md={3} lg={2}>
            <TextField
              id="dateStart"
              label="Data Início"
              type="date"
              value={dateStart}
              onChange={e => {
                if (!dateEnd) {
                  setDateStart(e.target.value);
                  setDateEnd(e.target.value);
                } else setDateStart(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />               
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <TextField
              id="dateEnd"
              label="Data Fim"
              type="date"
              value={dateEnd}
              onChange={e => {
                if (!dateEnd) {
                  setDateStart(e.target.value);
                  setDateEnd(e.target.value);
                } else setDateEnd(e.target.value);
              }}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4} md={3} lg={2}>
            <Button
              simple
              color="primary"
              onClick={() => {
                setDateStart('');
                setDateEnd('');
              }}
            >
              {"Limpar"}
            </Button>
          </Grid>
        </Grid>
        <Grid item md={8}container justify="flex-start">
          <Typography variant='caption'>Os valores exportados serão iguais aos presentes na tabela no momento da exportação.</Typography>
        </Grid> 
        <Grid item md={4}container justify="flex-end">
          <div style={{textAlign:'left'}}>
            {
              csvData.length > 0 ? (
                <>
                <CSVLink
                  data={csvData}
                  headers={headers}
                  separator={";"}                
                  filename="driverIncome.csv"
                  className="hidden"
                  ref={(r) => setCsvLink(r)}
                  target="_blank"/>              
                  <Button                      
                    color="success"
                    size="sm"
                    onClick={()=>downloadCSV()}>
                    BAIXAR
                  </Button>
                </>
              ) : (
                <div style={{
                  position:'relative',
                  margin:1
                }}>
                <Button
                  outline
                  color="primary"
                  size="sm"
                  disabled={csvLoading}
                  onClick={()=>requestCSV()}>
                  {buttonLabel}
                </Button>
                {csvLoading && <CircularProgress size={24} style={{
                  color: '#FFF',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: -12,
                  marginLeft: -12}} 
                />
                }
              </div>
              )}
            </div>
          </Grid>
        <Grid item md={12} container>
          <Grid item md={3}>
            <HeaderItem
              title="Valor total"
              value={income !== undefined ? formatMoney(income.total_price) : ''}
            />
          </Grid>
          <Grid item md={3}>
            <HeaderItem
              title="Repasse p/ motorista"
              value={
                income !== undefined ? formatMoney(income.driver_price) : ''
              }
            />
          </Grid>
          <Grid item md={3}>
            <HeaderItem
              title="Repasse p/ seguro"
              value={
                income !== undefined
                  ? formatMoney(income.insurance_price)
                  : ''
              }
            />
          </Grid>
          <Grid item md={3} style={{ textAlign: 'right' }}>
            <HeaderItem
              title="Total de corridas"
              value={count !== undefined ? count : ''}
            />
          </Grid>
        </Grid>
        <Grid item md={12} container>
          <ReactTable
            style={{ width: '100%', textAlign: 'center' }}
            className="-striped -highlight"
            data={allRides}
            sortable={false}
            defaultPageSize={pageLength}
            pages={Math.ceil(count ? count / pageLength : 1)}
            loading={tableLoading}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando..."
            noDataText="Nenhum dado encontrado"
            onFetchData={state => {
              const obj = {
                id: '',
                external_id: '',
                origin_addres: '',
                destination_addres: '',
              };

              state.filtered.forEach(filter => {
                obj[filter.id] = filter.value;
              });

              setFilterState(obj);
              
              setPage(state.page);
              setPageLength(state.pageSize);

            }}
            onFilteredChange={state => {
              setFilterState(state)}}
            manual
            columns={[
              {
                id: 'id',
                accessor: 'id',
                Header: 'Cód. Frete',
                width: 75,
                filterable: true
              },
              {
                id: 'external_id',
                Header: 'Cód. Pedido',
                accessor: row => (row.external_id !== null ? row.external_id : '~'),
                width: 75,
                filterable: true
              },
              {
                id: 'month_year',
                Header: 'Data',
                accessor: row => formatTimeDate(row.date)[0],
                width: 100,
                filterable: false,
              },
              {
                id: 'distance',
                Header: 'Distância',
                accessor: row => (row.distance)/1000 + ' km',
                width: 100,
                filterable: false,
              },
              {
                id: 'origin_addres',
                Header: 'Origem',
                accessor: 'originAddress',
                filterable: true,
              },
              {
                id: 'destination_addres',
                Header: 'Destino',
                accessor: 'destinationAddress',
                filterable: true,
              },
              {
                id: 'money',
                Header: 'Valor total do frete',
                accessor: row => `R$ ${formatMoney(row.price.total_price)}`,
                width: 100,
              }
            ]}
            showPageJump={false}
          />
        </Grid>
        </>
      )}
    </Grid>
  );
}

export default DriverIncome;