import React from 'react';
import cx from 'classnames';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import dashboardRoutes from 'routes/dashboard.jsx';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import image from 'assets/img/sidebar-2.jpg';
import logo from '../assets/img/logo-driver.png';
import Error from '../views/Pages/Error';
import { getPermissions } from '../helpers/functions';

const parseAdmin = flag => {
  switch (flag) {
    case 'IS_ADMIN':
      return dashboardRoutes;

    default:
      return dashboardRoutes.filter(route => !route.admin);
  }
};

const switchRoutes = flag => (
  <Switch>
    {parseAdmin(flag).map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => {
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        });
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

let ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      permissions: { permissions: null, flag: '' }
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Windows') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);

    getPermissions()
      .then(response => {
        console.log('per', response);
        this.setState({ permissions: response });
      })
      .catch(error => {
        console.log('error :', error);
        this.setState({ permissions: error });
      });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Windows') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }

  getRoute() {
    return this.props.location.pathname !== '/map';
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
     // this.setState({ mobileOpen: false });
     // break map fullscreen
    }
  }

  /**
   *
   *
   * @param {[]} permissions
   */
  selectRoute = permissions => {
    if (permissions.indexOf('NOT_LOGGED')) {
      return <Redirect to="/driver/login" />;
    }
    return <Error title="403" txt="Acesso negado" />;
  };

  render() {
    const { classes, ...rest } = this.props;
    const { permissions } = this.state;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Windows') > -1
    })}`;
    if (permissions.permissions === null) {
      return <div />;
    }
    return (
      <PermissibleRender
        userPermissions={permissions.permissions}
        requiredPermissions={['IS_SUPPORT']}
        renderOtherwise={this.selectRoute(permissions.permissions)}
      >
        <div className={classes.wrapper}>
          <Sidebar
            routes={parseAdmin(this.state.permissions.flag).filter(route => {
              return !route.hidden;
            })}
            logoText="Beelog"
            logo={logo}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            bgColor="black"
            miniActive={this.state.miniActive}
            {...rest}
          />
          <div className={mainPanel} ref="mainPanel">
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              routes={parseAdmin(this.state.permissions.flag)}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>
                  {switchRoutes(this.state.permissions.flag)}
                </div>
              </div>
            ) : (
              <div className={classes.map}>
                {switchRoutes(this.state.permissions.flag)}
              </div>
            )}
            {this.getRoute() ? <Footer fluid /> : null}
          </div>
        </div>
      </PermissibleRender>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(appStyle)(Dashboard);
