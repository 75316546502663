import React, { useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
// import { fitBounds } from 'google-map-react/utils';

import { getDriversLocations, getZones } from '../../requests/requests';
import Marker from '../../components/Marker/Marker';
import { K_SIZE } from '../../components/Marker/styles';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { Card } from '@material-ui/core';

// arquivo que falta do css 
//import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

//arquivo que falta do css 
//const useStyles = makeStyles(styles);

export default function DriversMap({ history }) {
  
  const [drivers, setDrivers] = useState([]);
  const [first, setFirst] = useState(true);
  const [error, setError] = useState();  
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState();
  const [selectedZoneId, setSelectedZoneId] = useState();
  const [zonedrivers, setZoneDrivers] = useState([]);
  // const [center, setCenter] = useState();
  // const [zoom, setZoom] = useState();

  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedType, setSelectedType] = useState('');

  //arquivo que falta do css 
  //const classes = useStyles();

  const defaultMapOptions = {
    fullscreenControl: true,
    disableDefaultUI: false,
  };
 

  async function fetchZones() {
      const res = await getZones();
      setZones(res.data.zones);
  }

  async function fetchData(id) {
    try {
      const res = await getDriversLocations(id);
      setZoneDrivers(res.data.drivers);
      // console.log(res.data);
      if (first) setFirst(false);
    } catch (err) {
      if (first) {
        history.push('/dashboard');
        setFirst(false);
      }
      setError(err);
    }
  }

  useEffect(() => {
    fetchZones();
  }, []);


// useEffect(() => {
//   fetchData();
// }, []);

  useEffect(() => {
    if (!error && selectedZoneId) {
      let timeOutFunction = setTimeout(() => fetchData(selectedZoneId), 10000);
      return function cleanup() {
        clearTimeout(timeOutFunction);
        timeOutFunction = null;
      };
    }
  }, [zonedrivers]);

  useEffect(()=>{
    if (zonedrivers.length > 0){
      let arr = [];
      if (selectedStatus === 'online'){
        if (selectedType === '' || selectedType === 'all' ){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 1) {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'available',
                name: driver.name,
                type: driver.vehicle_type === '1' ? 'Carro' : 'Moto'
              });
            }
          })
        } else if (selectedType === 'moto'){ // Moto
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 1 && driver.vehicle_type === '0') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'available',
                name: driver.name,
                type: 'Moto'
              });
            }
          })
        } else if (selectedType === 'carro'){ // Carro
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 1 && driver.vehicle_type === '1') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'available',
                name: driver.name,
                type: 'Carro' 
              });
            }
          })
        }
      } else if (selectedStatus === 'inRide'){
        if (selectedType === '' || selectedType === 'all' ){
          zonedrivers.map(driver => {
            if (driver.in_ride == 1 && driver.online == 0) {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'inRide',
                name: driver.name,
                type: driver.vehicle_type === '1' ? 'Carro' : 'Moto'
              });
            }
          })
        } else if (selectedType === 'moto'){ // Moto
          zonedrivers.map(driver => {
            if (driver.in_ride == 1 && driver.online == 0 && driver.vehicle_type === '0') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'inRide',
                name: driver.name,
                type: 'Moto'
              });
            }
          })
        } else if (selectedType === 'carro'){ // Carro
          zonedrivers.map(driver => {
            if (driver.in_ride == 1 && driver.online == 0 && driver.vehicle_type === '1') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'inRide',
                name: driver.name,
                type: 'Carro' 
              });
            }
          })
        }
      } else if (selectedStatus === 'offline'){
        if (selectedType === '' || selectedType === 'all'){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 0) {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'unavailable',
                name: driver.name,
                type: driver.vehicle_type === '1' ? 'Carro' : 'Moto'
              });
            }
          });
        } else if (selectedType === 'carro'){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 0 && driver.vehicle_type === '1') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'unavailable',
                name: driver.name,
                type: 'Carro'
              });
            }
          });
        } else if (selectedType === 'moto'){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 0 && driver.vehicle_type === '0') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'unavailable',
                name: driver.name,
                type: 'Moto'
              });
            }
          });
        }
      } else if (selectedStatus === 'all'){
        if (selectedType === '' || selectedType === 'all'){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 1) {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'available',
                name: driver.name,
                type: driver.vehicle_type === '1' ? 'Carro' : 'Moto'
              });
            } else if (driver.in_ride == 1){
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'inRide',
                name: driver.name,
                type: driver.vehicle_type === '1' ? 'Carro' : 'Moto'
              });
            } else {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'unavailable',
                name: driver.name,
                type: driver.vehicle_type === '1' ? 'Carro' : 'Moto'
              });
            }
          })
        } else if (selectedType === 'carro'){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 1 && driver.vehicle_type === '1') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'available',
                name: driver.name,
                type: 'Carro'
              });
            } else if (driver.in_ride == 1 && driver.vehicle_type === '1'){
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'inRide',
                name: driver.name,
                type: 'Carro'
              });
            } else if (driver.vehicle_type === '1') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'unavailable',
                name: driver.name,
                type: 'Carro' 
              });
            }
          })
        } else if (selectedType === 'moto'){
          zonedrivers.map(driver => {
            if (driver.in_ride == 0 && driver.online == 1 && driver.vehicle_type === '0') {
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'available',
                name: driver.name,
                type: 'Moto'
              });
            } else if (driver.in_ride == 1 && driver.vehicle_type === '0'){
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'inRide',
                name: driver.name,
                type: 'Moto'
              });
            } else if (driver.vehicle_type === '0'){
              arr.push({
                key:driver.user_id,
                lat: driver.current_latitude,
                lng: driver.current_longitude,
                text: `${driver.plate}`,
                tooltip: `${driver.plate}\n${driver.name}`,
                available: 'unavailable',
                name: driver.name,
                type: 'Moto'
              });
            }
          })
        }
      }
      setSelectedDrivers(arr);
    }
  },[zonedrivers,selectedStatus, selectedType])

  const handleSelectChange = (event) => {    
    setSelectedZone(event.target.name);
    setSelectedZoneId(event.target.value);
    fetchData(event.target.value);
  };


  function handleStatus(event){
    setSelectedStatus(event.target.value);
  }

  function handleVehicleType(event){
    setSelectedType(event.target.value);
  }

  if (error) {
    return (
      <div
        style={{ height: 'calc(100vh - 80px)', width: '100%', alignItems: 'center', justifyContent: 'center' }}
      >
        Rastreio indisponível.
      </div>
    );
  }

  return (
    <div>
      <div style={{position: 'absolute', top: '80px', left: '5%', zIndex: 20, width: '300px'}}>
        <Card style={{padding: '5%'}}>          
          <FormControl fullWidth> 
              <InputLabel
                htmlFor="zones"
              >
                Selecione a zona de atuação:
              </InputLabel>
                <Select
                native            
                value={selectedZoneId}
                onChange={handleSelectChange} 
                name="Zones"
                >
                  <option aria-label="None" value="" />
                  {
                    zones.map(zone => {
                      return (
                        <option value={zone.id}>{zone.note}</option>
                      );
                    })
                  }
                
              </Select>
            </FormControl>
            {zonedrivers.length > 0 ? (
              <>
              <FormControl fullWidth> 
                <InputLabel
                  htmlFor="status"
                >
                  Filtrar por status:
                </InputLabel>
                  <Select
                    value={selectedStatus}
                    onChange={e => handleStatus(e)} 
                    name="status"
                  >
                    <MenuItem value={'online'}>Online</MenuItem>
                    <MenuItem value={'inRide'}>Em corrida</MenuItem>
                    <MenuItem value={'offline'}>Offline</MenuItem>
                    <MenuItem value={'all'}>Todos</MenuItem>
                  </Select>
              </FormControl>
              <br/>
              <FormControl fullWidth> 
              <InputLabel
                htmlFor="vehicle_type"
              >
                Filtrar por veículo:
              </InputLabel>
                <Select
                  value={selectedType}
                  onChange={e => handleVehicleType(e)} 
                  name="vehicle_type"
                >
                  <MenuItem value={'carro'}>Carro</MenuItem>
                  <MenuItem value={'moto'}>Moto</MenuItem>
                  <MenuItem value={'all'}>Todos</MenuItem>
                </Select>
            </FormControl>
            </>
            ) : ""}
        </Card>
      </div>
      {selectedStatus !== '' ? (
        selectedDrivers.length > 0 ? (
        <div style={{ height: '98vh', width: '100%' }}>        
          <GoogleMapReact
            style={{ zIndex: 2 }}
            bootstrapURLKeys={{ key: 'AIzaSyDXfR60BoXlwEEocHKfwTzwbLjex_fnoXk' }}
            defaultZoom={12}
            center={{
              lat: selectedZoneId && zones && zones.find(zone => zone.id == selectedZoneId) ? zones.find(zone => zone.id == selectedZoneId).origin_latitude : -10.94904010 ,
              lng: selectedZoneId && zones && zones.find(zone => zone.id == selectedZoneId) ? zones.find(zone => zone.id == selectedZoneId).origin_longitude : -37.08827580
            }}
            layerTypes={[]}
            hoverDistance={K_SIZE / 2}
            defaultOptions={defaultMapOptions}
            
            // onChange={({ bounds, size }) => {
            //   const { cnt, zm } = fitBounds(bounds, size);
            //   console.log(bounds);
            //   setCenter(cnt);
            //   setZoom(zm);
            // }}
          >
            {selectedDrivers.map(driver => {
              return (
                <Marker
                  key={driver.key}
                  lat={driver.lat}
                  lng={driver.lng}
                  text={driver.text}
                  tooltip={driver.tooltip}
                  available={driver.available}
                  name={driver.name}
                  type={driver.type}
                />
              );          
            })}
          </GoogleMapReact>
        </div>
        ) : (
        <div style={{ height: '98vh', width: '100%' }}>        
          <GoogleMapReact
            style={{ zIndex: 2 }}
            bootstrapURLKeys={{ key: 'AIzaSyDXfR60BoXlwEEocHKfwTzwbLjex_fnoXk' }}
            defaultZoom={12}
            center={{
              lat: selectedZoneId && zones && zones.find(zone => zone.id == selectedZoneId) ? zones.find(zone => zone.id == selectedZoneId).origin_latitude : -10.94904010 ,
              lng: selectedZoneId && zones && zones.find(zone => zone.id == selectedZoneId) ? zones.find(zone => zone.id == selectedZoneId).origin_longitude : -37.08827580
            }}
            layerTypes={[]}
            hoverDistance={K_SIZE / 2}
            defaultOptions={defaultMapOptions}
            
            // onChange={({ bounds, size }) => {
            //   const { cnt, zm } = fitBounds(bounds, size);
            //   console.log(bounds);
            //   setCenter(cnt);
            //   setZoom(zm);
            // }}
          >
            {selectedDrivers.map(driver => {
              return (
                <Marker
                  key={driver.key}
                  lat={driver.lat}
                  lng={driver.lng}
                  text={driver.text}
                  tooltip={driver.tooltip}
                  available={driver.available}
                  name={driver.name}
                  type={driver.type}
                />
              );          
            })}
          </GoogleMapReact>
        </div>
        )
      ) : (
      <div style={{ height: '98vh', width: '100%' }}>        
        <GoogleMapReact
          style={{ zIndex: 2 }}
          bootstrapURLKeys={{ key: 'AIzaSyDXfR60BoXlwEEocHKfwTzwbLjex_fnoXk' }}
          defaultZoom={12}
          center={{
            lat: selectedZoneId && zones && zones.find(zone => zone.id == selectedZoneId) ? zones.find(zone => zone.id == selectedZoneId).origin_latitude : -10.94904010 ,
            lng: selectedZoneId && zones && zones.find(zone => zone.id == selectedZoneId) ? zones.find(zone => zone.id == selectedZoneId).origin_longitude : -37.08827580
          }}
          layerTypes={[]}
          hoverDistance={K_SIZE / 2}
          defaultOptions={defaultMapOptions}
          
          // onChange={({ bounds, size }) => {
          //   const { cnt, zm } = fitBounds(bounds, size);
          //   console.log(bounds);
          //   setCenter(cnt);
          //   setZoom(zm);
          // }}
        >
          {zonedrivers.length > 0 ? zonedrivers.map(driver => {
              let available = 'unavailable';
              if (driver.in_ride === "0" && driver.online === "1") {
                available = 'available';
              } else if (driver.in_ride === "1") {
                available = 'inRide';
              } else {
                available = 'unavailable';
              }
              return (
                <Marker
                  key={driver.user_id}
                  lat={driver.current_latitude}
                  lng={driver.current_longitude}
                  text={`${driver.plate}`}
                  tooltip={`${driver.plate}\n${driver.name}`}
                  available={available}
                  name={driver.name}
                  type={driver.vehicle_type === '1' ? 'Carro' : 'Moto'}
                />
              );
            }) : ''
          }
        </GoogleMapReact>
      </div>
      )}    
    </div>
  );
}