import React, { useState, useEffect } from 'react';

import { editEnterprise, getBusinessReport, updateBusinessLogo } from '../../../requests/requests';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
// import CardBody from '../../../components/Card/CardBody';
import Button from '../../../components/CustomButtons/Button';
import { 
  TextField,
  Grid
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Card, CircularProgress } from '@material-ui/core';
import { formatTimeDate } from '../../../helpers/functions';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import logoDriver from '../../../assets/img/logo-driver.png';
import ImageUploader from 'react-images-upload';

function EnterpriseDetail() {
  const [enterprise] = useState(
    JSON.parse(sessionStorage.getItem('enterpriseDetail'))
  );
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState('');
  const [addressNote, setAddressNote] = useState('');
  const [logo, setLogo] = useState();
  const [image, setImage] = useState();
  const [document, setDocument] = useState('');
  const [name, setName] = useState('');
  const [hasInvoice, setHasInvoice] = useState(false);
  const [ownsFleet, setOwnsFleet] = useState(false);
  const [active, setActive] = useState(false);
  const [requiresQRCode, setRequiresQRCode] = useState(false);
  const [id, setId] = useState();
  const [alert, setAlert] = useState();
  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const FileDownload = require('js-file-download');

  /** EFFECTS */
  useEffect(() => {
    if (enterprise !== undefined) {
      console.log('enterprise', enterprise);
      setLoading(false);
      setId(enterprise[0].id);
      setAddress(enterprise[0].address);
      setAddressNote(enterprise[0].addressNote);
      enterprise[0].logo === null
        ? setLogo(logoDriver)
        : setLogo(enterprise[0].logo);
      setDocument(enterprise[0].document);
      setName(enterprise[0].name);
      enterprise[0].has_invoice === 0
        ? setHasInvoice(false)
        : setHasInvoice(true);
      enterprise[0].owns_fleet === 0 ? setOwnsFleet(false) : setOwnsFleet(true);
      enterprise[0].active === 0 ? setActive(false) : setActive(true);
      setRequiresQRCode(enterprise[0].requires_qrcode);
    }
  }, [enterprise]);

  /** FUNCTIONS */
  function showAlert(signal, active) {
    setAlert(
      <SweetAlert
        warning
        style={{
          display: 'block',
          overflow: 'visible',
          marginTop: '-200px',
          marginLeft: '-250px',
          marginRight: '-250px',
          marginBottom: '-100px'
        }}
        title={'Alterar empresa'}
        onConfirm={() => editSignal(signal, active)}
        onCancel={() => hideAlert()}
        showCancel
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        Você tem certeza que quer alterar essa empresa?
      </SweetAlert>
    );
  }

  function hideAlert() {
    setAlert(null);
  }

  async function updateLogo(){    
    setLoading(true);

    const res = await updateBusinessLogo(id, image[0]);
    if (res.data) {      
      setLoading(false);
      window.alert("Imagem enviada.")
      window.location.href="/enterprise-table";
    }
  }

  async function editSignal(signal, active) {
    setLoading(true);
    hideAlert();
    let newSignal = 0;
    signal ? (newSignal = 1) : (newSignal = 0);
    let newActive = 0;
    active ? (newActive = 1) : (newActive = 0);
    const res = await editEnterprise(id, newSignal, newActive);
    if (res.data) {
      setOwnsFleet(signal);
      setActive(active);
      setLoading(false);
    }
  }

  async function getReport(){

    const res = await getBusinessReport(id, dateStart, dateEnd);
    FileDownload(res.data, `Relatorio${enterprise[0].name}D${dateStart}A${dateEnd}.csv`);
  }

  return (
    <>
      {alert}
      <GridContainer
        component={Card}
        style={{ minHeight: '80vh', padding: '3% 3%', marginTop: '20px' }}
        spacing={16}
        justify="center"
        alignItems="center"
      >
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <>
            <GridItem xs={12} sm={12} md={12}>
              {/* <Card>
            <CardBody> */}
              <div style={{ textAlign: 'center', margin: 10 }}>
                <img src={logo} alt="logo" width="100px" />

                <p>Alterar logo:</p>
                <ImageUploader
                          withIcon={false}
                          withPreview
                          buttonText="Selecionar arquivo"
                          label="Máximo 3mb"
                          onChange={file => {
                            setImage(file);
                          }}
                          imgExtension={['.jpg', '.png']}
                          maxFileSize={3242880}
                        />
                  {image ? (
                      <Button                    
                      color="primary"
                      onClick={() => {
                        updateLogo()
                      }}
                    >
                      {"Enviar"}
                    </Button>
                    ) : ''}                    
              </div>
              <div
                style={{
                  justifyItems: 'start',
                  justifySelf: 'start',
                  justifyContent: 'start',
                  alignItems: 'flex-start'
                }}
              >
                <CustomInput
                  labelText="Nome"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    defaultValue: name
                  }}
                />
                <CustomInput
                  labelText="Documento"
                  id="document"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    defaultValue: document
                  }}
                />
                <CustomInput
                  labelText="Endereço"
                  id="address"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    defaultValue: address
                  }}
                />
                <CustomInput
                  labelText="Complemento"
                  id="addressNote"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    defaultValue: addressNote
                  }}
                />
              </div>
              <FormControl component="fieldset">
                <FormGroup
                  aria-label="position"
                  row
                  style={{ textAlign: 'center' }}
                >
                  <FormControlLabel
                    disabled
                    control={<Checkbox checked={hasInvoice} color="primary" />}
                    label="Faturada"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    disabled
                    control={
                      <Checkbox checked={requiresQRCode} color="primary" />
                    }
                    label="Requer QR Code"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ownsFleet}
                        color="primary"
                        onChange={() => showAlert(!ownsFleet, active)}
                      />
                    }
                    label="Frota própria"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={active}
                        color="primary"
                        onChange={() => showAlert(ownsFleet, !active)}
                      />
                    }
                    label="Sinal liberado"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
              {/* </CardBody>
          </Card> */}
            </GridItem>            
          </>
        )}
      </GridContainer>
      <GridContainer
        component={Card}
        style={{ padding: '3% 3%', marginTop: '20px' }}
        spacing={16}
        justify="center"
        alignItems="center">
        <GridItem xs={12} sm={12} md={12}>
            <h5>Gerar Relatório Financeiro</h5>
        </GridItem>
        <Grid container
        style={{ padding: '3% 3%', marginTop: '20px' }} >
              <Grid container item xs={3} spacing={2} >
                <TextField
                  id="dateStart"
                  label="Data Início"
                  type="date"
                  value={dateStart}
                  onChange={e => {
                    if (!dateEnd) {
                      setDateStart(e.target.value);
                      setDateEnd(e.target.value);
                    } else setDateStart(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />               
              </Grid>
              <Grid container item xs={3} spacing={2} >
                <TextField
                  id="dateEnd"
                  label="Data Fim"
                  type="date"
                  value={dateEnd}
                  onChange={e => {
                    if (!dateEnd) {
                      setDateStart(e.target.value);
                      setDateEnd(e.target.value);
                    } else setDateEnd(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid container item xs={3} spacing={1} >
                <div style={{textAlign:'initial'}}>
                  <Button                    
                    color="primary"
                    onClick={() => {
                      getReport()
                    }}
                  >
                    {"Baixar"}
                  </Button>
                </div>
              </Grid>
              <Grid container item xs={3} spacing={1}></Grid>
            </Grid>
            
      </GridContainer>
    </>
  );
}

export default EnterpriseDetail;
