import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import {
  Grid,
  Card,
  CircularProgress,
  Select,
  TextField,
  MenuItem
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import { getIncome, getBusinessList } from '../../../requests/requests';
import HeaderItem from './HeaderItem';
import { useDebounce } from '../../../utils/hooks';
import { formatMoney, formatTimeDate } from '../../../utils/functions';
import './index.css';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Typography from '@material-ui/core/Typography';

const parseDateToRequest = filterDate => {
  if (filterDate === 'hoje') {
    const today = new Date(Date.now());
    return { month: today.getMonth(), year: today.getFullYear() };
  }
  switch (filterDate.length) {
    case 1:
      return { month: +filterDate, year: null };
    case 2:
      return { month: +filterDate, year: null };
    case 4:
      return { month: null, year: +filterDate };
    case 7:
      return {
        month: +filterDate.substring(0, 2),
        year: +filterDate.substring(3, 7)
      };
    default:
      return { month: null, year: null };
  }
};

function Income(props) {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(30);
  const [filterState, setFilterState] = useState({
    ride_id: '',
    requester_id: '',
    is_business: undefined,
    date_start: null,
    date_end: null
  });
  const [stats, setStats] = useState({ rides: [], count: 0 });
  const [businessList, setBusinessList] = useState([]);

  const debouncedRideId = useDebounce(filterState.ride_id, 1000);
  // const debouncedMonthYear = useDebounce(filterState.month_year, 1000);
  const debouncedRequesterId = useDebounce(filterState.requester_id, 1000);
  const debouncedIsBusiness = useDebounce(filterState.is_business, 1000);

  const [count, setCount] = useState();
  const [csvRes, setCsvRes] = useState();
  const [csvData, setCsvData] = useState([]);
  const [csvLink, setCsvLink] = useState();

  const [dateStart, setDateStart] = useState('');
  const [dateEnd, setDateEnd] = useState('');

  // useEffect(() => {
  //   if (
  //     filterState !== null &&
  //     ((!dateStart && !dateEnd) || (dateStart && dateEnd))
  //   ) {
  //     let timeOutFunction = setTimeout(() => {
  //       getIncome(filterState);
  //     }, 500);

  //     return function cleanup() {
  //       clearTimeout(timeOutFunction);
  //       timeOutFunction = null;
  //     };
  //   }
  // }, [filterState, dateStart, dateEnd]);

  const headers = [
    {
      label: 'Código do frete',
      key: 'id'
    },
    {
      label: 'Código do pedido',
      key: 'order_id'
    },
    {
      label: 'Origem',
      key: 'from'
    },
    {
      label: 'Destino',
      key: 'to'
    },
    {
      label: 'Distância',
      key: 'distance'
    },
    {
      label: 'Data/Hora',
      key: 'date'
    },
    {
      label: 'Motorista',
      key: 'driver'
    },
    {
      label: 'Valor total',
      key: 'total_price'
    },
    {
      label: 'Valor peso',
      key: 'volume_price'
    },
    // {
    //   label: 'Valor distância',
    //   key: 'distance_price',
    // },
    {
      label: 'Valor declarado',
      key: 'product_price'
    },
    {
      label: 'Repasse Seguro',
      key: 'insurance_price'
    },
    {
      label: 'Repasse motorista',
      key: 'driver_price'
    }
  ];

  function redirectRide(id) {
    window.open(`/ride/${id}`, '_blank');
  }

  useEffect(() => {
    getBusinessList().then(res => {
      setBusinessList(res.data.businesses);
    });
  }, []);

  useEffect(() => {
    if (csvRes !== undefined) {
      console.log('csvRes', csvRes);
      let data = [];
      csvRes.rides.map(r => {
        data.push({
          id: r.id,
          order_id: r.external_id,
          from: r.originAddress,
          to: r.destinationAddress,
          driver: r.driver != null ? r.driver.name : '',
          distance: r.distance,
          date: r.date,
          volume_price: r.price != null ? formatMoney(r.price.volume_price) : '',
          // 'distance_price': formatMoney(r.price.distance_price),
          driver_price: r.price != null ? formatMoney(r.price.driver_price) : '',
          insurance_price: r.insurance != null ? formatMoney(r.insurance.price) : '',
          product_price: r.insurance != null ? formatMoney(r.insurance.product_price) : '',
          total_price: r.price != null ? formatMoney(r.price.total_price) : ''
        });
      });
      setCsvData(data);
    }
  }, [csvRes]);

  useEffect(() => {
    console.log('csvData', csvData);
    if (csvData.length > 0) {
      downloadCSV();
    }
  }, [csvData]);

  useEffect(() => {
    if (dateStart !== '' && dateEnd !== '') {
      let start = moment(dateStart);
      let end = moment(dateEnd);

      if (end.isBefore(start)) {
        setDateEnd(dateStart);
      }
    }
  }, [dateStart, dateEnd]);

  useEffect(() => {
    if (
      debouncedRideId ||
      debouncedRequesterId ||
      debouncedIsBusiness ||
      dateStart ||
      dateEnd
    ) {
      setTableLoading(true);
      getIncome(page * pageLength, pageLength, {
        ride_id: debouncedRideId,
        requester_id: debouncedRequesterId,
        is_business:
          debouncedIsBusiness == 0 ? 0 : debouncedIsBusiness == 1 ? 1 : null,
        date_start: dateStart,
        date_end: dateEnd
      })
        .then(res => {
          setCount(res.data.count);
          setStats(res.data);
          setCsvData([]);
          setLoading(false);
          setTableLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setTableLoading(false);
        });
    } else {
      setTableLoading(true);
      getIncome(page * pageLength, pageLength)
        .then(res => {
          setCount(res.data.count);
          setStats(res.data);
          setCsvData([]);
          setLoading(false);
          setTableLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setTableLoading(false);
        });
    }
  }, [
    page,
    pageLength,
    debouncedRideId,
    debouncedRequesterId,
    debouncedIsBusiness,
    dateStart,
    dateEnd
  ]);

  function availableDates(startDate, endDate) {
    var startDate = moment('2020-04-01');
    var endDate = moment();

    var result = [];

    if (endDate.isBefore(startDate)) {
      throw 'End date must be greated than start date.';
    }

    while (startDate.isBefore(endDate)) {
      result.push(startDate.format('MM/YYYY'));
      startDate.add(1, 'month');
    }

    return result;
  }

  function requestCSV() {
    if (count > 0) {
      setCsvLoading(true);
      if (
        debouncedRideId ||
        debouncedRequesterId ||
        debouncedIsBusiness ||
        dateStart ||
        dateEnd
      ) {
        getIncome(0, count, {
          ride_id: debouncedRideId,
          requester_id: debouncedRequesterId,
          is_business:
            debouncedIsBusiness == 0 ? 0 : debouncedIsBusiness == 1 ? 1 : null,
          date_start: dateStart,
          date_end: dateEnd
        })
          .then(res => {
            setCsvRes(res.data);
            setCsvLoading(false);
          })
          .catch(() => {
            setCsvLoading(false);
          });
      } else {
        getIncome(0, count)
          .then(res => {
            setCsvRes(res.data);
            setCsvLoading(false);
          })
          .catch(() => {
            setCsvLoading(false);
          });
      }
    }
  }

  function downloadCSV() {
    if (csvRes !== undefined) {
      if (csvLink !== undefined && csvLink !== null) {
        csvLink.link.click();
      }
    } else {
      requestCSV();
    }
  }

  return (
    <Grid
      container
      component={Card}
      style={{ minHeight: '80vh', padding: '3% 5%', marginTop: '20px' }}
      spacing={16}
      justify="center"
      alignItems="center"
    >
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <>
          <Grid container>
            <Grid container item xs={3}>
              <TextField
                id="dateStart"
                label="Data Início"
                type="date"
                value={dateStart}
                onChange={e => {
                  if (!dateEnd) {
                    setDateStart(e.target.value);
                    setDateEnd(e.target.value);
                  } else setDateStart(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid container item xs={3}>
              <TextField
                id="dateEnd"
                label="Data Fim"
                type="date"
                value={dateEnd}
                onChange={e => {
                  if (!dateEnd) {
                    setDateStart(e.target.value);
                    setDateEnd(e.target.value);
                  } else setDateEnd(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid container item xs={3}>
              <div style={{ textAlign: 'initial' }}>
                <Button
                  simple
                  color="primary"
                  onClick={() => {
                    setDateStart('');
                    setDateEnd('');
                  }}
                >
                  {'Limpar'}
                </Button>
              </div>
            </Grid>
            <Grid container item xs={3}></Grid>
          </Grid>
          <Grid item md={8} container justify="flex-start">
            <Typography variant="caption">
              Os valores exportados serão iguais aos presentes na tabela no
              momento da exportação.
            </Typography>
          </Grid>
          <Grid item md={4} container justify="flex-end">
            <div style={{ textAlign: 'left' }}>
              {csvData.length > 0 ? (
                <>
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    separator={';'}
                    filename="dataIncome.csv"
                    className="hidden"
                    ref={r => setCsvLink(r)}
                    target="_blank"
                  />
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => downloadCSV()}
                  >
                    BAIXAR
                  </Button>
                </>
              ) : (
                <div
                  style={{
                    position: 'relative',
                    margin: 1
                  }}
                >
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    disabled={csvLoading}
                    onClick={() => requestCSV()}
                  >
                    {csvLoading ? 'PREPARANDO' : 'EXPORTAR'}
                  </Button>
                  {csvLoading && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: '#FFF',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: -12,
                        marginLeft: -12
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </Grid>
          <Grid item md={12} container>
            <Grid item md={3}>
              <HeaderItem
                title="Valor total"
                value={stats.total_price ? formatMoney(stats.total_price) : ''}
              />
            </Grid>
            <Grid item md={3}>
              <HeaderItem
                title="Repasse p/ motoristas"
                value={
                  stats.driver_price ? formatMoney(stats.driver_price) : ''
                }
              />
            </Grid>
            <Grid item md={3}>
              <HeaderItem
                title="Repasse p/ seguro"
                value={
                  stats.insurance_price
                    ? formatMoney(stats.insurance_price)
                    : ''
                }
              />
            </Grid>
            <Grid item md={3} style={{ textAlign: 'right' }}>
              <HeaderItem
                title="Total de corridas"
                value={stats.count ? stats.count : ''}
              />
            </Grid>
          </Grid>
          <Grid item md={12} container>
            <ReactTable
              style={{ width: '100%', textAlign: 'center' }}
              className="-striped -highlight"
              data={stats.rides}
              sortable={false}
              defaultPageSize={pageLength}
              pages={Math.ceil(stats.count / pageLength)}
              loading={tableLoading}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando..."
              noDataText="Nenhum dado encontrado"
              manual
              onFetchData={state => {
                const obj = {
                  ride_id: '',
                  // month_year: '',
                  requester_id: ''
                };

                state.filtered.forEach(filter => {
                  obj[filter.id] = filter.value;
                });

                setFilterState(obj);

                setPage(state.page);

                setPageLength(state.pageSize);
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: e => {
                    redirectRide(rowInfo.original.id);
                  }
                };
              }}
              columns={[
                {
                  id: 'ride_id',
                  Header: 'Cód. do frete',
                  accessor: row => row.id,
                  filterable: true
                },
                {
                  id: 'month_year',
                  Header: 'Data',
                  accessor: row => formatTimeDate(row.date)[0],
                  filterable: false
                  // Filter: (
                  //   <Select
                  //     id="date-select"
                  //     fullWidth
                  //     value={filterState ? filterState.month_year : ''}
                  //     onChange={e =>
                  //       setFilterState({
                  //         ...filterState,
                  //         month_year: e.target.value
                  //       })
                  //     }
                  //   >
                  //     <MenuItem value="">Qualquer</MenuItem>
                  //     {availableDates().map(date => (
                  //       <MenuItem key={date} value={date}>
                  //         {date}
                  //       </MenuItem>
                  //     ))}
                  //   </Select>
                  // )
                },
                {
                  id: 'is_business',
                  Header: 'Tipo de Cliente',
                  accessor: row => (row.is_business == 0 ? 'B2C' : 'B2B'),
                  filterable: true,
                  Filter: (
                    <Select
                      id="is-business-select"
                      fullWidth
                      name="is_business"
                      value={filterState ? filterState.is_business : ''}
                      onChange={e =>
                        setFilterState({
                          ...filterState,
                          is_business: e.target.value
                        })
                      }
                    >
                      <MenuItem>Todos</MenuItem>
                      <MenuItem value="0">B2C</MenuItem>
                      <MenuItem value="1">B2B</MenuItem>
                    </Select>
                  )
                },
                {
                  id: 'requester_id',
                  Header: 'Cod. Cliente',
                  accessor: row => row.requester_id,
                  filterable: true,
                  Filter: (
                    <Select
                      id="requester-select"
                      fullWidth
                      value={filterState ? filterState.requester_id : ''}
                      onChange={e =>
                        setFilterState({
                          ...filterState,
                          requester_id: e.target.value
                        })
                      }
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {businessList.map(business => (
                        <MenuItem key={business.id} value={business.id}>
                          {business.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                },
                {
                  id: 'money',
                  Header: 'Valor total do frete',
                  accessor: row => `R$ ${formatMoney(row.price.total_price)}`
                }
              ]}
              showPageJump={false}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Income;
